import { List } from '@shared/models/interfaces/List.interface';

export class SetModalData {
  static readonly type = '[MODAL-DATA] Set modal data';
  constructor(public data: List<any>) {}
}

export class SetModalSelectedElements {
  static readonly type = '[MODAL-DATA] Set modal selected elements';
  constructor(public data: any[]) {}
}

export class AddModalSelectedElement {
  static readonly type = '[MODAL-DATA] Add modal selected elements';
  constructor(public data: any) {}
}

export class SetUpdateElements {
  static readonly type = '[MODAL-DATA] Set update elements';
  constructor(public data: any) {}
}

export class AddSelectedElement {
  static readonly type = '[MODAL-DATA] Add selected elements from modal';
  constructor(public data: any) {}
}

export class RemoveSelectedElement {
  static readonly type = '[MODAL-DATA] Remove selected elements from modal';
  constructor(public data: any) {}
}

export class ClearSelectedElement {
  static readonly type = '[MODAL-DATA] Clear selected elements from modal';
  constructor() {}
}

export class ClearRemovedElement {
  static readonly type = '[MODAL-DATA] Clear removed elements from modal';
  constructor() {}
}

export class ClearSelectedAndRemovedElement {
  static readonly type = '[MODAL-DATA] Clear selected and removed elements from modal';
  constructor() {}
}
