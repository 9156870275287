import { List } from '@shared/models/interfaces/List.interface';
import { Process } from '@shared/models/interfaces/process.interface';

export class SetProcess {
  static readonly type = '[PROCESS] Set Process';
  constructor(public process: List<Process>) {}
}

export class AddProcess {
  static readonly type = '[PROCESS] Add Process to the current state';
  constructor(public process: Process[]) {}
}
