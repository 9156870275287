import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { environment } from '@environments/environment';
import { AppAvailability } from '@ionic-native/app-availability/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { CameraPreview } from '@ionic-native/camera-preview/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { Device } from '@ionic-native/device/ngx';
import { File } from '@ionic-native/file/ngx';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { MediaCapture } from '@ionic-native/media-capture/ngx';
import { Network } from '@ionic-native/network/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { SpeechRecognition } from '@ionic-native/speech-recognition/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { HttpInterceptorService } from '@shared/providers/http-interceptor.service';
import { IndexedDBService } from '@shared/providers/indexed-db.service';
import { AdminsState } from '@shared/store/admin/admins.state';
import { CanAccessState } from '@shared/store/canAccess/canAccess.state';
import { ControllerState } from '@shared/store/controller/controller.state';
import { EditState } from '@shared/store/edit/edit.state';
import { UserState } from '@shared/store/logged-user/user.state';
import { MyTemplatesState } from '@shared/store/my-templates/my-templates.state';
import { ObjectState } from '@shared/store/objects/objects.state';
import { PhotoLimitState } from '@shared/store/photos-limit/photos-limit.state';
import { ProcedureState } from '@shared/store/procedures/procedures.state';
import { RequestState } from '@shared/store/request/request.state';
import { SegmentState } from '@shared/store/segments/segments.state';
import { SignatureState } from '@shared/store/signature/signature.state';
import { SurveyState } from '@shared/store/survey/survey.state';
import { SurveyorsState } from '@shared/store/surveyors/surveyors.state';
import { SurveysListState } from '@shared/store/surveys-list/surveys-list.state';
import { GoogleTagManagerModule, GoogleTagManagerService } from 'angular-google-tag-manager';
import { IonicSelectableModule } from 'ionic-selectable';
import { ConnectionService } from 'ng-connection-service';
import { NgxImageCompressService } from 'ngx-image-compress';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ElementsState } from './shared/store/element/elementsstate';
import { InterveningState } from './shared/store/intervening/intervening.state';
import { ModalState } from './shared/store/modal-data/modal-data.state';
import { NotificationState } from './shared/store/notification/notification.state';
import { ProcessState } from './shared/store/process/process.state';
import { WitnessState } from './shared/store/witness/witness.state';
import { VideoEditor } from '@ionic-native/video-editor/ngx'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IntervenerService } from '@shared/providers/intervener.service';
import { DatePicker } from '@ionic-native/date-picker/ngx';


registerLocaleData(localePt);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    IonicSelectableModule,
    BrowserModule,
    IonicModule.forRoot({ animated: false, backButtonText: 'Voltar' }),
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    HttpClientModule,
    GoogleTagManagerModule.forRoot({
      id: environment.googleTagManagerId,
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsModule.forRoot([
      UserState,
      AdminsState,
      SurveyorsState,
      EditState,
      SurveyState,
      SignatureState,
      CanAccessState,
      ControllerState,
      MyTemplatesState,
      ObjectState,
      ProcedureState,
      SegmentState,
      ModalState,
      RequestState,
      SurveysListState,
      ElementsState,
      InterveningState,
      ProcessState,
      WitnessState,
      NotificationState,
      PhotoLimitState,
    ]),
    NgxsResetPluginModule.forRoot(),
    NoopAnimationsModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [
    InAppBrowser,
    LocationAccuracy,
    StatusBar,
    SplashScreen,
    FingerprintAIO,
    ConnectionService,
    Network,
    Camera,
    CameraPreview,
    MediaCapture,
    VideoEditor,
    AppVersion,
    SocialSharing,
    Geolocation,
    Device,
    File,
    AppAvailability,
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    SpeechRecognition,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    GoogleTagManagerService,
    NgxImageCompressService,
    IndexedDBService,
    FileTransfer,
    IntervenerService,
    DatePicker
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
