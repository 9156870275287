export class SetCanAccessSurvey {
  static readonly type = '[GUARD] Set can access all survey data';
  constructor(public canAccess: boolean) {}
}
export class SetCanAccessSurveyDetails {
  static readonly type = '[GUARD] Set can access all survey details';
  constructor(public canAccess: boolean) {}
}
export class SetCanAccessSurveyModules {
  static readonly type = '[GUARD] Set can access survey modules';
  constructor(public canAccess: boolean) {}
}
export class SetCanAccessAddAmbience {
  static readonly type = '[GUARD] Set can access add-ambience';
  constructor(public canAccess: boolean) {}
}

export class SetCanAccessAddTemplate {
  static readonly type = '[GUARD] Set can access add-template';
  constructor(public canAccess: boolean) {}
}

export class SetCanAccessEditAdmin {
  static readonly type = '[GUARD] Set can access edit-admin';
  constructor(public canAccess: boolean) {}
}

export class SetCanAccessEditSurveyor {
  static readonly type = '[GUARD] Set can access edit-surveyor';
  constructor(public canAccess: boolean) {}
}

export class SetCanAccessAddSurvey {
  static readonly type = '[GUARD] Set can access new-survey';
  constructor(public canAccess: boolean) {}
}

export class SetCanAccessSurveySettings {
  static readonly type = '[GUARD] Set can access survey settings';
  constructor(public canAccess: boolean) {}
}

export class SetCanAccessReport {
  static readonly type = '[GUARD] Set can access report';
  constructor(public canAccess: boolean) {}
}

export class SetCanAccessEditProcess {
  static readonly type = '[GUARD] Set can access edit process';
  constructor(public canAccess: boolean) {}
}

export class SetCanAccessRegisterComplete {
  static readonly type = '[GUARD] Set can access register complete';
  constructor(public canAccess: boolean) {}
}
