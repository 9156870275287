import { Action, Selector, State, StateContext } from '@ngxs/store';

import {
  AddPendingSurveys,
  MoveSurveyToDoneList,
  MoveSurveyToDonePendingList,
  SetDonePendingSurveys,
  SetDoneSurveys,
  SetInProgressSurveys,
  SetPendingSurveys,
  SetSurveysListState,
  MoveSurveyToInProgressList,
  SetArchivedSurveys,
  SetSelectedSurveysList,
} from './surveys-list.actions';
import { ISurveyList } from './surveys-list.interface';
import { Injectable } from '@angular/core';

/**
 * State of Item
 * Add new props in defaults object
 */
@Injectable()
@State<ISurveyList>({
  name: 'surveysList',
  defaults: {
    pending: {
      page: 0,
      lastPage: 0,
      perPage: 0,
      total: 0,
      data: [],
    },
    inProgress: {
      page: 0,
      lastPage: 0,
      perPage: 0,
      total: 0,
      data: [],
    },
    done: {
      page: 0,
      lastPage: 0,
      perPage: 0,
      total: 0,
      data: [],
    },
    donePending: {
      page: 0,
      lastPage: 0,
      perPage: 0,
      total: 0,
      data: [],
    },
    archived: {
      page: 0,
      lastPage: 0,
      perPage: 0,
      total: 0,
      data: [],
    },
    selected: 'pending',
  },
})
export class SurveysListState {
  /****************************
   * Selectors
   ****************************/
  @Selector()
  static getPendingSurveys(surveys: ISurveyList) {
    return surveys.pending;
  }
  @Selector()
  static getInProgressSurveys(surveys: ISurveyList) {
    return surveys.inProgress;
  }
  @Selector()
  static getDonePendingSurveys(surveys: ISurveyList) {
    return surveys.donePending;
  }
  @Selector()
  static getDoneSurveys(surveys: ISurveyList) {
    return surveys.done;
  }
  @Selector()
  static getArchivedSurveys(surveys: ISurveyList) {
    return surveys.archived;
  }
  @Selector()
  static getSelectedSurveyList(surveys: ISurveyList) {
    return surveys.selected;
  }
  /****************************
   * Actions
   ****************************/

  @Action(SetPendingSurveys)
  setPendingSurveys({ patchState, getState }: StateContext<ISurveyList>, { pending }: SetPendingSurveys) {
    if (pending) {
      const currentState = getState();
      patchState({ ...currentState, pending });
    }
  }

  @Action(AddPendingSurveys)
  addPendingSurveys({ patchState, getState }: StateContext<ISurveyList>, { survey }: AddPendingSurveys) {
    const currentState = getState();
    currentState.pending.data.push(survey);
    currentState.pending.total += 1;
    patchState({ ...currentState });
  }

  @Action(MoveSurveyToInProgressList)
  moveSurveyToInProgressList(
    { patchState, getState }: StateContext<ISurveyList>,
    { survey }: MoveSurveyToInProgressList
  ) {
    const currentState = getState();

    currentState.pending.data.splice(survey.surveyId, 1);
    currentState.inProgress.data.push(survey);
    currentState.pending.total -= 1;
    currentState.inProgress.total += 1;
    patchState({ ...currentState });
  }

  @Action(MoveSurveyToDoneList)
  moveSurveyToDoneList(
    { patchState, getState }: StateContext<ISurveyList>,
    { survey }: MoveSurveyToDoneList
  ) {
    const currentState = getState();

    //Verifica se a vistoria foi de pendente para realizada ou em progresso pararealizada
    if (localStorage.getItem('localVistoria') === null) {
      currentState.inProgress.data.splice(survey.surveyId, 1);
      currentState.inProgress.total -= 1;
    } else {
      currentState.pending.data.splice(survey.surveyId, 1);
      currentState.pending.total -= 1;
    }

    currentState.done.data.push(survey);
    currentState.done.total += 1;
    patchState({ ...currentState });
  }

  @Action(MoveSurveyToDonePendingList)
  moveSurveyToDonePendingList(
    { patchState, getState }: StateContext<ISurveyList>,
    { survey }: MoveSurveyToDonePendingList
  ) {
    const currentState = getState();

    currentState.inProgress.data.splice(survey.surveyId, 1);
    currentState.donePending.data.push(survey);
    currentState.inProgress.total -= 1;
    currentState.donePending.total += 1;
    patchState({ ...currentState });
  }

  @Action(SetInProgressSurveys)
  setInProgressSurveys(
    { patchState, getState }: StateContext<ISurveyList>,
    { inProgress }: SetInProgressSurveys
  ) {
    if (inProgress) {
      const currentState = getState();
      patchState({ ...currentState, inProgress });
    }
  }

  @Action(SetDonePendingSurveys)
  setDonePendingSurveys(
    { patchState, getState }: StateContext<ISurveyList>,
    { donePending }: SetDonePendingSurveys
  ) {
    if (donePending) {
      const currentState = getState();
      patchState({ ...currentState, donePending });
    }
  }

  @Action(SetDoneSurveys)
  setDoneSurveys({ patchState, getState }: StateContext<ISurveyList>, { done }: SetDoneSurveys) {
    if (done) {
      const currentState = getState();
      patchState({ ...currentState, done });
    }
  }

  @Action(SetArchivedSurveys)
  setArchivedSurveys({ patchState, getState }: StateContext<ISurveyList>, { archived }: SetArchivedSurveys) {
    if (archived) {
      const currentState = getState();
      patchState({ ...currentState, archived });
    }
  }

  @Action(SetSurveysListState)
  setSurveysListState({ patchState }: StateContext<ISurveyList>, { surveysListState }: SetSurveysListState) {
    if (surveysListState) {
      patchState(surveysListState);
    }
  }

  @Action(SetSelectedSurveysList)
  SetSelectedSurveysList(
    { patchState, getState }: StateContext<ISurveyList>,
    { selected }: SetSelectedSurveysList
  ) {
    const currentState = getState();
    const keys = ['pendentes', 'progresso', 'realizadas', 'arquivadas'];
    if (keys.includes(selected)) {
      patchState({ ...currentState, selected });
    }
  }
}
