export class SetSignatureForResponsible {
  static readonly type = '[SHARED-SIGNATURE] Set signature to responsible';
  constructor(public responsibleSignature: string) {}
}
export class SetSignatureForSurveyor {
  static readonly type = '[SHARED-SIGNATURE] Set signature to surveyor';
  constructor(public surveyorSignature: string) {}
}
export class SetIsSurveyor {
  static readonly type = `[SHARED-SIGNATURE] Set flag to signature to know who's under edition `;
  constructor(public isSurveyor: boolean) {}
}
