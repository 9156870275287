import { ConnectionStatus } from '@shared/models/constants/ConnectionStatus.enum';
import { TabId } from '@shared/models/types/tab-id.type';

export class SetFlagAddNew {
  static readonly type = '[CONTROLLER] Set flag for add new data';
  constructor(public addNew: boolean) {}
}

export class SetFlagIsLogout {
  static readonly type = '[CONTROLLER] Set flag for logout';
  constructor(public isLogout: boolean) {}
}

export class SetFlagModal {
  static readonly type = '[CONTROLLER] Set flag for modal';
  constructor(public flagModal: boolean) {}
}

export class SetFlagCompleteTemplate {
  static readonly type = '[CONTROLLER] Set flag to complete new template';
  constructor(public completeTemplate: boolean) {}
}

export class SetFlagCompleteSurvey {
  static readonly type = '[CONTROLLER] Set flag to complete new survey';
  constructor(public completeSurvey: boolean) {}
}

export class SetConnectionStatus {
  static readonly type = '[CONTROLLER] Set flag to connection status - isOnline';
  constructor(public connectionStatus: ConnectionStatus) {}
}

export class SetCurrentDoneListHasPendencies {
  static readonly type = '[CONTROLLER] Set flag for current list of done surveys';
  constructor(public hasPendencies: boolean) {}
}
export class SetNoItemsAdded {
  static readonly type = '[CONTROLLER] Set flag for no items add to the ambience';
  constructor(public noItemsAdded: boolean) {}
}
export class SetSelectedTab {
  static readonly type = '[CONTROLLER] Set selected tab for tab component';
  constructor(public selectedTab: TabId) {}
}
