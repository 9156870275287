import { Action, Selector, State, StateContext } from '@ngxs/store';

import {
  SetCanAccessAddAmbience,
  SetCanAccessAddSurvey,
  SetCanAccessAddTemplate,
  SetCanAccessEditAdmin,
  SetCanAccessEditSurveyor,
  SetCanAccessReport,
  SetCanAccessSurvey,
  SetCanAccessSurveyDetails,
  SetCanAccessSurveyModules,
  SetCanAccessSurveySettings,
  SetCanAccessEditProcess
} from './canAccess.actions';
import { ICanAccessStateModel } from './canAccess.interface';
import { Injectable } from '@angular/core';

@Injectable()
@State<ICanAccessStateModel>({
  name: 'canAccess',
  defaults: {
    canAccessSurvey: false,
    canAccessSurveyModules: false,
    canAccessaddAmbience: false,
    canAccessaddTemplate: false,
    canAccessEditAdmin: false,
    canAccessEditSurveyor: false,
    canAccessAddSurvey: false,
    canAccessSurveySettings: false,
    canAccessSurveyDetails: false,
    canAccessReport: false,
    canAccessEditProcess: false,
    canAccessRegisterComplete: false,
  }
})
export class CanAccessState {
  /****************************
   * Selectors
   ****************************/

  @Selector()
  static canAccessSurvey({ canAccessSurvey }: ICanAccessStateModel) {
    return canAccessSurvey;
  }
  @Selector()
  static canAccessSurveyDetails({ canAccessSurveyDetails }: ICanAccessStateModel) {
    return canAccessSurveyDetails;
  }
  @Selector()
  static canAccessSurveyModules({ canAccessSurveyModules }: ICanAccessStateModel) {
    return canAccessSurveyModules;
  }
  @Selector()
  static canAccessaddAmbience({ canAccessaddAmbience }: ICanAccessStateModel) {
    return canAccessaddAmbience;
  }
  @Selector()
  static canAccessaddTemplate({ canAccessaddTemplate }: ICanAccessStateModel) {
    return canAccessaddTemplate;
  }
  @Selector()
  static canAccessEditAdmin({ canAccessEditAdmin }: ICanAccessStateModel) {
    return canAccessEditAdmin;
  }
  @Selector()
  static canAccessEditSurveyor({ canAccessEditSurveyor }: ICanAccessStateModel) {
    return canAccessEditSurveyor;
  }
  @Selector()
  static canAccessAddSurvey({ canAccessAddSurvey }: ICanAccessStateModel) {
    return canAccessAddSurvey;
  }
  @Selector()
  static canAccessSurveySettings({ canAccessSurveySettings }: ICanAccessStateModel) {
    return canAccessSurveySettings;
  }
  @Selector()
  static canAccessReport({ canAccessReport }: ICanAccessStateModel) {
    return canAccessReport;
  }
  @Selector()
  static canAccessEditProcess({ canAccessEditProcess }: ICanAccessStateModel) {
    return canAccessEditProcess;
  }
  @Selector()
  static canAccessRegisterComplete({ canAccessRegisterComplete }: ICanAccessStateModel) {
    return canAccessRegisterComplete;
  }
  /****************************
   * Actions
   ****************************/

  @Action(SetCanAccessSurvey)
  setUserEditId({ patchState }: StateContext<ICanAccessStateModel>, { canAccess }: SetCanAccessSurvey) {
    patchState({ canAccessSurvey: canAccess });
  }

  @Action(SetCanAccessSurveyModules)
  setCanAccessSurveyModules(
    { patchState }: StateContext<ICanAccessStateModel>,
    { canAccess }: SetCanAccessSurveyModules
  ) {
    patchState({ canAccessSurveyModules: canAccess });
  }

  @Action(SetCanAccessSurveyDetails)
  setCanAccessSurveyDetails(
    { patchState }: StateContext<ICanAccessStateModel>,
    { canAccess }: SetCanAccessSurveyDetails
  ) {
    patchState({ canAccessSurveyDetails: canAccess });
  }

  @Action(SetCanAccessAddAmbience)
  setCanAccessAddAmbience(
    { patchState }: StateContext<ICanAccessStateModel>,
    { canAccess }: SetCanAccessAddAmbience
  ) {
    patchState({ canAccessaddAmbience: canAccess });
  }

  @Action(SetCanAccessAddTemplate)
  setCanAccessAddTemplate(
    { patchState }: StateContext<ICanAccessStateModel>,
    { canAccess }: SetCanAccessAddTemplate
  ) {
    patchState({ canAccessaddTemplate: canAccess });
  }

  @Action(SetCanAccessEditAdmin)
  setCanAccessEditAdmin(
    { patchState }: StateContext<ICanAccessStateModel>,
    { canAccess }: SetCanAccessEditAdmin
  ) {
    patchState({ canAccessEditAdmin: canAccess });
  }

  @Action(SetCanAccessEditSurveyor)
  setCanAccessEditSurveyor(
    { patchState }: StateContext<ICanAccessStateModel>,
    { canAccess }: SetCanAccessEditSurveyor
  ) {
    patchState({ canAccessEditSurveyor: canAccess });
  }

  @Action(SetCanAccessAddSurvey)
  setCanAccessAddSurvey(
    { patchState }: StateContext<ICanAccessStateModel>,
    { canAccess }: SetCanAccessAddSurvey
  ) {
    patchState({ canAccessAddSurvey: canAccess });
  }

  @Action(SetCanAccessSurveySettings)
  setCanAccessSurveySettings(
    { patchState }: StateContext<ICanAccessStateModel>,
    { canAccess }: SetCanAccessSurveySettings
  ) {
    patchState({ canAccessSurveySettings: canAccess });
  }

  @Action(SetCanAccessReport)
  setCanAccessReport({ patchState }: StateContext<ICanAccessStateModel>, { canAccess }: SetCanAccessReport) {
    patchState({ canAccessReport: canAccess });
  }

  @Action(SetCanAccessEditProcess)
  setCanAccessEditProcess(
    { patchState }: StateContext<ICanAccessStateModel>,
    { canAccess }: SetCanAccessEditProcess
  ) {
    patchState({ canAccessEditProcess: canAccess });
  }
}
