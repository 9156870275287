import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ConnectionStatus } from '@shared/models/constants/ConnectionStatus.enum';

import {
  SetConnectionStatus,
  SetFlagAddNew,
  SetFlagCompleteSurvey,
  SetFlagCompleteTemplate,
  SetCurrentDoneListHasPendencies,
  SetNoItemsAdded,
  SetSelectedTab,
  SetFlagIsLogout,
  SetFlagModal,
} from './controller.actions';
import { IController } from './controller.interface';
import { Injectable } from '@angular/core';

/**
 * Use this when
 */
@Injectable()
@State<IController>({
  name: 'controller',
  defaults: {
    addNew: false,
    completeTemplate: false,
    completeSurvey: false,
    searchTerm: '',
    connectionStatus: ConnectionStatus.Online,
    canUseOfflineMode: true,
    hasPendencies: false,
    noItemsAdded: false,
    selectedTab: '',
    isLogout: false,
    flagModal: true,
  },
})
export class ControllerState {
  /****************************
   * Selectors
   ****************************/

  @Selector()
  static addNew({ addNew }: IController) {
    return addNew;
  }

  @Selector()
  static flagModal({ flagModal }: IController) {
    return flagModal;
  }

  @Selector()
  static isLogout({ isLogout }: IController) {
    return isLogout;
  }

  @Selector()
  static completeTemplate({ completeTemplate }: IController) {
    return completeTemplate;
  }

  @Selector()
  static completeSurvey({ completeSurvey }: IController) {
    return completeSurvey;
  }

  @Selector()
  static getConnectionStatus({ connectionStatus }: IController) {
    return connectionStatus;
  }

  @Selector()
  static getCanUseOfflineMode({ canUseOfflineMode }: IController) {
    return canUseOfflineMode;
  }

  @Selector()
  static getCurrentListHasPendencies({ hasPendencies }: IController) {
    return hasPendencies;
  }

  @Selector()
  static getNoItemsAdded({ noItemsAdded }: IController) {
    return noItemsAdded;
  }

  @Selector()
  static getSelectedTab({ selectedTab }: IController) {
    return selectedTab;
  }
  /****************************
   * Actions
   ****************************/

  @Action(SetFlagAddNew)
  setFlagAddNew({ patchState }: StateContext<IController>, { addNew }: SetFlagAddNew) {
    patchState({ addNew });
  }

  @Action(SetFlagIsLogout)
  SetFlagIsLogout({ patchState }: StateContext<IController>, { isLogout }: SetFlagIsLogout) {
    patchState({ isLogout });
  }

  @Action(SetFlagModal)
  SetFlagModal({ patchState }: StateContext<IController>, { flagModal }: SetFlagModal) {
    patchState({ flagModal });
  }

  @Action(SetFlagCompleteTemplate)
  setFlagCompleteTemplate(
    { patchState }: StateContext<IController>,
    { completeTemplate }: SetFlagCompleteTemplate
  ) {
    patchState({ completeTemplate });
  }

  @Action(SetFlagCompleteSurvey)
  setFlagCompleteSurvey(
    { patchState }: StateContext<IController>,
    { completeSurvey }: SetFlagCompleteSurvey
  ) {
    patchState({ completeSurvey });
  }

  @Action(SetConnectionStatus)
  setConnectionStatus({ patchState }: StateContext<IController>, { connectionStatus }: SetConnectionStatus) {
    patchState({ connectionStatus });
  }

  @Action(SetCurrentDoneListHasPendencies)
  setCurrentDoneListHasPendencies(
    { patchState }: StateContext<IController>,
    { hasPendencies }: SetCurrentDoneListHasPendencies
  ) {
    patchState({ hasPendencies });
  }

  @Action(SetNoItemsAdded)
  setNoItemsAdded({ patchState }: StateContext<IController>, { noItemsAdded }: SetNoItemsAdded) {
    patchState({ noItemsAdded });
  }
  @Action(SetSelectedTab)
  setSelectedTab({ patchState }: StateContext<IController>, { selectedTab }: SetSelectedTab) {
    patchState({ selectedTab });
  }
}
