import { UrlSegment } from '@angular/router';

import { Store } from '@ngxs/store';
import { PROFILEIDS } from '@shared/models/constants/profileIds';

import { AuthenticationService } from './authentication.service';

export class Matcher {
  constructor(public store: Store) {}
  // AGS(1), AM(2), ADM(3), VISTORIADOR(4);

  public static checkUserIsMaster(url: UrlSegment[]) {
    const newUrlSegment: UrlSegment[] = [];
    const loggedUser = AuthenticationService.instance.getAuthData();
    const profileId = loggedUser ? loggedUser.profileId : null;
    return profileId === PROFILEIDS.adminMaster ? { consumed: newUrlSegment } : null;
  }

  public static checkUserIsAdmin(url: UrlSegment[]) {
    const newUrlSegment: UrlSegment[] = [];
    const loggedUser = AuthenticationService.instance.getAuthData();
    const profileId = loggedUser ? loggedUser.profileId : null;
    return profileId === PROFILEIDS.admin ? { consumed: newUrlSegment } : null;
  }

  public static checkUserIsSurveyor(url: UrlSegment[]) {
    const newUrlSegment: UrlSegment[] = [];
    const loggedUser = AuthenticationService.instance.getAuthData();
    const profileId = loggedUser ? loggedUser.profileId : null;
    return profileId === PROFILEIDS.surveyor ? { consumed: newUrlSegment } : null;
  }

public static sendToLogin(url: UrlSegment[]) {
  const hasUserLogged = AuthenticationService.instance.hasUserLogged;
  if (hasUserLogged === false) {
    return { consumed: url };
  }

  return null;
}

  public static sendToProfileModule(url: UrlSegment[]) {
    const newUrlSegment: UrlSegment[] = [];
    const hasUserLogged = AuthenticationService.instance.hasUserLogged;
    return hasUserLogged === true ? { consumed: newUrlSegment } : null;
  }
}
