/* eslint-disable prettier/prettier */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { Store } from '@ngxs/store';
import { MODULETYPESIDS } from '@shared/models/constants/moduleTypeId';
import { List } from '@shared/models/interfaces/List.interface';
import { Response } from '@shared/models/interfaces/response.interface';
import { SurveyData } from '@shared/models/interfaces/survey.interface';

import { SurveyInformations } from '@shared/store/survey/survey.interface';
import { HttpServiceV2 } from './http-v2.service';
import { IndexedDBService } from './indexed-db.service';

@Injectable({
  providedIn: 'root',
})
export class SurveysService extends HttpServiceV2 {
  constructor(httpClient: HttpClient, store: Store, private indexedDBService: IndexedDBService) {
    super(environment.endpoints.surveys, httpClient, store);
  }
  list(
    token: string,
    page: number,
    address = '',
    status: number,
    maintenanceRequested = false,
    userId = null
  ): Promise<List<SurveyInformations>> {
    let params: HttpParams;
    if (maintenanceRequested !== null) {
      params = new HttpParams()
        .set('perPage', String(environment.perPage))
        .set('page', String(page))
        .set('address', String(address))
        .set('maintenanceRequested', String(maintenanceRequested))
        .set('status', String(status));
    } else {
      params = new HttpParams()
        .set('perPage', String(environment.perPage))
        .set('page', String(page))
        .set('address', String(address))
        .set('status', String(status));
    }

    if (userId) {
      params = params.append('userId', userId);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
      params,
    };
    this.indexedDBService.clearIndexedDB();
    return this.getAsync<List<SurveyInformations>>('', httpOptions);
  }

  listArchived(token: string, page: number, address = '', userId = null): Promise<List<SurveyInformations>> {
    let params: HttpParams;
    params = new HttpParams()
      .set('perPage', String(environment.perPage))
      .set('page', String(page))
      .set('address', String(address));

    if (userId) {
      params = params.append('userId', userId);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
      params,
    };
    return this.getAsync<List<SurveyInformations>>('/archived', httpOptions);
  }

  getById(surveyId: string | number): Promise<any> {
    return this.getAsync<any>(`/${surveyId}`);
  }
  create(surveyData: SurveyData): Promise<Response<any>> {
    return this.postAsync(surveyData);
  }
  update(surveyData: SurveyData): Promise<Response<any>> {
    return this.putAsyncV2(surveyData, `/${surveyData.survey.surveyId}`);
  }
  deleteSurvey(surveyId: number): Promise<unknown> {
    return this.deleteAsync(`/${surveyId}`);
  }
  archiveSurvey(surveyId: number, archive: boolean): Promise<unknown> {
    return this.putAsync({ surveyArchived: archive }, `/archived/${surveyId}`);
  }

  copy(surveyId: number, surveyorId: number, procedureId: number): Promise<Response<any>> {
    return this.postAsync(
      {
        procedureId,
        surveyorId,
      },
      `/copy/${surveyId}`
    );
  }

  async getPhotosFromSurvey(survey: SurveyInformations): Promise<string[]> {
    if (!survey) {
      return;
    }
    let photoUrlsForDownload = [];

    survey.elements.forEach(element => {
      const { items } = element;
      if (items) {
        items.forEach(item => {
          const { evaluationDetail } = item;

          if (evaluationDetail && evaluationDetail.evaluationDetailsItemsPhotos) {
            photoUrlsForDownload = photoUrlsForDownload.concat(
              evaluationDetail.evaluationDetailsItemsPhotos.map(
                (evaluationDetailsItemsPhoto: { evaluationDetailItemPhotoUrl: any }) =>
                  evaluationDetailsItemsPhoto.evaluationDetailItemPhotoUrl
              )
            );
          } else if (evaluationDetail && evaluationDetail.evaluationDetailsItemsPhotos) {
            photoUrlsForDownload = photoUrlsForDownload.concat(evaluationDetail.evaluationDetailsItemsPhotos);
          }
        });
        return;
      }
      const { evaluationDetail } = element;
      const { moduleTypeId } = element.moduleType;

      if (evaluationDetail) {
        switch (moduleTypeId) {
          case MODULETYPESIDS.KeysAndControls:
            if (evaluationDetail.evaluationDetailsItemPhotoUrl) {
              const { evaluationDetailsItemPhotoUrl = [] } = evaluationDetail;
              photoUrlsForDownload = photoUrlsForDownload.concat(evaluationDetailsItemPhotoUrl);
            } else {
              evaluationDetail.forEach((evaluation: { evaluationDetailKeysControlsPhotos?: any[] }) => {
                const { evaluationDetailKeysControlsPhotos = [] } = evaluation;

                if (evaluationDetailKeysControlsPhotos.length > 0) {
                  photoUrlsForDownload = photoUrlsForDownload.concat(
                    evaluationDetailKeysControlsPhotos.map(
                      (evaluationDetailKeysControlsPhoto: { evaluationDetailKeysControlsPhotoUrl: any }) =>
                        evaluationDetailKeysControlsPhoto.evaluationDetailKeysControlsPhotoUrl ||
                        evaluationDetailKeysControlsPhoto
                    )
                  );
                }
              });
            }
            break;
          case MODULETYPESIDS.ConsumeMeasurers:
            if (evaluationDetail.evaluationDetailsItemPhotoUrl) {
              const { evaluationDetailsItemPhotoUrl = [] } = evaluationDetail;
              photoUrlsForDownload = photoUrlsForDownload.concat(evaluationDetailsItemPhotoUrl);
            } else {
              evaluationDetail.forEach((evaluation: { evaluationDetailConsumptionMeterPhotos?: any[] }) => {
                const { evaluationDetailConsumptionMeterPhotos = [] } = evaluation;
                photoUrlsForDownload = photoUrlsForDownload.concat(
                  evaluationDetailConsumptionMeterPhotos.map(
                    (evaluationDetailsConsumptionMeterPhoto: {
                      evaluationDetailConsumptionMeterPhotoUrl: any;
                    }) => {
                      return (
                        evaluationDetailsConsumptionMeterPhoto.evaluationDetailConsumptionMeterPhotoUrl ||
                        evaluationDetailsConsumptionMeterPhoto
                      );
                    }
                  )
                );
              });
            }
            break;
        }
      }
    });
    return photoUrlsForDownload;
  }

  sort(surveyId: number, elementOrModuleId: string | number, array: []): Promise<Response<any>> {
    return this.putAsync(array, `/sort/${surveyId}/${elementOrModuleId}`);
  }

  countPhotosBySurvey(surveyId: string | number, token): Promise<any> {
    let params: HttpParams;
    params = new HttpParams().set('surveyId', String(surveyId));

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
      params,
    };

    return this.getAsync<any>(`/${environment.endpoints.countPhotos}`, httpOptions);
  }
}
