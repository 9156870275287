import { State, Selector, Action, StateContext } from '@ngxs/store';
import { List } from '@shared/models/interfaces/List.interface';
import { Procedure } from '@shared/models/interfaces/procedures.interface';

import { SetProcedures } from './procedures.actions';
import { Injectable } from '@angular/core';

/**
 * State of Procedure
 * Add new props in defaults object
 */
@Injectable()
@State<List<Procedure>>({
  name: 'procedures',
  defaults: {
    total: 0,
    perPage: 0,
    page: 0,
    lastPage: 0,
    data: []
  }
})
export class ProcedureState {
  /****************************
   * Selectors
   ****************************/
  @Selector()
  static getProcedures(procedureState: List<Procedure>) {
    return procedureState;
  }

  /****************************
   * Actions
   ****************************/

  @Action(SetProcedures)
  setItems({ patchState }: StateContext<List<Procedure>>, { procedures }: SetProcedures) {
    patchState(procedures);
  }
}
