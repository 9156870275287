import { Element } from '@shared/models/interfaces/element.interface';
import { Item } from '@shared/models/interfaces/item.interface';
import { List } from '@shared/models/interfaces/List.interface';
import { Template } from '@shared/models/interfaces/template.interface';
export class SetItems {
  static readonly type = '[MY-TEMPLATES] Set Items';
  constructor(public items: List<Item>) {}
}

export class SetAmbiences {
  static readonly type = '[MY-TEMPLATES] Set Ambiences';
  constructor(public ambiences: List<Element>) {}
}

export class AddAmbienceName {
  static readonly type = '[MY-TEMPLATES] Set Name for new Ambience';
  constructor(public elementName: string) {}
}

export class UpdateAmbience {
  static readonly type = '[MY-TEMPLATES] Update Ambience';
  constructor(public element: Element) {}
}

export class DeleteAmbience {
  static readonly type = '[MY-TEMPLATES] Delete Ambience';
  constructor(public element: Element) {}
}

export class AddAmbience {
  static readonly type = '[MY-TEMPLATES] Set new Ambience';
  constructor(public element: Element) {}
}

export class AddAmbienceItems {
  static readonly type = '[MY-TEMPLATES] Set Items for new Ambience';
  constructor(public items: Item[]) {}
}
export class SetTemplates {
  static readonly type = '[MY-TEMPLATES] Set templates';
  constructor(public templates: List<Template>) {}
}

export class SetAdminTemplates {
  static readonly type = '[MY-TEMPLATES] Set admin templates';
  constructor(public adminTemplates: List<Template>) {}
}

export class AddTemplates {
  static readonly type = '[MY-TEMPLATES] Add templates';
  constructor(public templates: List<Template>) {}
}
export class AddTemplateName {
  static readonly type = '[MY-TEMPLATES] Set Name for new Template';
  constructor(public templateName: string) {}
}

export class AddTemplateElements {
  static readonly type = '[MY-TEMPLATES] Set Elements for new Template';
  constructor(public elements: Element[]) {}
}

export class AddTemplate {
  static readonly type = '[MY-TEMPLATES] Set new Template';
  constructor(public template: Template) {}
}

export class UpdateTemplate {
  static readonly type = '[MY-TEMPLATES] Update Template';
  constructor(public template: Template) {}
}

export class DeleteTemplate {
  static readonly type = '[MY-TEMPLATES] Delete Template';
  constructor(public template: Template) {}
}

export class AddItem {
  static readonly type = '[MY-TEMPLATES] Add Item';
  constructor(public item: Item) {}
}

export class DeleteItem {
  static readonly type = '[MY-TEMPLATES] Delete Item';
  constructor(public item: Item) {}
}

export class UpdateItem {
  static readonly type = '[MY-TEMPLATES] Update Item';
  constructor(public item: Item) {}
}

export class SetTemplatesState {
  static readonly type = '[MY-TEMPLATES] Set Templates State';
  constructor(public templatesState: any) {}
}
