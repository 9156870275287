import { UserState } from '@shared/store/logged-user/user.state';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Platform } from '@ionic/angular';

import { environment } from '@environments/environment';
import { Store } from '@ngxs/store';
import { ConnectionStatus } from '@shared/models/constants/ConnectionStatus.enum';
import { SURVEYSTATUS } from '@shared/models/constants/surveyStatus';
import { SetConnectionStatus } from '@shared/store/controller/controller.actions';
import {
  RemoveRequestToQueue,
  SetRequestQueue,
  AddRequestToQueue,
  AddSurveyOnFailQueue,
} from '@shared/store/request/request.actions';
import { IRequest } from '@shared/store/request/request.interface';
import { RequestState } from '@shared/store/request/request.state';
import { Subscription, EMPTY, of } from 'rxjs';
import { v1 as uuidv1 } from 'uuid';

import { AddSurveyOnFinalizedQueue, RemoveToOnFailQueue } from './../store/request/request.actions';
import { ISurveyRequest } from './../store/request/request.interface';
import { NetworkService } from './network.service';
import { PouchIdbService } from './pouch-idb.service';
import { SurveysService } from './surveys.service';
import { UploadService } from './upload.service';
import { UtilsService } from './utils.service';
import { TranslateService } from '@ngx-translate/core';
import { SurveyInformations } from '@shared/store/survey/survey.interface';

@Injectable({
  providedIn: 'root',
})
export class ManageOfflineRequestsService {
  private subscription = new Subscription();

  constructor(
    private store: Store,
    private networkService: NetworkService,
    private pouchService: PouchIdbService,
    private utils: UtilsService,
    private platform: Platform,
    private surveyService: SurveysService,
    private uploadService: UploadService,
    private translate: TranslateService
  ) {}
  private surveyEndpoints = [
    environment.endpoints.surveyStatusHistories,
    environment.endpoints.surveyElementSettings,
    environment.endpoints.evaluationElements,
    environment.endpoints.evaluationItems,
    environment.endpoints.evaluationKeys,
    environment.endpoints.evaluationResponsible,
    environment.endpoints.evaluationConsumptionMeter,
    environment.endpoints.evaluationAddItems,
    environment.endpoints.evaluationSignature,
  ];
  public async monitoringStatusConnection() {
    await this.platform.ready();
    this.subscription = this.networkService.onNetworkChange().subscribe(async isConnected => {
      this.store.dispatch(new SetConnectionStatus(isConnected));
      if (isConnected === ConnectionStatus.Online) {
        await this.doQueueRequests();
      }
    });
  }

  private async doQueueRequests() {
    const requisitionQueue = JSON.parse(
      JSON.stringify(this.store.selectSnapshot(RequestState.getRequisitionQueue))
    );
    if (requisitionQueue && requisitionQueue.length > 0) {
      this.utils.showToast('', `${this.translate.instant('PROVIDERS.SYNCHRONIZING_YOUR')}`, 2000, 'top');
      for (const request of requisitionQueue) {
        const { method, path, body } = request;
        const bodyParsed = typeof body === 'string' ? JSON.parse(body) : body;
        if (path.includes(environment.endpoints.evaluationSignature)) {
          const signatures = bodyParsed.map(ev => ev.evaluationDetailSignaturePhotoUrl);
          const { uploadData } = this.manipulateBodyDataToUpload(signatures, request._id);
          if (uploadData.length) {
            const res = await this.uploadService.uploadBase64(uploadData);
            const urls = res.data ? res.data.urls : [];
            for (let i = 0; i < bodyParsed.length; i++) {
              if (
                bodyParsed[i] &&
                urls[i] &&
                !bodyParsed[i].evaluationDetailSignaturePhotoUrl.includes('https://')
              ) {
                // eslint-disable-next-line require-atomic-updates
                bodyParsed[i].evaluationDetailSignaturePhotoUrl = urls[i];
              }
            }
          }
        } else if (
          path.includes(environment.endpoints.evaluationItems) ||
          path.includes(environment.endpoints.evaluationConsumptionMeter)
        ) {
          const { bodyWithoutBase64, uploadData } = this.manipulateBodyDataToUpload(
            bodyParsed.evaluationDetailsItemPhotoUrl,
            bodyParsed.evaluationSurveyElementItemId
          );
          if (uploadData.length) {
            const res = await this.uploadService.uploadBase64(uploadData);
            const urls = res.data ? res.data.urls : [];
            // eslint-disable-next-line require-atomic-updates
            bodyParsed.evaluationDetailsItemPhotoUrl = [...bodyWithoutBase64, ...urls];
            request.body = bodyParsed;
            this.store.dispatch(new RemoveRequestToQueue(request._id));
            this.store.dispatch(new AddRequestToQueue(request));
          }
        }
        // responseCache = null;
        // }
        try {
          if (!this.validateSurveyFinalRequest(path, bodyParsed)) {
            const response = await this.pouchService.httpRequest(method, path, bodyParsed);
            // we can log this

            this.removeRequestFromLocalStore(request);
          }
        } catch (error) {
          let surveyId;
          // we can log this
          // Verify if request is a survey
          this.surveyEndpoints.forEach(endpoint => {
            if (path.includes(endpoint)) {
              // verify method to know if we get id from URL, from body object or from an array
              if (method.toUpperCase() === 'DELETE') {
                // only surveySettings and evaluationElements has Delete
                // and the path is: ...survey_element_settings/<surveyId>/<moduleId>
                const splitedPath = path.split(',');
                surveyId = splitedPath[path.split(',').length - 2];
              } else {
                if (bodyParsed instanceof Object) {
                  surveyId = bodyParsed.surveyId;
                } else if (bodyParsed instanceof Array) {
                  const objWithSurveyId = bodyParsed.find(array => array.surveyId);
                  surveyId = objWithSurveyId.surveyId;
                } else {
                  console.debug('Unknow body instance');
                }
              }
              return 0;
            }
          });

          if (surveyId) {
            this.manageFailedSurveyRequest(surveyId, request);
          }
        }
      }
      this.utils.showToast('', `${this.translate.instant('PROVIDERS.SYNCHRONIZED_DATA')}`, 2000, 'top');
    }
  }

  private validateSurveyFinalRequest(path: string, bodyParsed: any) {
    const surveyFailQueue = this.store.selectSnapshot(RequestState.getSurveyFailQueue);
    return (
      path.includes(environment.endpoints.surveyStatusHistories) &&
      surveyFailQueue.find((req: ISurveyRequest) => req.surveyId === bodyParsed.surveyId)
    );
  }

  private manipulateBodyDataToUpload(bodyData: any, nameId) {
    const uploadData = [];
    const bodyWithoutBase64 = [];
    bodyData.forEach((signatureData, index) => {
      if (!signatureData.includes('https://')) {
        uploadData.push({
          fileBase64: signatureData,
          fileName: `${nameId}-photo-${index}`,
        });
      } else {
        bodyWithoutBase64.push(signatureData);
      }
    });
    // uploadData = uploadData.filter(b => b !== undefined);
    // bodyWithoutBase64 = bodyData.filter(a => a.includes('https://'));
    return { bodyWithoutBase64, uploadData };
  }

  private removeRequestFromLocalStore(request: any) {
    this.store.dispatch(new RemoveRequestToQueue());
    this.pouchService.deletePouchData(request);
  }

  public async manageFailedSurveyRequest(surveyId, request) {
    const finalizedSurveys = this.store.selectSnapshot(RequestState.getFinalizedSurveys);
    const failedRequests = this.store.selectSnapshot(RequestState.getSurveyFailQueue);
    if (finalizedSurveys.find(id => id === surveyId)) {
      this.removeRequestFromLocalStore(request);
    } else if (failedRequests.find((surveyRequest: ISurveyRequest) => surveyRequest.surveyId === surveyId)) {
      console.debug(`A vistoria ${surveyId} possui um erro`);
    } else {
      this.store.dispatch(new AddSurveyOnFailQueue({ surveyId, request }));
      const survey: SurveyInformations = (await this.surveyService.getById(surveyId)).data.survey;
      if (survey.surveyStatus === SURVEYSTATUS.done || survey.surveyStatus === SURVEYSTATUS.donePending) {
        this.store.dispatch(new AddSurveyOnFinalizedQueue(surveyId));
        this.removeRequestFromLocalStore(request);
        this.store.dispatch(new RemoveToOnFailQueue(surveyId));
      } else {
        console.debug(`A vistoria não ${surveyId} não foi finalizada. Status ${survey.surveyStatus}`);
      }
    }
  }

  public async setPendingRequestsToState() {
    const queue = await this.pouchService.fetch();
    if (queue) {
      const requests: IRequest[] = [];
      queue.rows.forEach(requisition => {
        requests.push({
          _id: requisition.doc._id,
          body: requisition.doc.body,
          path: requisition.doc.path,
          method: requisition.doc.method,
        });
      });
      this.store.dispatch(new SetRequestQueue(requests));
      this.doQueueRequests();
    }
  }

  public manageOfflineRequest(req) {
    const reqId = uuidv1();
    const bodyOffline = this.addOfflineIdentifier(req.body, reqId);
    const document = {
      body: bodyOffline,
      method: req.method,
      path: req.urlWithParams.split(`${environment.apiVersion}/`)[1],
    };
    if (req.method !== 'GET') {
      this.utils.showToast('', `${this.translate.instant('PROVIDERS.OPERATING_IN')}`);
      // File requests cant be in offline queue for prevent loop of file requests.
      if (!document.path.includes('file/base')) {
        this.pouchService.putPouchData(document, reqId);
        this.store.dispatch(new AddRequestToQueue({ _id: reqId, ...document }));
      }
    } else {
      // here we can store current get request
      return EMPTY;
    }
    return of(new HttpResponse({ status: 200, body: bodyOffline }));
  }

  /**
   * Uuid is used to identify proprieties in offline mode
   */
  public addOfflineIdentifier(body, reqId) {
    const bodyParsed = typeof body === 'string' ? JSON.parse(body) : body;
    if (bodyParsed !== null) {
      // verify if body is an array
      if (bodyParsed instanceof Array) {
        bodyParsed.forEach(arrayData => {
          arrayData.requestId = uuidv1();
        });
      } else {
        bodyParsed.requestId = reqId;
      }
    }
    return bodyParsed;
  }

  public unsubscribe() {
    this.subscription.unsubscribe();
  }
}
