import { State, Selector, Action, StateContext } from '@ngxs/store';
import { List } from '@shared/models/interfaces/List.interface';
import { SegmentObject } from '@shared/models/interfaces/object.interface';

import { SetObjects } from './objects.actions';
import { Injectable } from '@angular/core';

/**
 * State of Object
 * Add new props in defaults object
 */
@Injectable()
@State<List<SegmentObject>>({
  name: 'objects',
  defaults: {
    total: 0,
    perPage: 0,
    page: 0,
    lastPage: 0,
    data: []
  }
})
export class ObjectState {
  /****************************
   * Selectors
   ****************************/
  @Selector()
  static getObjects(objectState: List<SegmentObject>) {
    return objectState;
  }

  /****************************
   * Actions
   ****************************/

  @Action(SetObjects)
  setItems({ patchState }: StateContext<List<SegmentObject>>, { objects }: SetObjects) {
    patchState(objects);
  }
}
