import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Store } from '@ngxs/store';
import { Response } from '@shared/models/interfaces/response.interface';
import { Intervener } from '@shared/models/interfaces/survey.interface';
import { HttpService } from './http.service';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root',
})
export class IntervenerService extends HttpService {
  constructor(private utils: UtilsService, private httpClient: HttpClient, private store: Store) {
    super(environment.endpoints.evaluationDetailsIntervener, httpClient, store);
  }

  async listBySurvey(surveyId: number) {
    return this.getAsync<Response<Intervener[]>>(`/${surveyId}`);
  }

  async update(surveyId: number, intervenerList: Intervener[]) {
    return this.putAsync<Intervener[], Response<Intervener[]>>(intervenerList, `/${surveyId}`);
  }

  deleteIntervening(surveyId: number, idList: number[]) {
    return this.deleteAsyncWithBody<Response<any>>(`/${surveyId}`, idList);
  }
}
