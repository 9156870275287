import { Action, Selector, State, StateContext } from '@ngxs/store';
import { TokenService } from '@shared/providers/token.service';

import { Injectable } from '@angular/core';
import { SetUserInformations, SetUserToken } from './user.actions';
import { UserStateModel } from './user.interface';

/**
 * State of User
 * Add new props in defaults object
 */
@State<UserStateModel>({
  name: 'user',
  defaults: {
    userToken: undefined,
    userTokenData: undefined,
  },
})
@Injectable()
export class UserState {
  constructor(private tokenService: TokenService) {}

  /****************************
   * Selectors
   ****************************/
  @Selector()
  static getUserToken({ userToken }: UserStateModel) {
    return userToken ?? '';
  }
  @Selector()
  static getUserInformations({ userTokenData }: UserStateModel) {
    return userTokenData.userInformations;
  }

  /****************************
   * Actions
   ****************************/

  @Action(SetUserToken)
  setUserToken({ patchState }: StateContext<UserStateModel>, { userToken }: SetUserToken) {
    const userTokenData = this.tokenService.deserializeToken(userToken);
    const settings = JSON.parse(localStorage.getItem('settings'));

    if (settings) {
      userTokenData.userInformations.userUsePhotoBetweenElements = settings.userUsePhotoBetweenElements;
      userTokenData.userInformations.photoSizeTypeId = settings.photoSizeTypeId;
    }
    userTokenData.userInformations.userPhotoUrl = userTokenData.userInformations.userPhotoUrl
      ? `${userTokenData.userInformations.userPhotoUrl}?update=${new Date().getTime()}`
      : null;
    patchState({ userToken, userTokenData });
  }

  @Action(SetUserInformations)
  setUserInformations(
    { patchState, getState }: StateContext<UserStateModel>,
    { userData }: SetUserInformations,
  ) {
    const tokenState = getState();
    tokenState.userTokenData.userInformations = userData;
    tokenState.userTokenData.userInformations.userPhotoUrl = `${
      tokenState.userTokenData.userInformations.userPhotoUrl
    }?update=${new Date().getTime()}`;

    patchState(tokenState);
  }
}
