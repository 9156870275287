import { Action, Selector, State, StateContext } from '@ngxs/store';

import { Injectable } from '@angular/core';
import {
  AddSelectedElement,
  ClearRemovedElement,
  ClearSelectedAndRemovedElement,
  ClearSelectedElement,
  RemoveSelectedElement,
  SetModalData,
  SetModalSelectedElements,
  SetUpdateElements,
} from './modal-data.actions';
import { ModalData } from './modal-data.interface';
/**
 * Use this when
 */
@Injectable()
@State<ModalData>({
  name: 'modalData',
  defaults: {},
})
export class ModalState {
  /****************************
   * Selectors
   ****************************/

  @Selector()
  static getModalData({ data }: ModalData) {
    return data;
  }

  @Selector()
  static getModalSelectedElements({ selectedElements }: ModalData) {
    return selectedElements;
  }

  @Selector()
  static getModalRemovedElements({ removedElements }: ModalData) {
    return removedElements;
  }

  @Selector()
  static getUpdateElements({ updateElements }: ModalData) {
    return updateElements;
  }

  /****************************
   * Actions
   ****************************/

  @Action(SetModalData)
  setModalData({ patchState }: StateContext<ModalData>, { data }: SetModalData) {
    patchState({ data });
  }

  @Action(SetModalSelectedElements)
  setModalSelectedElements({ patchState }: StateContext<ModalData>, { data }: SetModalSelectedElements) {
    patchState({ selectedElements: data });
  }

  @Action(SetUpdateElements)
  setUpdateElements({ patchState }: StateContext<ModalData>, { data }: SetUpdateElements) {
    patchState({ updateElements: data });
  }

  @Action(AddSelectedElement)
  addSelectedElement({ patchState, getState }: StateContext<ModalData>, { data }: AddSelectedElement) {
    const currentState = getState();
    if (!currentState?.selectedElements) {
      currentState.selectedElements = [];
    }
    currentState.selectedElements.push(data);

    if (currentState.removedElements && currentState.removedElements.length > 0) {
      const index = this.findIndexToElement(data, currentState.removedElements);

      if (index !== -1) {
        currentState.removedElements.splice(index, 1);
        patchState(currentState);
      }
    }

    patchState(currentState);
  }

  @Action(RemoveSelectedElement)
  removeSelectedElement({ patchState, getState }: StateContext<ModalData>, { data }: RemoveSelectedElement) {
    const currentState = getState();

    if (currentState.selectedElements && currentState.selectedElements.length > 0) {
      const index = this.findIndexToElement(data, currentState.selectedElements);

      if (index !== -1) {
        currentState.selectedElements.splice(index, 1);
        patchState(currentState);
      }
    }

    if (!currentState?.removedElements) {
      currentState.removedElements = [];
    }
    currentState.removedElements.push(data);
    patchState(currentState);
  }

  @Action(ClearSelectedElement)
  clearSelectedElement({ patchState, getState }: StateContext<ModalData>) {
    const currentState = getState();
    currentState.selectedElements = [];
    patchState(currentState);
  }

  @Action(ClearRemovedElement)
  clearRemovedElement({ patchState, getState }: StateContext<ModalData>) {
    const currentState = getState();
    currentState.removedElements = [];
    patchState(currentState);
  }

  @Action(ClearSelectedAndRemovedElement)
  clearSelectedAndRemovedElement({ dispatch }: StateContext<ModalData>) {
    dispatch(new ClearSelectedElement());
    dispatch(new ClearRemovedElement());
  }

  findIndexToElement(data: any, arrayToFind: any[]): number {
    return arrayToFind.findIndex(x => {
      if (x?.elementId) {
        return x.elementId === data.elementId;
      }
      if (x?.itemId) {
        return x.itemId === data.itemId;
      }
      if (x?.moduleTypeId) {
        return x.moduleTypeId === data.moduleTypeId;
      }
      return JSON.stringify(x) === JSON.stringify(data);
    });
  }
}
