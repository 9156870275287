import { Injectable } from '@angular/core';
import { RoutesRecognized, Router } from '@angular/router';

import { Store } from '@ngxs/store';
import { SetUserToken } from '@shared/store/logged-user/user.actions';
import { UserState } from '@shared/store/logged-user/user.state';
import { StateResetAll } from 'ngxs-reset-plugin';
import { filter } from 'rxjs/operators';

import { UserInformations } from './../store/logged-user/user.interface';
import { PouchIdbService } from './pouch-idb.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  static instance: AuthenticationService;
  public hasUserLogged: boolean = undefined;

  constructor(private router: Router, private store: Store, private pouchService: PouchIdbService) {
    AuthenticationService.instance = this;
  }

  /**
   * Start in app component
   * Verify if exist user logged
   */
  init() {
    const subscriptionRouters = this.router.events
      .pipe(filter(event => event instanceof RoutesRecognized))
      .subscribe((routesRecognized: RoutesRecognized) => {
        const token = JSON.parse(localStorage.getItem('token'));
        this.hasUserLogged = !!token;
        if (token) {
          this.store.dispatch(new SetUserToken(token));
          this.pouchService.initLocalDatabaseByUserOnState();
        }
        // Case error in navigate, go to home
        this.router.navigateByUrl(routesRecognized.url).catch(() => this.router.navigateByUrl(''));

        subscriptionRouters.unsubscribe();
      });
  }

  setAuthentication(token = null, keepSession = false) {
    this.hasUserLogged = !!token;
    if (token) {
      this.store.dispatch(new SetUserToken(token));
      this.pouchService.initLocalDatabaseByUserOnState();
      if (keepSession) {
        localStorage.setItem('token', JSON.stringify(token));
      }
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('settings');
      this.store.dispatch(new StateResetAll());
    }
  }
  getAuthData(): UserInformations {
    return this.store.selectSnapshot(UserState.getUserInformations);
  }
}
