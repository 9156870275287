/* eslint-disable @typescript-eslint/indent */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Store } from '@ngxs/store';
import { UserState } from '@shared/store/logged-user/user.state';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export interface HttpOptions {
  body?: any;
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  obserapiVersione?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };
  reportProgress?: boolean;
  withCredentials?: boolean;
}

export class HttpService {
  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(protected ENDPOINT: string, protected http: HttpClient, protected storeXs: Store) {
    this.storeXs.select(UserState.getUserToken).subscribe(userToken => {
      if (userToken) {
        this.httpOptions.headers = this.httpOptions.headers.set('Authorization', `Bearer ${userToken}`);
      }
    });
  }

  protected get<R>(path = ''): Observable<R> {
    return this.http
      .get<R>(`${environment.apiUrl}/${environment.apiVersion}/${this.ENDPOINT}${path}`, this.httpOptions)
      .pipe(catchError(this.errorHandler));
  }

  protected getAsync<R>(path = '', httpOptions?: any): Promise<R> {
    const header: {
      headers: HttpHeaders;
    } = httpOptions || this.httpOptions;

    return this.http
      .get<R>(`${environment.apiUrl}/${environment.apiVersion}/${this.ENDPOINT}${path}`, header)
      .pipe(catchError(this.errorHandler))
      .toPromise();
  }

  protected getAsyncV2<R>(path = '', httpOptions?: any): Promise<R> {
    const header: {
      headers: HttpHeaders;
    } = httpOptions || this.httpOptions;

    return this.http
      .get<R>(`${environment.apiUrl}/${environment.apiVersionV2}/${this.ENDPOINT}${path}`, header)
      .pipe(catchError(this.errorHandler))
      .toPromise();
  }

  protected getAsyncV3<R>(path = '', httpOptions?: any): Promise<R> {
    const header: {
      headers: HttpHeaders;
    } = httpOptions || this.httpOptions;

    return this.http
      .get<R>(`${environment.apiUrl}/${environment.apiVersionV3}/${this.ENDPOINT}${path}`, header)
      .pipe(catchError(this.errorHandler))
      .toPromise();
  }

  protected post<T, R>(body: T, path = ''): Observable<R> {
    return this.http
      .post<R>(
        `${environment.apiUrl}/${environment.apiVersion}/${this.ENDPOINT}${path}`,
        JSON.stringify(body),
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  protected postAsync<T, R>(body: T, path?: string, httpOptions?: any): Promise<R> {
    const header: {
      headers: HttpHeaders;
    } = httpOptions || this.httpOptions;

    return this.http
      .post<R>(`${environment.apiUrl}/${environment.apiVersion}/${this.ENDPOINT}${path || ''}`, body, header)
      .pipe(catchError(this.errorHandler))
      .toPromise();
  }

  protected postAsyncV2<T, R>(body: T, path?: string, httpOptions?: any): Promise<R> {
    const header: {
      headers: HttpHeaders;
    } = httpOptions || this.httpOptions;

    return this.http
      .post<R>(
        `${environment.apiUrl}/${environment.apiVersionV2}/${this.ENDPOINT}${path || ''}`,
        body,
        header
      )
      .pipe(catchError(this.errorHandler))
      .toPromise();
  }

  protected postAsyncV3<T, R>(body: T, path?: string, httpOptions?: any): Promise<R> {
    const header: {
      headers: HttpHeaders;
    } = httpOptions || this.httpOptions;

    return this.http
      .post<R>(
        `${environment.apiUrl}/${environment.apiVersionV3}/${this.ENDPOINT}${path || ''}`,
        body,
        header
      )
      .pipe(catchError(this.errorHandler))
      .toPromise();
  }

  protected put<T, R>(body: T, path?: string): Observable<R> {
    return this.http
      .put<R>(
        `${environment.apiUrl}/${environment.apiVersion}/${this.ENDPOINT}${path || ''}`,
        JSON.stringify(body),
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  protected putAsync<T, R>(body: T, path?: string, httpOptions?: any): Promise<R> {
    const header: {
      headers: HttpHeaders;
    } = httpOptions || this.httpOptions;
    return this.http
      .put<R>(
        `${environment.apiUrl}/${environment.apiVersion}/${this.ENDPOINT}${path || ''}`,
        JSON.stringify(body),
        header
      )
      .pipe(catchError(this.errorHandler))
      .toPromise();
  }

  protected putAsyncV2<T, R>(body: T, path?: string, httpOptions?: any): Promise<R> {
    const header: {
      headers: HttpHeaders;
    } = httpOptions || this.httpOptions;
    return this.http
      .put<R>(
        `${environment.apiUrl}/${environment.apiVersionV2}/${this.ENDPOINT}${path || ''}`,
        JSON.stringify(body),
        header
      )
      .pipe(catchError(this.errorHandler))
      .toPromise();
  }

  protected putAsyncV3<T, R>(body: T, path?: string, httpOptions?: any): Promise<R> {
    const header: {
      headers: HttpHeaders;
    } = httpOptions || this.httpOptions;
    return this.http
      .put<R>(
        `${environment.apiUrl}/${environment.apiVersionV3}/${this.ENDPOINT}${path || ''}`,
        JSON.stringify(body),
        header
      )
      .pipe(catchError(this.errorHandler))
      .toPromise();
  }

  protected delete<R>(path?: string): Observable<R> {
    return this.http
      .delete<R>(
        `${environment.apiUrl}/${environment.apiVersion}/${this.ENDPOINT}${path || ''}`,
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  protected deleteAsync<R>(path?: string, httpOptions?: any): Promise<R> {
    const header: {
      headers: HttpHeaders;
    } = httpOptions || this.httpOptions;
    return this.http
      .delete<R>(`${environment.apiUrl}/${environment.apiVersion}/${this.ENDPOINT}${path || ''}`, header)
      .pipe(catchError(this.errorHandler))
      .toPromise();
  }

  protected deleteAsyncWithBody<R>(path?: string, body?: any, httpOptions?: any): Promise<R> {
    const header: {
      headers: HttpHeaders;
    } = httpOptions || this.httpOptions;

    const options = {
      ...header,
      body: body
    };

    return this.http
      .request<R>('DELETE', `${environment.apiUrl}/${environment.apiVersion}/${this.ENDPOINT}${path || ''}`, options)
      .pipe(catchError(this.errorHandler))
      .toPromise();
}

  private errorHandler(error: any) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}`, error.error);
    }

    return throwError(error);
  }
  protected request(method: string, path: string, options: HttpOptions): Observable<any> {
    if (!options.headers) {
      options.headers = this.httpOptions.headers;
    }
    return this.http.request(method, path, options).pipe(catchError(this.errorHandler));
  }
}
