import { Action, Selector, State, StateContext } from '@ngxs/store';
import { List } from '@shared/models/interfaces/List.interface';
import { AddSurveyors, SetSurveyors } from './surveyors.actions';
import { Surveyor } from './surveyors.interface';
import { Injectable } from '@angular/core';


/**
 * State of Surveyor
 * Add new props in defaults object
 */
@Injectable()
@State<List<Surveyor>>({
  name: 'surveyors',
  defaults: {
    page: 0,
    lastPage: 0,
    perPage: 0,
    total: 0,
    data: []
  }
})
export class SurveyorsState {
  /****************************
   * Selectors
   ****************************/
  @Selector()
  static getSurveyors(surveyors: List<Surveyor>) {
    return surveyors;
  }

  /****************************
   * Actions
   ****************************/

  @Action(SetSurveyors)
  setSurveyors({ patchState }: StateContext<List<Surveyor>>, { surveyors }: SetSurveyors) {
    if (surveyors) {
      patchState(surveyors);
    }
  }

  @Action(AddSurveyors)
  addSurveyors({ patchState, getState }: StateContext<List<Surveyor>>, { surveyors }: AddSurveyors) {
    const currentState = getState();
    surveyors.forEach((surveyor: Surveyor) => {
      currentState.data.push(surveyor);
    });
    patchState(currentState);
  }
}
