import { Selector, State, Action, StateContext } from '@ngxs/store';
import { List } from '@shared/models/interfaces/List.interface';

import { SetAdmins, AddAdmins } from './admins.actions';
import { Admin } from './admins.interface';
import { Injectable } from '@angular/core';

/**
 * State of Item
 * Add new props in defaults object
 */
@Injectable()
@State<List<Admin>>({
  name: 'admins',
  defaults: {
    page: 0,
    lastPage: 0,
    perPage: 0,
    total: 0,
    data: []
  }
})
export class AdminsState {
  /****************************
   * Selectors
   ****************************/
  @Selector()
  static getAdmins(admins: List<Admin>) {
    return admins;
  }

  /****************************
   * Actions
   ****************************/

  @Action(SetAdmins)
  setAdmins({ patchState }: StateContext<List<Admin>>, { admins }: SetAdmins) {
    patchState(admins);
  }

  @Action(AddAdmins)
  addAdmins({ patchState, getState }: StateContext<List<Admin>>, { admins }: AddAdmins) {
    const currentState = getState();
    admins.forEach((admin: Admin) => {
      currentState.data.push(admin);
    });
    patchState(currentState);
  }
}
