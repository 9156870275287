import { Environment } from '@shared/models/interfaces/environment.interface';

export const environment: Environment = {
  production: true,
  hmr: false,
  perPage: 10,
  mapsUrl: 'https://google.com/maps/place',
  wazeUrl: 'https://www.waze.com',
  apiUrl: 'https://api.groupcheck.com.br',
  apiVersion: 'v1',
  apiVersionV2: 'v2',
  apiVersionV3: 'v3',
  endpoints: {
    login: 'authenticate',
    reauthenticate: 'reauthenticate',
    items: 'items',
    elements: 'elements',
    templates: 'templates',
    procedures: 'procedures',
    objects: 'objects',
    segments: 'segments',
    plans: 'plans',
    adminsMaster: 'admins_master',
    register: 'register',
    admin: 'user/admins',
    file: 'file',
    surveyors: 'surveyors',
    surveys: 'surveys',
    surveyElementSettings: 'survey_element_settings',
    evaluationElements: 'evaluation_survey_elements',
    priority: 'maintenance_priority_types',
    evaluationItems: 'evaluation_details_items',
    evaluationKeys: 'evaluation_details_keys_controls',
    evaluationResponsible: 'evaluation_details_responsible',
    evaluationAddItems: 'evaluation_survey_elements_items',
    evaluationConsumptionMeter: 'evaluation_consumption_meter',
    evaluationSignature: 'evaluation_signature',
    resetPassword: 'user_password_resets',
    surveyReport: 'survey_report',
    interveningSignature: 'intervening_signature',
    surveyStatusHistories: 'survey_status_histories',
    process: 'user_procedures',
    evaluationDetailsIntervener: 'evaluation_details_intervener',
    evaluationWitness: 'evaluation_witness',
    witnessSignature: 'witness_signature',
    newUser: 'lead',
    user: 'user',
    documentTypes: 'document_types',
    contestation: 'survey_contestation',
    reportBug: 'report_bug',
    surveySettings: 'survey_settings',
    deleteAccount: 'user/delete-account',
    trial: 'trial',
    checkFile: 'check_file',
    countPhotos: 'count-photos',
  },
  email: 'suportevistoria21@groupsoftware.com.br',
  phone: '(031) 2103-5700',
  title: 'Group Check',
  faviconUrl: '../assets/icon/favicon.ico',
  logo: '../assets/svg/group-logo.svg',
  recoveryLogo: '../assets/png/logo-group-check-white.png',
  responsibleId: '1',
  oneSignalAppId: '4de60fb5-722a-4735-b593-a37fcc274e5b',
  firebaseSenderId: '414598019574',
  oneSignalSafariWebId: 'web.onesignal.auto.1f4dfa09-73a7-4180-b8dc-d98896f4fb85',
  termsOfUseLink:
    'https://group-vistoria-bucket.s3-sa-east-1.amazonaws.com/Termo_de_Uso_-_Group_Vistoria.pdf',
  usagePolice: 'https://group-vistoria-bucket.s3-sa-east-1.amazonaws.com/Termo_de_Uso_-_Group_Vistoria.pdf',
  newUserPlanId: 2,
  codepushKeyAndroid: 'kVx4vfw1SRg75koZe_orQ1hk25UlM7sHPIuJt',
  codepushKeyiOS: 'pS9coJJl6wOmvqSsP0eY7aq8sikwXuQ8KTbWw',
  surveyPhotoItemLimit: 100,
  surveyVideoItemLimit: 1,
  timeoutPerVideoInSeconds: 15,
  googleTagManagerId: 'GTM-M244MTH',
  eadTraining: 'https://ead.groupeduca.com.br/adicionar/ead-group-vistoria-trial',
  eadMakeSurvey: 'https://www.youtube.com/watch?v=1KHwB-YwQwE',
  applicationUrl: 'https://groupcheck.com.br',
  tracksale: {
    key: '3a4ac5e3883f624e',
    campaign: '1a1e7e2b',
    initialDate: 'Nov 01 2024 00:00:00 GMT-0300',
    finalDate: 'Nov 30 2024 23:59:59 GMT-0300',
  },
  apiUrlAWS: '/prod',
  endpointsAWS: {
    zipImages: 'givistoriaprocessaimgprd',
  },
  customCurrencyMaskConfig: {
    align: 'left',
    allowNegative: false,
    decimal: ',',
    precision: 2,
    prefix: 'R$ ',
    suffix: '',
    thousands: '.',
  },
  editorConfig: {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '100px',
    maxHeight: 'auto',
    width: '100%',
    minWidth: '0',
    translate: 'no',
    enableToolbar: true,
    placeholder: 'Digite seu texto aqui...',
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'insertUnorderedList',
        'heading',
        'fontName',
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
      ],
    ],
  },
};
