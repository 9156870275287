import { Selector, State, Action, StateContext } from '@ngxs/store';
import { List } from '@shared/models/interfaces/List.interface';
import { Process } from '@shared/models/interfaces/process.interface';

import { SetProcess, AddProcess } from './process.actions';
import { Injectable } from '@angular/core';

/**
 * State of Item
 * Add new props in defaults object
 */
@Injectable()
@State<List<Process>>({
  name: 'process',
  defaults: {
    page: 0,
    lastPage: 0,
    perPage: 0,
    total: 0,
    data: []
  }
})
export class ProcessState {
  /****************************
   * Selectors
   ****************************/
  @Selector()
  static getProcess(process: List<Process>) {
    return process;
  }

  /****************************
   * Actions
   ****************************/

  @Action(SetProcess)
  setProcess({ patchState }: StateContext<List<Process>>, { process }: SetProcess) {
    patchState(process);
  }

  @Action(AddProcess)
  addProcess({ patchState, getState }: StateContext<List<Process>>, { process }: AddProcess) {
    const currentState = getState();
    process.forEach((pro: Process) => {
      currentState.data.push(pro);
    });
    patchState(currentState);
  }
}
