import { Injectable } from '@angular/core';

import { Network } from '@ionic-native/network/ngx';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { ConnectionStatus } from '@shared/models/constants/ConnectionStatus.enum';
import { ConnectionService } from 'ng-connection-service';
import { BehaviorSubject, Observable } from 'rxjs';

import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  private status: BehaviorSubject<ConnectionStatus> = new BehaviorSubject(ConnectionStatus.Offline);

  constructor(
    private network: Network,
    private plt: Platform,
    private connectionService: ConnectionService,
    private utils: UtilsService,
    private translate: TranslateService
  ) {
    this.plt.ready().then(() => {
      const status = this.network.type !== 'none' ? ConnectionStatus.Online : ConnectionStatus.Offline;
      this.status.next(status);
      this.initializeNetworkEvents();
    });
  }

  public initializeNetworkEvents() {
    if (this.plt.is('cordova')) {
      this.network.onDisconnect().subscribe(() => {
        if (this.status.getValue() !== ConnectionStatus.Offline) {
          this.updateNetworkStatus(ConnectionStatus.Offline);
          this.utils.showToast('', `${this.translate.instant('PROVIDERS.YOU_ARE_OFFLINE')}`);
        }
      });
      this.network.onConnect().subscribe(() => {
        if (this.status.getValue() !== ConnectionStatus.Online) {
          this.updateNetworkStatus(ConnectionStatus.Online);
        }
      });
    } else {
      this.connectionService.monitor().subscribe(status => {
        if (status) {
          this.updateNetworkStatus(ConnectionStatus.Online);
        } else {
          this.updateNetworkStatus(ConnectionStatus.Offline);
          this.utils.showToast('', `${this.translate.instant('PROVIDERS.YOU_ARE_OFFLINE')}`);
        }
      });
    }
  }

  private async updateNetworkStatus(status: ConnectionStatus) {
    this.status.next(status);
  }

  public onNetworkChange(): Observable<ConnectionStatus> {
    return this.status.asObservable();
  }

  public getCurrentNetworkStatus(): ConnectionStatus {
    return this.status.getValue();
  }
}
