import { Element } from '@shared/models/interfaces/element.interface';
import { Item } from '@shared/models/interfaces/item.interface';
import { List } from '@shared/models/interfaces/List.interface';
export class SetItems {
  static readonly type = '[ELEMENTS] Set Items';
  constructor(public items: List<Item>) {}
}

export class SetKeysAndControls {
  static readonly type = '[ELEMENTS] Set Keys and Controls';
  constructor(public keysAndControls: List<Element>) {}
}

export class SetConsumeMeasurers {
  static readonly type = '[ELEMENTS] Set Consume Measurers';
  constructor(public consumeMeasurers: List<Element>) {}
}

export class AddConsumeMeasurers {
  static readonly type = '[ELEMENTS] Add Consume Measurers';
  constructor(public consumeMeasurers: Element) {}
}

export class AddKeyAndControl {
  static readonly type = '[ELEMENTS] Add Key and Control';
  constructor(public keyAndControl: Element) {}
}

export class SetElementsState {
  static readonly type = '[ELEMENTS] Set All elements';
  constructor(public elements: any) {}
}
