/* eslint-disable @typescript-eslint/indent */
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Store } from '@ngxs/store';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpOptions, HttpService } from './http.service';

export class HttpServiceV2 extends HttpService {
  constructor(protected ENDPOINT: string, protected http: HttpClient, protected storeXs: Store) {
    super(ENDPOINT, http, storeXs);
  }

  protected getV2<R>(path = ''): Observable<R> {
    return this.http
      .get<R>(`${environment.apiUrl}/${environment.apiVersionV2}/${this.ENDPOINT}${path}`, this.httpOptions)
      .pipe(catchError(this.errorHandlerV2));
  }

  protected getAsyncV2<R>(path = '', httpOptions?: any): Promise<R> {
    const header: {
      headers: HttpHeaders;
    } = httpOptions || this.httpOptions;

    return this.http
      .get<R>(`${environment.apiUrl}/${environment.apiVersionV2}/${this.ENDPOINT}${path}`, header)
      .pipe(catchError(this.errorHandlerV2))
      .toPromise();
  }

  protected postV2<T, R>(body: T, path = ''): Observable<R> {
    return this.http
      .post<R>(
        `${environment.apiUrl}/${environment.apiVersionV2}/${this.ENDPOINT}${path}`,
        JSON.stringify(body),
        this.httpOptions
      )
      .pipe(catchError(this.errorHandlerV2));
  }

  protected postAsyncV2<T, R>(body: T, path?: string, httpOptions?: any): Promise<R> {
    const header: {
      headers: HttpHeaders;
    } = httpOptions || this.httpOptions;

    return this.http
      .post<R>(
        `${environment.apiUrl}/${environment.apiVersionV2}/${this.ENDPOINT}${path || ''}`,
        body,
        header
      )
      .pipe(catchError(this.errorHandlerV2))
      .toPromise();
  }

  protected putV2<T, R>(body: T, path?: string): Observable<R> {
    return this.http
      .put<R>(
        `${environment.apiUrl}/${environment.apiVersionV2}/${this.ENDPOINT}${path || ''}`,
        JSON.stringify(body),
        this.httpOptions
      )
      .pipe(catchError(this.errorHandlerV2));
  }

  protected putAsyncV2<T, R>(body: T, path?: string, httpOptions?: any): Promise<R> {
    const header: {
      headers: HttpHeaders;
    } = httpOptions || this.httpOptions;
    return this.http
      .put<R>(
        `${environment.apiUrl}/${environment.apiVersionV2}/${this.ENDPOINT}${path || ''}`,
        JSON.stringify(body),
        header
      )
      .pipe(catchError(this.errorHandlerV2))
      .toPromise();
  }

  protected deleteV2<R>(path?: string): Observable<R> {
    return this.http
      .delete<R>(
        `${environment.apiUrl}/${environment.apiVersionV2}/${this.ENDPOINT}${path || ''}`,
        this.httpOptions
      )
      .pipe(catchError(this.errorHandlerV2));
  }

  protected deleteAsyncV2<R>(path?: string, httpOptions?: any): Promise<R> {
    const header: {
      headers: HttpHeaders;
    } = httpOptions || this.httpOptions;
    return this.http
      .delete<R>(`${environment.apiUrl}/${environment.apiVersionV2}/${this.ENDPOINT}${path || ''}`, header)
      .pipe(catchError(this.errorHandlerV2))
      .toPromise();
  }

  private errorHandlerV2(error: any) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}`, error.error);
    }

    return throwError(error);
  }
  protected requestV2(method: string, path: string, options: HttpOptions): Observable<any> {
    if (!options.headers) {
      options.headers = this.httpOptions.headers;
    }
    return this.http.request(method, path, options).pipe(catchError(this.errorHandlerV2));
  }
}
