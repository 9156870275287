import { List } from '@shared/models/interfaces/List.interface';

import { Surveyor } from './surveyors.interface';

export class SetSurveyors {
  static readonly type = '[Surveyors] Set Surveyors';
  constructor(public surveyors: List<Surveyor>) {}
}

export class AddSurveyors {
  static readonly type = '[Surveyors] Add Surveyors to the current state';
  constructor(public surveyors: Surveyor[]) {}
}
