import { TranslateService } from '@ngx-translate/core';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { MODULETYPES, MODULETYPESIDS } from '@shared/models/constants/moduleTypeId';
import { orderBy } from 'lodash';

import {
  AddAmbienceToSurvey,
  AddConsumeMeasurerToSurvey,
  AddItemsToAmbience,
  AddKeysAndControlsToSurvey,
  AddResponsibleToSurvey,
  RemoveAmbienceFromSurvey,
  RemoveConsumptionMeaurer,
  RemoveItemFromAmbience,
  RemoveKeysAndControlsItem,
  ResetModules,
  SetAmbiencesToSurvey,
  SetConsumeMeasurersToSurvey,
  SetItemsToAmbience,
  SetKeysAndControlsToSurvey,
  SetResponsiblesToSurvey,
  SetSurveyId,
  SetSurveyHash,
  SetSurveyUnderInspection,
  UpdateAmbience,
  UpdateAmbienceItem,
  UpdateConsumptionMeasureItem,
  UpdateKeysAndControlsItem,
  UpdateResponsibleItem,
  UpdateSignature,
  ValidateAndCompleteSurvey,
  SetSurveyState,
  SetIsSigned,
} from './survey.actions';
import {
  IKeysAndControlsItem,
  IResponsibleItem,
  ISurvey,
  ISurveyAmbiences,
  ISurveyConsumptionMeasures,
  ISurveyKeysAndControls,
  ISurveyModule,
  ISurveyResponsibles,
} from './survey.interface';

import { Injectable } from '@angular/core';

const defaults: ISurvey = {
  surveyId: null,
  surveyData: null,
  modulesList: {},
  isComplete: false,
  isSigned: false,
  surveyHash: null,
  surveyModules: {
    moduleAmbience: {
      selected: false,
      moduleType: MODULETYPES[0],
      elementName: MODULETYPES[0].moduleTypeDecription,
      totalItems: 0,
      completedItems: 0,
      isComplete: false,
      link: 'ambientes',
      allAmbiences: [],
    },
    moduleConsumptionMeasures: {
      selected: false,
      moduleType: MODULETYPES[1],
      elementName: MODULETYPES[1].moduleTypeDecription,
      totalItems: 0,
      completedItems: 0,
      isComplete: false,
      link: 'medidores',
      consumptionMeasureItems: [],
    },
    moduleKeysAndControls: {
      selected: false,
      moduleType: MODULETYPES[2],
      elementName: MODULETYPES[2].moduleTypeDecription,
      totalItems: 0,
      completedItems: 0,
      isComplete: false,
      link: 'chaves',
      keysAndControlsItems: [],
    },
    moduleResponsibles: {
      selected: true,
      moduleType: MODULETYPES[3],
      elementName: MODULETYPES[3].moduleTypeDecription,
      totalItems: 0,
      completedItems: 0,
      isComplete: false,
      link: 'responsaveis',
      responsibleItems: [],
    },
    moduleSignatures: {
      selected: true,
      moduleType: MODULETYPES[4],
      elementName: MODULETYPES[4].moduleTypeDecription,
      totalItems: 2,
      completedItems: 0,
      isComplete: false,
      link: 'assinaturas',
      surveyorSignature: '',
      responsibleSignature: '',
    },
    moduleImmobile: {
      selected: true,
      moduleType: MODULETYPES[5],
      elementName: MODULETYPES[5].moduleTypeDecription,
      totalItems: 0,
      completedItems: 0,
      isComplete: false,
      link: 'imovel',
      description: '',
      observations: '',
    },
    moduleReport: {
      selected: true,
      moduleType: MODULETYPES[8],
      elementName: MODULETYPES[8].moduleTypeDecription,
      totalItems: 0,
      completedItems: 0,
      isComplete: false,
      link: 'relatorio',
      reports: [],
    },
  },
};

@Injectable()
@State<ISurvey>({
  name: 'surveyState',
  defaults,
})
export class SurveyState {
  /****************************
   * Selectors
   ****************************/

  @Selector()
  static getIsSigned({ isSigned }: ISurvey): boolean {
    return isSigned;
  }

  @Selector()
  static getSurvey(survey: ISurvey) {
    return survey;
  }
  @Selector()
  static getSurveyId({ surveyId }: ISurvey) {
    return surveyId;
  }

  @Selector()
  static getSurveyData({ surveyData }: ISurvey) {
    return surveyData;
  }

  @Selector()
  static getSurveyHash({ surveyHash }: ISurvey) {
    return surveyHash;
  }

  @Selector()
  static getArrayModules({ surveyModules }: ISurvey) {
    const array = [];
    if (surveyModules.moduleAmbience) {
      array.push(surveyModules.moduleAmbience);
    }
    if (surveyModules.moduleConsumptionMeasures) {
      array.push(surveyModules.moduleConsumptionMeasures);
    }
    if (surveyModules.moduleKeysAndControls) {
      array.push(surveyModules.moduleKeysAndControls);
    }
    if (surveyModules.moduleResponsibles) {
      array.push(surveyModules.moduleResponsibles);
    }
    if (surveyModules.moduleSignatures) {
      array.push(surveyModules.moduleSignatures);
    }
    if (surveyModules.moduleImmobile) {
      array.push(surveyModules.moduleImmobile);
    }
    if (surveyModules.moduleReport) {
      array.push(surveyModules.moduleReport);
    }
    return array;
  }

  static getArrayModulesAndTranslate(translate: TranslateService) {
    return createSelector([this.getArrayModules], array => {
      return array.map(module => {
        module.elementName = translate.instant(module.elementName);
        return module;
      });
    });
  }

  @Selector()
  static getArraySelectedModules(survey: ISurvey) {
    return this.getArrayModules(survey).filter(module => module.selected);
  }

  static getArraySelectedModulesAndTranslate(translate: TranslateService) {
    return createSelector([this.getArraySelectedModules], array => {
      return array.map(module => {
        module.elementName = translate.instant(module.elementName);
        return module;
      });
    });
  }

  @Selector()
  static getArrayUnselectedModules(survey: ISurvey) {
    return this.getArrayModules(survey).filter(module => !module.selected);
  }
  @Selector()
  static getAllModules(survey: ISurvey) {
    return survey.surveyModules;
  }
  @Selector()
  static getModuleAmbience(survey: ISurvey) {
    return survey.surveyModules.moduleAmbience;
  }
  @Selector()
  static getModuleResponsible(survey: ISurvey) {
    return survey.surveyModules.moduleResponsibles;
  }
  @Selector()
  static getModuleKeysAndControls(survey: ISurvey) {
    return survey.surveyModules.moduleKeysAndControls;
  }
  @Selector()
  static getModuleConsumptionMeasures(survey: ISurvey) {
    return survey.surveyModules.moduleConsumptionMeasures;
  }
  @Selector()
  static getAllAmbiences(survey: ISurvey) {
    return orderBy(survey.surveyModules.moduleAmbience.allAmbiences, 'elementPosition');
  }

  @Selector()
  static getAllResponsibles(survey: ISurvey) {
    return survey.surveyModules.moduleResponsibles.responsibleItems;
  }

  @Selector()
  static getAllConsumptionMeasurers(survey: ISurvey) {
    return survey.surveyModules.moduleConsumptionMeasures.consumptionMeasureItems;
  }

  @Selector()
  static getAllKeysAndControls(survey: ISurvey) {
    return survey.surveyModules.moduleKeysAndControls.keysAndControlsItems;
  }
  @Selector()
  static getItemsFromAmbience(survey: ISurvey) {
    return (ambienceEvalId: string) => {
      const ambience = survey.surveyModules.moduleAmbience.allAmbiences.find(
        a => a.evaluationSurveyElementItemId === ambienceEvalId
      );
      return ambience ? orderBy(ambience.items, 'itemtPosition') : null;
    };
  }

  @Selector()
  static getItem(survey: ISurvey) {
    return (ambienceEvalId: string, itemId: string) => {
      const ambience = survey.surveyModules.moduleAmbience.allAmbiences.find(
        a => a.evaluationSurveyElementItemId === ambienceEvalId
      );
      if (ambience) {
        return ambience.items.find(item => item.evaluationSurveyElementItemId === itemId);
      }
    };
  }

  @Selector()
  static getEvaluationSurveyElementItemIdConsumptionMeasure(survey: ISurvey) {
    return (ambienceEvalId: string, itemId: string) => {
      const ambience = survey.surveyModules.moduleConsumptionMeasures.consumptionMeasureItems.find(
        a => a.evaluationSurveyElementItemId === ambienceEvalId
      );
      if (ambience) {
        return ambience.evaluationSurveyElementItemId;
      }
    };
  }

  @Selector()
  static getEvaluationSurveyElementItemIdKeysAndControls(survey: ISurvey) {
    return (ambienceEvalId: string, itemId: string) => {
      const ambience = survey.surveyModules.moduleKeysAndControls.keysAndControlsItems.find(
        a => a.evaluationSurveyElementItemId.toString() === ambienceEvalId
      );
      if (ambience) {
        return ambience.evaluationSurveyElementItemId;
      }
    };
  }

  /****************************
   * Actions
   ****************************/

  @Action(SetSurveyState)
  setSurveyState({ patchState }: StateContext<ISurvey>, { surveyState }: SetSurveyState) {
    patchState(surveyState);
  }

  @Action(ResetModules)
  resetModules({ patchState, getState }: StateContext<ISurvey>, { modules }: ResetModules) {
    const surveyModules = getState().surveyModules;
    const indexAmbience = modules.findIndex(
      (module: ISurveyModule) => module.moduleType.moduleTypeId === MODULETYPESIDS.Ambiences
    );
    const indexKeys = modules.findIndex(
      (module: ISurveyModule) => module.moduleType.moduleTypeId === MODULETYPESIDS.KeysAndControls
    );
    const indexMeasurers = modules.findIndex(
      (module: ISurveyModule) => module.moduleType.moduleTypeId === MODULETYPESIDS.ConsumeMeasurers
    );
    if (indexAmbience !== -1) {
      surveyModules.moduleAmbience.isComplete = true;
      surveyModules.moduleAmbience.selected = false;
      surveyModules.moduleAmbience.allAmbiences = [];
    }
    if (indexKeys !== -1) {
      surveyModules.moduleKeysAndControls.isComplete = true;
      surveyModules.moduleKeysAndControls.selected = false;
      surveyModules.moduleKeysAndControls.keysAndControlsItems = [];
    }

    if (indexMeasurers !== -1) {
      surveyModules.moduleConsumptionMeasures.isComplete = true;
      surveyModules.moduleConsumptionMeasures.selected = false;
      surveyModules.moduleConsumptionMeasures.consumptionMeasureItems = [];
    }
    patchState({ surveyModules });
  }

  @Action(SetSurveyId)
  SetSurveyId({ patchState }: StateContext<ISurvey>, { surveyId }: SetSurveyId) {
    patchState({ surveyId });
  }

  @Action(SetSurveyHash)
  SetSurveyHash({ patchState }: StateContext<ISurvey>, { surveyHash }: SetSurveyHash) {
    patchState({ surveyHash });
  }

  @Action(SetIsSigned)
  setIsSigned({ patchState }: StateContext<ISurvey>, { isSigned }: SetIsSigned) {
    patchState({
      isSigned,
    });
  }

  @Action(SetSurveyUnderInspection)
  setSurveyUnderInspection({ patchState }: StateContext<any>, { surveyData }: SetSurveyUnderInspection) {
    patchState({ surveyData });
  }

  @Action(AddAmbienceToSurvey)
  addAmbienceToSurvey({ patchState, getState }: StateContext<ISurvey>, { ambience }: AddAmbienceToSurvey) {
    const surveyModules = getState().surveyModules;
    const allAmbiences: any[] = surveyModules.moduleAmbience.allAmbiences;
    const { name, index } = this.getNameWithIndex(ambience, allAmbiences);
    ambience.cardName = name;
    ambience.cardIndex = index;
    ambience.items = this.addCardNameAndCardIndexToArray(ambience.items);

    allAmbiences.push(ambience);

    surveyModules.moduleAmbience.allAmbiences = orderBy(allAmbiences, 'elementPosition');

    surveyModules.moduleAmbience.completedItems = 0;
    ambience.items?.forEach(item => {
      if (item.evaluationDetail) {
        surveyModules.moduleAmbience.completedItems += 1;
      }
    });

    patchState({ surveyModules });
  }

  @Action(AddItemsToAmbience)
  addItemsToAmbience(
    { patchState, getState }: StateContext<any>,
    { items, ambienceEvalId }: AddItemsToAmbience
  ) {
    const surveyModules = getState().surveyModules;
    const moduleAmbience = surveyModules.moduleAmbience;
    const allAmbiences = surveyModules.moduleAmbience.allAmbiences;
    const ambienceIndex = allAmbiences.findIndex(a => a.evaluationSurveyElementItemId === ambienceEvalId);
    items.forEach(item => {
      const { name, index } = this.getNameWithIndex(item, allAmbiences[ambienceIndex].items);
      item.cardName = name;
      item.cardIndex = index;
      allAmbiences[ambienceIndex].items.push(item);
      allAmbiences[ambienceIndex].items = orderBy(allAmbiences[ambienceIndex].items, 'itemPosition');
    });
    surveyModules.moduleAmbience.allAmbiences = allAmbiences;
    patchState({
      surveyModules: {
        ...surveyModules,
        moduleAmbience: { ...this.updateAllIndexersForAmbience(moduleAmbience), allAmbiences },
      },
    });
  }

  @Action(SetAmbiencesToSurvey)
  setAmbiencesToSurvey(
    { patchState, getState }: StateContext<ISurvey>,
    { allAmbiences }: SetAmbiencesToSurvey
  ) {
    const surveyModules = getState().surveyModules;
    const moduleAmbience = getState().surveyModules.moduleAmbience;
    allAmbiences.forEach(ambience => {
      ambience.items = this.addCardNameAndCardIndexToArray(ambience.items);
      ambience.items = orderBy(ambience.items, 'itemPosition');
    });

    allAmbiences.sort((a, b) => a.elementPosition - b.elementPosition);
    patchState({
      surveyModules: {
        ...surveyModules,
        moduleAmbience: {
          ...moduleAmbience,
          selected: true,
          totalItems: allAmbiences.length,
          allAmbiences: this.addCardNameAndCardIndexToArray(allAmbiences),
        },
      },
    });
  }

  @Action(SetResponsiblesToSurvey)
  setResponsiblesToSurvey(
    { patchState, getState }: StateContext<ISurvey>,
    { responsibleItems }: SetResponsiblesToSurvey
  ) {
    const surveyModules = getState().surveyModules;
    surveyModules.moduleResponsibles.responsibleItems = responsibleItems;
    surveyModules.moduleResponsibles.isComplete =
      responsibleItems.length > 0 && responsibleItems[0].evaluationDetail !== null;
    patchState({ surveyModules: { ...surveyModules } });
  }
  @Action(AddResponsibleToSurvey)
  addResponsiblesToSurvey(
    { patchState, getState }: StateContext<ISurvey>,
    { responsible }: AddResponsibleToSurvey
  ) {
    const surveyModules = getState().surveyModules;
    surveyModules.moduleResponsibles.responsibleItems.push(responsible);
    surveyModules.moduleResponsibles.totalItems += 1;
    surveyModules.moduleResponsibles.isComplete = false;

    patchState({ surveyModules });
  }

  @Action(AddConsumeMeasurerToSurvey)
  addConsumeMeasurerToSurvey(
    { patchState, getState }: StateContext<ISurvey>,
    { measurer }: AddConsumeMeasurerToSurvey
  ) {
    const surveyModules = getState().surveyModules;
    const consumptionMeasureItems = surveyModules.moduleConsumptionMeasures.consumptionMeasureItems;
    const { name, index } = this.getNameWithIndex(measurer, consumptionMeasureItems);
    measurer.cardName = name;
    measurer.cardIndex = index;
    consumptionMeasureItems.push(measurer);
    surveyModules.moduleConsumptionMeasures.consumptionMeasureItems = orderBy(
      consumptionMeasureItems,
      'elementPosition'
    );
    surveyModules.moduleConsumptionMeasures.totalItems += 1;
    surveyModules.moduleConsumptionMeasures.isComplete = false;

    patchState({ surveyModules });
  }

  @Action(AddKeysAndControlsToSurvey)
  addKeysAndControlsToSurvey(
    { patchState, getState }: StateContext<ISurvey>,
    { keysAndControls }: AddKeysAndControlsToSurvey
  ) {
    const surveyModules = getState().surveyModules;
    const keysAndControlsItems = surveyModules.moduleKeysAndControls.keysAndControlsItems;
    const { name, index } = this.getNameWithIndex(keysAndControls, keysAndControlsItems);
    keysAndControls.cardName = name;
    keysAndControls.cardIndex = index;
    keysAndControlsItems.push(keysAndControls);
    surveyModules.moduleKeysAndControls.keysAndControlsItems = orderBy(
      keysAndControlsItems,
      'elementPosition'
    );
    surveyModules.moduleKeysAndControls.totalItems += 1;
    surveyModules.moduleKeysAndControls.isComplete = false;

    patchState({ surveyModules });
  }

  @Action(SetConsumeMeasurersToSurvey)
  setConsumeMeasurersToSurvey(
    { patchState, getState }: StateContext<ISurvey>,
    { consumptionMeasureItems }: SetConsumeMeasurersToSurvey
  ) {
    const surveyModules = getState().surveyModules;
    const moduleConsumptionMeasures = surveyModules.moduleConsumptionMeasures;
    moduleConsumptionMeasures.selected = true;
    moduleConsumptionMeasures.consumptionMeasureItems = orderBy(
      this.addCardNameAndCardIndexToArray(consumptionMeasureItems),
      'elementPosition'
    );
    moduleConsumptionMeasures.moduleType = MODULETYPES[1];
    patchState({
      surveyModules: {
        ...surveyModules,
        moduleConsumptionMeasures: {
          ...this.updateAllIndexersForConsumptionMeasures(moduleConsumptionMeasures),
        },
      },
    });
  }

  @Action(SetKeysAndControlsToSurvey)
  setKeysAndControlsToSurvey(
    { patchState, getState }: StateContext<ISurvey>,
    { keysAndControlsItems }: SetKeysAndControlsToSurvey
  ) {
    const surveyModules = getState().surveyModules;
    const moduleKeysAndControls = surveyModules.moduleKeysAndControls;
    moduleKeysAndControls.selected = true;
    moduleKeysAndControls.keysAndControlsItems = this.addCardNameAndCardIndexToArray(keysAndControlsItems);
    moduleKeysAndControls.moduleType = MODULETYPES[2];
    patchState({
      surveyModules: {
        ...surveyModules,
        moduleKeysAndControls: {
          ...this.updateAllIndexersForKeysAndControl(moduleKeysAndControls),
        },
      },
    });
  }

  @Action(SetItemsToAmbience)
  setItemsToAmbience({ patchState, getState }: StateContext<ISurvey>, { ambience }: SetItemsToAmbience) {
    if (ambience) {
      const surveyModules = getState().surveyModules;
      const moduleAmbience = getState().surveyModules.moduleAmbience;
      const allAmbiences = moduleAmbience.allAmbiences;

      allAmbiences.forEach(element => {
        element.items = orderBy(element.items, 'itemPosition');
      });

      patchState({
        surveyModules: {
          ...surveyModules,
          moduleAmbience: {
            ...moduleAmbience,
            allAmbiences,
          },
        },
      });
    }
  }
  @Action(UpdateAmbience)
  updateAmbience({ patchState, getState }: StateContext<ISurvey>, { ambience }: UpdateAmbience) {
    const surveyModules = getState().surveyModules;
    const moduleAmbience = surveyModules.moduleAmbience;
    const allAmbiences = moduleAmbience.allAmbiences;
    const ambienceToUpdate = allAmbiences.findIndex(
      currentAmbience =>
        currentAmbience.evaluationSurveyElementItemId === ambience.evaluationSurveyElementItemId
    );

    allAmbiences[ambienceToUpdate] = ambience;
    patchState({
      surveyModules: {
        ...surveyModules,
        moduleAmbience: { ...moduleAmbience, allAmbiences: orderBy(allAmbiences, 'elementPosition') },
      },
    });
  }

  @Action(RemoveAmbienceFromSurvey)
  removeAmbienceFromSurvey(
    { patchState, getState }: StateContext<ISurvey>,
    { ambienceEvalId }: RemoveAmbienceFromSurvey
  ) {
    const surveyModules = getState().surveyModules;
    const moduleAmbience = surveyModules.moduleAmbience;
    const allAmbiences = moduleAmbience.allAmbiences;
    const index = allAmbiences.findIndex(a => a.evaluationSurveyElementItemId === ambienceEvalId);
    if (allAmbiences[index].isComplete) {
      moduleAmbience.completedItems--;
    }
    allAmbiences.splice(index, 1);
    moduleAmbience.allAmbiences = allAmbiences;
    patchState({
      surveyModules: {
        ...surveyModules,
        moduleAmbience: { ...this.updateAllIndexersForAmbience(moduleAmbience), allAmbiences },
      },
    });
  }

  @Action(RemoveConsumptionMeaurer)
  removeCompsumptionMeasurer(
    { patchState, getState }: StateContext<ISurvey>,
    { itemId }: RemoveConsumptionMeaurer
  ) {
    const surveyModules = getState().surveyModules;
    const moduleConsumptionMeasures = surveyModules.moduleConsumptionMeasures;
    const measurerIndex = moduleConsumptionMeasures.consumptionMeasureItems.findIndex(
      m => m.evaluationSurveyElementItemId === itemId
    );
    moduleConsumptionMeasures.consumptionMeasureItems.splice(measurerIndex, 1);
    moduleConsumptionMeasures.totalItems -= 1;

    patchState({
      surveyModules: {
        ...surveyModules,
        moduleConsumptionMeasures: {
          ...this.updateAllIndexersForConsumptionMeasures(moduleConsumptionMeasures),
        },
      },
    });
  }

  @Action(RemoveKeysAndControlsItem)
  removeKeysAndControlsItem(
    { patchState, getState }: StateContext<ISurvey>,
    { itemId }: RemoveKeysAndControlsItem
  ) {
    const surveyModules = getState().surveyModules;
    const moduleKeysAndControls: ISurveyKeysAndControls = surveyModules.moduleKeysAndControls;
    const index = moduleKeysAndControls.keysAndControlsItems.findIndex(
      m => m.evaluationSurveyElementItemId === itemId
    );
    moduleKeysAndControls.keysAndControlsItems.splice(index, 1);
    moduleKeysAndControls.totalItems -= 1;

    patchState({
      surveyModules: {
        ...surveyModules,
        moduleKeysAndControls: { ...this.updateAllIndexersForKeysAndControl(moduleKeysAndControls) },
      },
    });
  }
  @Action(RemoveItemFromAmbience)
  removeItemFromAmbience(
    { patchState, getState }: StateContext<ISurvey>,
    { ambience, itemId }: RemoveItemFromAmbience
  ) {
    const surveyModules = getState().surveyModules;
    const moduleAmbience = surveyModules.moduleAmbience;
    const allAmbiences = moduleAmbience.allAmbiences;
    const ambienceIndex = allAmbiences.findIndex(
      a => a.evaluationSurveyElementItemId === ambience.evaluationSurveyElementItemId
    );
    const itemIndex = allAmbiences[ambienceIndex].items.findIndex(
      i => i.evaluationSurveyElementItemId === itemId
    );
    if (itemIndex !== -1 && allAmbiences[ambienceIndex].items[itemIndex].evaluationDetail) {
      allAmbiences[ambienceIndex].completedItems--;
    }
    allAmbiences[ambienceIndex].items.splice(itemIndex, 1);
    moduleAmbience.allAmbiences = allAmbiences;
    patchState({
      surveyModules: {
        ...surveyModules,
        moduleAmbience: { ...this.updateAllIndexersForAmbience(moduleAmbience), allAmbiences },
      },
    });
  }

  @Action(UpdateAmbienceItem)
  updateAmbienceItem(
    { patchState, getState }: StateContext<ISurvey>,
    { ambienceEvalId, ambienceItem }: UpdateAmbienceItem
  ) {
    const surveyModules = getState().surveyModules;
    const moduleAmbience = surveyModules.moduleAmbience;
    const allAmbiences = moduleAmbience.allAmbiences;
    const ambience = allAmbiences.find(a => a.evaluationSurveyElementItemId === ambienceEvalId);
    const index = allAmbiences.findIndex(a => a.evaluationSurveyElementItemId === ambienceEvalId);

    const itemIndex = ambience.items.findIndex(
      item => item.evaluationSurveyElementItemId === ambienceItem.evaluationSurveyElementItemId
    );
    ambience.items[itemIndex].evaluationDetail = ambienceItem;
    if (ambience.completedItems !== ambience.totalItems) {
      ambience.completedItems++;
    }
    ambience.items = orderBy(ambience.items, 'itemPosition');
    allAmbiences[index] = ambience;
    moduleAmbience.allAmbiences = allAmbiences;
    patchState({
      surveyModules: {
        ...surveyModules,
        moduleAmbience: { ...this.updateAllIndexersForAmbience(moduleAmbience), allAmbiences },
      },
    });
  }

  @Action(UpdateResponsibleItem)
  updateResponsibleItem(
    { patchState, getState }: StateContext<ISurvey>,
    { responsibleId, responsibleItem }: UpdateResponsibleItem
  ) {
    const surveyModules = getState().surveyModules;
    const moduleResponsibles = surveyModules.moduleResponsibles;

    if (moduleResponsibles.responsibleItems) {
      const itemIndex = moduleResponsibles.responsibleItems.findIndex(
        (item: IResponsibleItem) => item.evaluationSurveyElementItemId === responsibleId
      );
      moduleResponsibles.responsibleItems[itemIndex].evaluationDetail = [];
      moduleResponsibles.responsibleItems[itemIndex].evaluationDetail.push(responsibleItem);
      moduleResponsibles.isComplete = true;
      moduleResponsibles.completedItems += 1;
    } else {
      moduleResponsibles.isComplete = false;
    }
    surveyModules.moduleResponsibles = moduleResponsibles;
    patchState({
      surveyModules: {
        ...surveyModules,
      },
    });
  }

  @Action(UpdateKeysAndControlsItem)
  updateKeysAndControlsItem(
    { patchState, getState }: StateContext<ISurvey>,
    { keysAndControlsId, keysAndControlsItem }: UpdateKeysAndControlsItem
  ) {
    const surveyModules = getState().surveyModules;
    const moduleKeysAndControls: ISurveyKeysAndControls = getState().surveyModules.moduleKeysAndControls;

    const itemIndex = moduleKeysAndControls.keysAndControlsItems.findIndex(
      (item: IKeysAndControlsItem) => item.evaluationSurveyElementItemId === keysAndControlsId
    );

    moduleKeysAndControls.keysAndControlsItems[itemIndex].evaluationDetail = [];
    moduleKeysAndControls.keysAndControlsItems[itemIndex].evaluationDetail[0] = keysAndControlsItem;
    patchState({
      surveyModules: {
        ...surveyModules,
        moduleKeysAndControls: { ...this.updateAllIndexersForKeysAndControl(moduleKeysAndControls) },
      },
    });
  }

  @Action(UpdateConsumptionMeasureItem)
  UpdateConsumptionMeasureItem(
    { patchState, getState }: StateContext<ISurvey>,
    { consumptionmeasureId, consumptionmeasureItem }: UpdateConsumptionMeasureItem
  ) {
    const surveyModules = getState().surveyModules;
    const moduleConsumptionMeasures = getState().surveyModules.moduleConsumptionMeasures;

    const itemIndex = moduleConsumptionMeasures.consumptionMeasureItems.findIndex(
      item => item.evaluationSurveyElementItemId === consumptionmeasureId
    );

    moduleConsumptionMeasures.consumptionMeasureItems[itemIndex].evaluationDetail = [];
    moduleConsumptionMeasures.consumptionMeasureItems[itemIndex].evaluationDetail[0] = consumptionmeasureItem;
    patchState({
      surveyModules: {
        ...surveyModules,
        moduleConsumptionMeasures: {
          ...this.updateAllIndexersForConsumptionMeasures(moduleConsumptionMeasures),
        },
      },
    });
  }

  @Action(UpdateSignature)
  updateSignature(
    { patchState, getState }: StateContext<ISurvey>,
    { surveyorSignature, responsibleSignature }: UpdateSignature
  ) {
    const surveyModules = getState().surveyModules;
    surveyModules.moduleSignatures.responsibleSignature = responsibleSignature;
    if (surveyModules.moduleSignatures.completedItems === 2) {
      if (responsibleSignature && responsibleSignature !== '') {
        surveyModules.moduleSignatures.completedItems += 1;
      }
      if (surveyorSignature && surveyorSignature !== '') {
        surveyModules.moduleSignatures.completedItems += 1;
      }
    }
    surveyModules.moduleSignatures.surveyorSignature = surveyorSignature;
    surveyModules.moduleSignatures.isComplete = surveyModules.moduleSignatures.totalItems === 2;
    patchState({
      surveyModules,
    });
  }
  @Action(ValidateAndCompleteSurvey)
  validateAndCompleteSurvey({ patchState, getState }: StateContext<ISurvey>) {
    const surveyModules = getState().surveyModules;
    if (surveyModules.moduleAmbience.allAmbiences) {
      surveyModules.moduleAmbience.allAmbiences.forEach(ambience => {
        ambience.totalItems = ambience.items.length;
        ambience.completedItems = 0;
        if (ambience.totalItems) {
          ambience.items.forEach(item => {
            if (item.evaluationDetail) {
              ambience.completedItems += 1;
            }
          });
        }
        ambience.isComplete = ambience.totalItems === ambience.completedItems;
      });
    } else {
      surveyModules.moduleAmbience.isComplete = false;
    }

    surveyModules.moduleAmbience = this.updateAllIndexersForAmbience(surveyModules.moduleAmbience);
    surveyModules.moduleConsumptionMeasures = this.updateAllIndexersForConsumptionMeasures(
      surveyModules.moduleConsumptionMeasures
    );

    surveyModules.moduleKeysAndControls = this.updateAllIndexersForKeysAndControl(
      surveyModules.moduleKeysAndControls
    );

    surveyModules.moduleResponsibles.isComplete =
      surveyModules.moduleResponsibles.responsibleItems.length > 0 &&
      surveyModules.moduleResponsibles.responsibleItems[0].evaluationDetail !== null;
    surveyModules.moduleResponsibles.completedItems = surveyModules.moduleResponsibles.isComplete ? 1 : 0;

    // only surveyor signature is required
    surveyModules.moduleSignatures.isComplete = surveyModules.moduleSignatures.surveyorSignature !== '';
    surveyModules.moduleSignatures.completedItems = 0;
    if (surveyModules.moduleSignatures.surveyorSignature) {
      surveyModules.moduleSignatures.completedItems += 1;
    }
    if (surveyModules.moduleSignatures.responsibleSignature) {
      surveyModules.moduleSignatures.completedItems += 1;
    }

    const survey = getState();
    survey.isComplete =
      surveyModules.moduleSignatures.isComplete &&
      // surveyModules.moduleResponsibles.isComplete &&
      surveyModules.moduleAmbience.isComplete &&
      surveyModules.moduleConsumptionMeasures.isComplete &&
      surveyModules.moduleKeysAndControls.isComplete;
    survey.surveyModules = surveyModules;
    patchState({
      surveyModules,
    });
  }
  updateAllIndexersForAmbience(moduleAmbience: ISurveyAmbiences) {
    moduleAmbience.totalItems = moduleAmbience.allAmbiences ? moduleAmbience.allAmbiences.length : 0;
    moduleAmbience.isComplete = !!moduleAmbience.allAmbiences;
    moduleAmbience.completedItems = 0;
    moduleAmbience.allAmbiences.forEach(oneAmbience => {
      oneAmbience.totalItems = oneAmbience.items.length;
      oneAmbience.completedItems = 0;
      oneAmbience.items.forEach(oneItem => {
        if (oneItem.evaluationDetail) {
          oneAmbience.completedItems += 1;
        }
      });
      if (oneAmbience.isComplete) {
        moduleAmbience.completedItems += 1;
      }
      moduleAmbience.isComplete =
        moduleAmbience.completedItems === moduleAmbience.totalItems &&
        moduleAmbience.allAmbiences &&
        moduleAmbience.allAmbiences.length > 0;
    });
    return moduleAmbience;
  }

  updateAllIndexersForResponsible(moduleResponsibles: ISurveyResponsibles) {
    moduleResponsibles.isComplete = true;
    moduleResponsibles.totalItems = moduleResponsibles.responsibleItems.length;
    moduleResponsibles.completedItems = 0;
    moduleResponsibles.responsibleItems.forEach(oneItem =>
      oneItem.isComplete ? moduleResponsibles.completedItems++ : (moduleResponsibles.isComplete = false)
    );
    moduleResponsibles.isComplete = moduleResponsibles.totalItems === moduleResponsibles.completedItems;
    return moduleResponsibles;
  }

  updateAllIndexersForKeysAndControl(moduleKeysAndControls: ISurveyKeysAndControls) {
    if (moduleKeysAndControls.keysAndControlsItems) {
      moduleKeysAndControls.isComplete = true;
      moduleKeysAndControls.totalItems = moduleKeysAndControls.keysAndControlsItems.length;
      moduleKeysAndControls.completedItems = 0;
      moduleKeysAndControls.keysAndControlsItems.forEach(oneItem => {
        if (oneItem.evaluationDetail) {
          moduleKeysAndControls.completedItems += 1;
        }
      });
      moduleKeysAndControls.keysAndControlsItems = orderBy(
        moduleKeysAndControls.keysAndControlsItems,
        'elementPosition'
      );
      moduleKeysAndControls.isComplete =
        moduleKeysAndControls.totalItems === moduleKeysAndControls.completedItems;
    }

    return moduleKeysAndControls;
  }

  updateAllIndexersForConsumptionMeasures(moduleConsumptionMeasures: ISurveyConsumptionMeasures) {
    moduleConsumptionMeasures.isComplete = true;
    moduleConsumptionMeasures.totalItems = moduleConsumptionMeasures.consumptionMeasureItems.length;
    moduleConsumptionMeasures.completedItems = 0;
    moduleConsumptionMeasures.consumptionMeasureItems.forEach(oneItem => {
      if (oneItem.evaluationDetail) {
        moduleConsumptionMeasures.completedItems += 1;
      }
    });
    moduleConsumptionMeasures.consumptionMeasureItems = orderBy(
      moduleConsumptionMeasures.consumptionMeasureItems,
      'elementPosition'
    );
    moduleConsumptionMeasures.isComplete =
      moduleConsumptionMeasures.totalItems === moduleConsumptionMeasures.completedItems;
    return moduleConsumptionMeasures;
  }

  addCardNameAndCardIndexToArray(array) {
    if (array && array != 'undefined') {
      return array.map(data => {
        if (!data.cardName || !data.cardIndex) {
          const { name, index } = this.getNameWithIndex(data, array);
          data.cardName = name;
          data.cardIndex = index;
        }
        return data;
      });
    }
  }

  getNameWithIndex(data, array) {
    if (data.evaluationSurveyElementItemId) {
      const paramName = data.elementName ? 'elementName' : 'itemName';
      const paramId = data.elementName ? 'elementId' : 'itemId';
      const arrayWithWlements = array.filter(d => d[paramId] === data[paramId]);
      let index = arrayWithWlements.length;
      let name = '';
      // verify if element is add after array is initialized
      if (
        arrayWithWlements[arrayWithWlements.length - 1] &&
        arrayWithWlements[arrayWithWlements.length - 1].cardName
      ) {
        name = `${data[paramName]} ${index + 1}`;
      } else {
        index = arrayWithWlements.findIndex(
          d => d.evaluationSurveyElementItemId === data.evaluationSurveyElementItemId
        );
        name = arrayWithWlements.length > 1 ? `${data[paramName]} ${Number(index) + 1}` : data[paramName];
      }
      return { name, index: Number(index) + 1 };
    }
    return { name: data.elementName, index: 0 };
  }

  sortAmbiences(allAmbiences) {
    const completed = [];
    const uncompleted = [];
    allAmbiences.forEach(ambience1 => {
      const indice = ambience1.items.findIndex(
        i => i.evaluationDetail === null || i.evaluationDetail === undefined
      );
      if (indice === -1) {
        completed.push(ambience1);
      } else {
        uncompleted.push(ambience1);
      }
    });

    let ambienceSorted = uncompleted.sort((a, b) => (a.elementName > b.elementName ? 1 : -1));
    ambienceSorted = ambienceSorted.concat(
      completed.sort((a, b) => (a.elementName > b.elementName ? 1 : -1))
    );

    return ambienceSorted;
  }

  sortElement(allElement) {
    const completed = [];
    const uncompleted = [];
    allElement.forEach(element => {
      if (element.evaluationDetail) {
        completed.push(element);
      } else {
        uncompleted.push(element);
      }
    });

    let elementSorted = uncompleted.sort((a, b) => (a.elementName > b.elementName ? 1 : -1));
    elementSorted = elementSorted.concat(completed.sort((a, b) => (a.elementName > b.elementName ? 1 : -1)));

    return elementSorted;
  }

  sortItems(allItems) {
    const completed = [];
    const uncompleted = [];
    allItems.forEach(item => {
      if (item.evaluationDetail) {
        completed.push(item);
      } else {
        uncompleted.push(item);
      }
    });
    let itemsSorted = uncompleted.sort((a, b) => (a.itemName > b.itemName ? 1 : -1));
    itemsSorted = itemsSorted.concat(completed.sort((a, b) => (a.itemName > b.itemName ? 1 : -1)));
    return itemsSorted;
  }
}
