export const INTERVENINGKEYS: {
  [IntervenerType: string]: {
    id: number;
    name: string;
    email: string;
    rg: string;
    document: string;
    profession: string;
    naturalness: string;
    nationality: string;
    marital: string;
  };
} = {
  owner: {
    id: 1,
    name: 'ownerName',
    email: 'ownerEmail',
    rg: 'ownerRg',
    document: 'ownerDocument',
    profession: 'ownerProfession',
    naturalness: 'ownerNaturalness',
    nationality: 'ownerNationality',
    marital: 'ownerMarital',
  },
  tenant: {
    id: 2,
    name: 'tenantName',
    email: 'tenantEmail',
    rg: 'tenantRg',
    document: 'tenantDocument',
    profession: 'tenantProfession',
    naturalness: 'tenantNaturalness',
    nationality: 'tenantNationality',
    marital: 'tenantMarital',
  },
  guarantor1: {
    id: 3,
    name: 'guarantor1Name',
    email: 'guarantor1Email',
    rg: 'guarantor1Rg',
    document: 'guarantor1Document',
    profession: 'guarantor1Profession',
    naturalness: 'guarantor1Naturalness',
    nationality: 'guarantor1Nationality',
    marital: 'guarantor1Marital',
  },
  guarantor2: {
    id: 4,
    name: 'guarantor2Name',
    email: 'guarantor2Email',
    rg: 'guarantor2Rg',
    document: 'guarantor2Document',
    profession: 'guarantor2Profession',
    naturalness: 'guarantor2Naturalness',
    nationality: 'guarantor2Nationality',
    marital: 'guarantor2Marital',
  },
  ownerPartner: {
    id: 5,
    name: 'ownerPartnerName',
    email: 'ownerPartnerEmail',
    rg: 'ownerPartnerRg',
    document: 'ownerPartnerDocument',
    profession: 'ownerPartnerProfession',
    naturalness: 'ownerPartnerNaturalness',
    nationality: 'ownerPartnerNationality',
    marital: 'ownerPartnerMarital',
  },
  tenantPartner: {
    id: 6,
    name: 'tenantPartnerName',
    email: 'tenantPartnerEmail',
    rg: 'tenantPartnerRg',
    document: 'tenantPartnerDocument',
    profession: 'tenantPartnerProfession',
    naturalness: 'tenantPartnerNaturalness',
    nationality: 'tenantPartnerNationality',
    marital: 'tenantPartnerMarital',
  },
  guarantor1Partner: {
    id: 7,
    name: 'guarantor1PartnerName',
    email: 'guarantor1PartnerEmail',
    rg: 'guarantor1PartnerRg',
    document: 'guarantor1PartnerDocument',
    profession: 'guarantor1PartnerProfession',
    naturalness: 'guarantor1PartnerNaturalness',
    nationality: 'guarantor1PartnerNationality',
    marital: 'guarantor1PartnerMarital',
  },
  guarantor2Partner: {
    id: 8,
    name: 'guarantor2PartnerName',
    email: 'guarantor2PartnerEmail',
    rg: 'guarantor2PartnerRg',
    document: 'guarantor2PartnerDocument',
    profession: 'guarantor2PartnerProfession',
    naturalness: 'guarantor2PartnerNaturalness',
    nationality: 'guarantor2PartnerNationality',
    marital: 'guarantor2PartnerMarital',
  },
  buyer: {
    id: 9,
    name: 'buyerName',
    email: 'buyerEmail',
    rg: 'buyerRg',
    document: 'buyerDocument',
    profession: 'buyerProfession',
    naturalness: 'buyerNaturalness',
    nationality: 'buyerNationality',
    marital: 'buyerMarital',
  },
  buyerPartner: {
    id: 10,
    name: 'buyerPartnerName',
    email: 'buyerPartnerEmail',
    rg: 'buyerPartnerRg',
    document: 'buyerPartnerDocument',
    profession: 'buyerPartnerProfession',
    naturalness: 'buyerPartnerNaturalness',
    nationality: 'buyerPartnerNationality',
    marital: 'buyerPartnerMarital',
  },
  intermediary: {
    id: 11,
    name: 'intermediaryName',
    email: 'intermediaryEmail',
    rg: 'intermediaryRg',
    document: 'intermediaryDocument',
    profession: 'intermediaryProfession',
    naturalness: 'intermediaryNaturalness',
    nationality: 'intermediaryNationality',
    marital: 'intermediaryMarital',
  },
  intermediaryPartner: {
    id: 12,
    name: 'intermediaryPartnerName',
    email: 'intermediaryPartnerEmail',
    rg: 'intermediaryPartnerRg',
    document: 'intermediaryPartnerDocument',
    profession: 'intermediaryPartnerProfession',
    naturalness: 'intermediaryPartnerNaturalness',
    nationality: 'intermediaryPartnerNationality',
    marital: 'intermediaryPartnerMarital',
  },
  surveyor: {
    id: 13,
    name: 'surveyorName',
    email: 'surveyorEmail',
    rg: 'surveyorRg',
    document: 'surveyorDocument',
    profession: 'surveyorProfession',
    naturalness: 'surveyorNaturalness',
    nationality: 'surveyorNationality',
    marital: 'surveyorMarital',
  },
  surveyorPartner: {
    id: 14,
    name: 'surveyorPartnerName',
    email: 'surveyorPartnerEmail',
    rg: 'surveyorPartnerRg',
    document: 'surveyorPartnerDocument',
    profession: 'surveyorPartnerProfession',
    naturalness: 'surveyorPartnerNaturalness',
    nationality: 'surveyorPartnerNationality',
    marital: 'surveyorPartnerMarital',
  },
  resident: {
    id: 15,
    name: 'residentName',
    email: 'residentEmail',
    rg: 'residentPartnerRg',
    document: 'residentDocument',
    profession: 'residentProfession',
    naturalness: 'residentNaturalness',
    nationality: 'residentNationality',
    marital: 'residentMarital',
  },
  residentPartner: {
    id: 16,
    name: 'residentPartnerName',
    email: 'residentPartnerEmail',
    rg: 'residentPartnerRg',
    document: 'residentPartnerDocument',
    profession: 'residentPartnerProfession',
    naturalness: 'residentPartnerNaturalness',
    nationality: 'residentPartnerNationality',
    marital: 'residentPartnerMarital',
  },
  joint: {
    id: 17,
    name: 'jointName',
    email: 'jointEmail',
    rg: 'jointRg',
    document: 'jointDocument',
    profession: 'jointProfession',
    naturalness: 'jointNaturalness',
    nationality: 'jointNationality',
    marital: 'jointMarital',
  },
  jointPartner: {
    id: 18,
    name: 'jointPartnerName',
    email: 'jointPartnerEmail',
    rg: 'jointPartnerRg',
    document: 'jointPartnerDocument',
    profession: 'jointPartnerProfession',
    naturalness: 'jointPartnerNaturalness',
    nationality: 'jointPartnerNationality',
    marital: 'jointPartnerMarital',
  },
  propertymanager: {
    id: 19,
    name: 'propertymanagerName',
    email: 'propertymanagerEmail',
    rg: 'propertymanagerRg',
    document: 'propertymanagerDocument',
    profession: 'propertymanagerProfession',
    naturalness: 'propertymanagerNaturalness',
    nationality: 'propertymanagerNationality',
    marital: 'propertymanagerMarital',
  },
  propertymanagerPartner: {
    id: 20,
    name: 'propertymanagerPartnerName',
    email: 'propertymanagerPartnerEmail',
    rg: 'propertymanagerPartnerRg',
    document: 'propertymanagerPartnerDocument',
    profession: 'propertymanagerPartnerProfession',
    naturalness: 'propertymanagerPartnerNaturalness',
    nationality: 'propertymanagerPartnerNationality',
    marital: 'propertymanagerPartnerMarital',
  },
  legalresponsible: {
    id: 21,
    name: 'legalresponsibleName',
    email: 'legalresponsibleEmail',
    rg: 'legalresponsibleRg',
    document: 'legalresponsibleDocument',
    profession: 'legalresponsibleProfession',
    naturalness: 'legalresponsibleNaturalness',
    nationality: 'legalresponsibleNationality',
    marital: 'legalresponsibleMarital',
  },
  legalresponsiblePartner: {
    id: 22,
    name: 'legalresponsiblePartnerName',
    email: 'legalresponsiblePartnerEmail',
    rg: 'legalresponsiblePartnerRg',
    document: 'legalresponsiblePartnerDocument',
    profession: 'legalresponsiblePartnerProfession',
    naturalness: 'legalresponsiblePartnerNaturalness',
    nationality: 'legalresponsiblePartnerNationality',
    marital: 'legalresponsiblePartnerMarital',
  },
};
export const INTERVENINGTYPEID = {
  1: 'owner',
  2: 'tenant',
  3: 'guarantor1',
  4: 'guarantor2',
  5: 'ownerPartner',
  6: 'tenantPartner',
  7: 'guarantor1Partner',
  8: 'guarantor2Partner',
  9: 'buyer',
  10: 'buyerPartner',
  11: 'intermediary',
  12: 'intermediaryPartner',
  13: 'surveyor',
  14: 'surveyorPartner',
  15: 'resident',
  16: 'residentPartner',
  17: 'joint',
  18: 'jointPartner',
  19: 'propertymanager',
  20: 'propertymanagerPartner',
  21: 'legalresponsible',
  22: 'legalresponsiblePartner',
};
export const INTERVENINGNAME = {
  1: 'MODELS.OWNER',
  2: 'MODELS.LESSEE',
  3: 'MODELS.GUARANTOR_ONE',
  4: 'MODELS.GUARANTOR_TWO',
  5: 'MODELS.SPOUSE_OWNER',
  6: 'MODELS.SPOUSE_LESSEE',
  7: 'MODELS.SPOUSE_GUARANTOR_ONE',
  8: 'MODELS.SPOUSE_GUARANTOR_TWO',
  9: 'MODELS.BUYER',
  10: 'MODELS.SPOUSE_BUYER',
  11: 'MODELS.INTERMEDIARY',
  12: 'MODELS.SPOUSE_INTERMEDIARY',
  13: 'MODELS.SURVEYOR',
  14: 'MODELS.SPOUSE_SURVEYOR',
  15: 'MODELS.RESIDENT',
  16: 'MODELS.SPOUSE_RESIDENT',
  17: 'MODELS.JOINT_TENANT',
  18: 'MODELS.SPOUSE_JOINT_TENANT',
  19: 'MODELS.PROPERTY_MANAGER',
  20: 'MODELS.SPOUSE_PROPERTY_MANAGER',
  21: 'MODELS.LEGAL_RESPONSIBLE',
  22: 'MODELS.SPOUSE_LEGAL_RESPONSIBLE',
};

export const getInterveningTypeIdForName = (name: string): number => {
  return INTERVENINGKEYS[name].id;
};

export const LABELSKEYS: {
  [InterveningTypeId: string]: {
    title: string;
    data: string;
    enterName: string;
    enterIntervenersName: string;
    enterEmail: string;
    enterIntervenersEmail: string;
    enterCPFCNPJ: string;
    informIntervenerCPFCNPJ: string;
    enterIntervenerId: string;
    informIntervenerId: string;
    intervenerDetails: string;
    enterIntervenerProfession: string;
    enterBirthplace: string;
  };
} = {
  owner: {
    title: 'COMPONENTS.FORMS.OWNER',
    data: 'COMPONENTS.FORMS.OWNER_DATA',
    enterName: 'COMPONENTS.FORMS.ENTER_OWNER_NAME',
    enterIntervenersName: 'COMPONENTS.FORMS.ENTER_THE_OWNERS_NAME',
    enterEmail: 'COMPONENTS.FORMS.ENTER_OWNER_EMAIL',
    enterIntervenersEmail: 'COMPONENTS.FORMS.ENTER_THE_OWNERS_EMAIL_ADDRESS',
    enterCPFCNPJ: 'COMPONENTS.FORMS.ENTER_THE_OWNERS_CPF_CNPJ',
    informIntervenerCPFCNPJ: 'COMPONENTS.FORMS.INFORM_THE_OWNERS_CPF_CNPJ',
    enterIntervenerId: 'COMPONENTS.FORMS.ENTER_OWNER_ID',
    informIntervenerId: 'COMPONENTS.FORMS.INFORM_THE_OWNERID',
    intervenerDetails: 'COMPONENTS.FORMS.OWNER_DETAILS',
    enterIntervenerProfession: 'COMPONENTS.FORMS.ENTER_OWNERS_PROFESSION',
    enterBirthplace: 'COMPONENTS.FORMS.ENTER_THE_BIRTHPLACE',
  },
  tenant: {
    title: 'COMPONENTS.FORMS.LESSEE',
    data: 'COMPONENTS.FORMS.LESSEE_DATA',
    enterName: 'COMPONENTS.FORMS.ENTER_RENTERS_NAME',
    enterIntervenersName: 'COMPONENTS.FORMS.ENTER_THE_NAME_TENANT',
    enterEmail: 'COMPONENTS.FORMS.INSERT_THE_EMAIL_TENANT',
    enterIntervenersEmail: 'COMPONENTS.FORMS.ENTER_THE_TENANTS_EMAIL',
    enterCPFCNPJ: 'COMPONENTS.FORMS.ENTER_THE_CPF_CNPJ_LESSEE',
    informIntervenerCPFCNPJ: 'COMPONENTS.FORMS.INFORM_THE_CPF_CNPJ_LESSEE',
    enterIntervenerId: 'COMPONENTS.FORMS.ENTER_TENANT_ID',
    informIntervenerId: 'COMPONENTS.FORMS.INSERT_TENANT_ID',
    intervenerDetails: 'COMPONENTS.FORMS.RENTER_DETAILS',
    enterIntervenerProfession: 'COMPONENTS.FORMS.ENTER_THE_TENANTS_PROFESSION',
    enterBirthplace: 'COMPONENTS.FORMS.ENTER_THE_PLACE_OF_BIRTH',
  },
  guarantor1: {
    title: 'COMPONENTS.FORMS.GUARANTOR_ONE',
    data: 'COMPONENTS.FORMS.GUARANTOR_DATA_ONE',
    enterName: 'COMPONENTS.FORMS.INSERT_THE_NAME_OF_GUARANTOR_ONE',
    enterIntervenersName: 'COMPONENTS.FORMS.ENTER_THE_NAME_GUARANTER_ONE',
    enterEmail: 'COMPONENTS.FORMS.ENTER_THE_GUARANTORS',
    enterIntervenersEmail: 'COMPONENTS.FORMS.ENTER_THE_GUARANTORS_EMAIL_ONE',
    enterCPFCNPJ: 'COMPONENTS.FORMS.ENTER_THE_CPF_GUARANTOR_ONE',
    informIntervenerCPFCNPJ: 'COMPONENTS.FORMS.INFORM_THE_CPF_GUARANTOR_ONE',
    enterIntervenerId: 'COMPONENTS.FORMS.ENTER_THE_RG_GUARANTOR_ONE',
    informIntervenerId: 'COMPONENTS.FORMS.INFORM_THE_RG',
    intervenerDetails: 'COMPONENTS.FORMS.GUARANTOR_DETAILS',
    enterIntervenerProfession: 'COMPONENTS.FORMS.ENTER_THE_GUARANTOR_PROFESSION',
    enterBirthplace: 'COMPONENTS.FORMS.ENTER_THE_GUARANTORS_BIRTHPLACE',
  },
  buyer: {
    title: 'COMPONENTS.FORMS.BUYER',
    data: 'COMPONENTS.FORMS.BUYER_DATA',
    enterName: 'COMPONENTS.FORMS.BUYER_NAME',
    enterIntervenersName: 'COMPONENTS.FORMS.ENTER_THE_BUYERS_NAME',
    enterEmail: 'COMPONENTS.FORMS.ENTER_BUYER_EMAIL',
    enterIntervenersEmail: 'COMPONENTS.FORMS.ENTER_THE_BUYERS_EMAIL_ADDRESS',
    enterCPFCNPJ: 'COMPONENTS.FORMS.ENTER_BUYERS_DOCUMENT',
    informIntervenerCPFCNPJ: 'COMPONENTS.FORMS.INFORM_BUYERS_DOCUMENT',
    enterIntervenerId: 'COMPONENTS.FORMS.ENTER_BUYERS_ID',
    informIntervenerId: 'COMPONENTS.FORMS.INFORM_THE_BUYERS_ID',
    intervenerDetails: 'COMPONENTS.FORMS.BUYER_DETAILS',
    enterIntervenerProfession: 'COMPONENTS.FORMS.ENTER_BUYERS_PROFESSION',
    enterBirthplace: 'COMPONENTS.FORMS.ENTER_BUYERS_BIRTHPLACE',
  },
  intermediary: {
    title: 'COMPONENTS.FORMS.INTERMEDIARY',
    data: 'COMPONENTS.FORMS.INTERMEDIARY_DATA',
    enterName: 'COMPONENTS.FORMS.INTERMEDIARY_NAME',
    enterIntervenersName: 'COMPONENTS.FORMS.ENTER_THE_INTERMEDIARYS_NAME',
    enterEmail: 'COMPONENTS.FORMS.ENTER_INTERMEDIARY_EMAIL',
    enterIntervenersEmail: 'COMPONENTS.FORMS.ENTER_THE_INTERMEDIARYS_EMAIL_ADDRESS',
    enterCPFCNPJ: 'COMPONENTS.FORMS.ENTER_INTERMEDIARYS_DOCUMENT',
    informIntervenerCPFCNPJ: 'COMPONENTS.FORMS.INFORM_INTERMEDIARYS_DOCUMENT',
    enterIntervenerId: 'COMPONENTS.FORMS.ENTER_INTERMEDIARYS_ID',
    informIntervenerId: 'COMPONENTS.FORMS.INFORM_THE_INTERMEDIARYS_ID',
    intervenerDetails: 'COMPONENTS.FORMS.INTERMEDIARY_DETAILS',
    enterIntervenerProfession: 'COMPONENTS.FORMS.ENTER_INTERMEDIARYS_PROFESSION',
    enterBirthplace: 'COMPONENTS.FORMS.ENTER_INTERMEDIARYS_BIRTHPLACE',
  },
  surveyor: {
    title: 'COMPONENTS.FORMS.SURVEYOR',
    data: 'COMPONENTS.FORMS.SURVEYOR_DATA',
    enterName: 'COMPONENTS.FORMS.SURVEYOR_NAME',
    enterIntervenersName: 'COMPONENTS.FORMS.ENTER_THE_SURVEYOR_NAME',
    enterEmail: 'COMPONENTS.FORMS.ENTER_SURVEYOR_EMAIL',
    enterIntervenersEmail: 'COMPONENTS.FORMS.ENTER_THE_SURVEYOR_EMAIL_ADDRESS',
    enterCPFCNPJ: 'COMPONENTS.FORMS.ENTER_SURVEYOR_DOCUMENT',
    informIntervenerCPFCNPJ: 'COMPONENTS.FORMS.INFORM_SURVEYOR_DOCUMENT',
    enterIntervenerId: 'COMPONENTS.FORMS.ENTER_SURVEYOR_ID',
    informIntervenerId: 'COMPONENTS.FORMS.INFORM_THE_SURVEYOR_ID',
    intervenerDetails: 'COMPONENTS.FORMS.SURVEYOR_DETAILS',
    enterIntervenerProfession: 'COMPONENTS.FORMS.ENTER_SURVEYOR_PROFESSION',
    enterBirthplace: 'COMPONENTS.FORMS.ENTER_SURVEYOR_BIRTHPLACE',
  },
  resident: {
    title: 'COMPONENTS.FORMS.RESIDENT',
    data: 'COMPONENTS.FORMS.RESIDENT_DATA',
    enterName: 'COMPONENTS.FORMS.RESIDENT_NAME',
    enterIntervenersName: 'COMPONENTS.FORMS.ENTER_THE_RESIDENT_NAME',
    enterEmail: 'COMPONENTS.FORMS.ENTER_RESIDENT_EMAIL',
    enterIntervenersEmail: 'COMPONENTS.FORMS.ENTER_THE_RESIDENT_EMAIL_ADDRESS',
    enterCPFCNPJ: 'COMPONENTS.FORMS.ENTER_RESIDENT_DOCUMENT',
    informIntervenerCPFCNPJ: 'COMPONENTS.FORMS.INFORM_RESIDENT_DOCUMENT',
    enterIntervenerId: 'COMPONENTS.FORMS.ENTER_RESIDENT_ID',
    informIntervenerId: 'COMPONENTS.FORMS.INFORM_THE_RESIDENT_ID',
    intervenerDetails: 'COMPONENTS.FORMS.RESIDENT_DETAILS',
    enterIntervenerProfession: 'COMPONENTS.FORMS.ENTER_RESIDENT_PROFESSION',
    enterBirthplace: 'COMPONENTS.FORMS.ENTER_RESIDENT_BIRTHPLACE',
  },
  joint: {
    title: 'COMPONENTS.FORMS.JOINT_TENANT',
    data: 'COMPONENTS.FORMS.JOINT_TENANT_DATA',
    enterName: 'COMPONENTS.FORMS.JOINT_TENANT_NAME',
    enterIntervenersName: 'COMPONENTS.FORMS.ENTER_THE_JOINT_TENANT_NAME',
    enterEmail: 'COMPONENTS.FORMS.ENTER_JOINT_TENANT_EMAIL',
    enterIntervenersEmail: 'COMPONENTS.FORMS.ENTER_THE_JOINT_TENANT_EMAIL_ADDRESS',
    enterCPFCNPJ: 'COMPONENTS.FORMS.ENTER_JOINT_TENANT_DOCUMENT',
    informIntervenerCPFCNPJ: 'COMPONENTS.FORMS.INFORM_JOINT_TENANT_DOCUMENT',
    enterIntervenerId: 'COMPONENTS.FORMS.ENTER_JOINT_TENANT_ID',
    informIntervenerId: 'COMPONENTS.FORMS.INFORM_THE_JOINT_TENANT_ID',
    intervenerDetails: 'COMPONENTS.FORMS.JOINT_TENANT_DETAILS',
    enterIntervenerProfession: 'COMPONENTS.FORMS.ENTER_JOINT_TENANT_PROFESSION',
    enterBirthplace: 'COMPONENTS.FORMS.ENTER_JOINT_TENANT_BIRTHPLACE',
  },
  propertymanager: {
    title: 'COMPONENTS.FORMS.PROPERTY_MANAGER',
    data: 'COMPONENTS.FORMS.PROPERTY_MANAGER_DATA',
    enterName: 'COMPONENTS.FORMS.PROPERTY_MANAGER_NAME',
    enterIntervenersName: 'COMPONENTS.FORMS.ENTER_THE_PROPERTY_MANAGER_NAME',
    enterEmail: 'COMPONENTS.FORMS.ENTER_PROPERTY_MANAGER_EMAIL',
    enterIntervenersEmail: 'COMPONENTS.FORMS.ENTER_THE_PROPERTY_MANAGER_EMAIL_ADDRESS',
    enterCPFCNPJ: 'COMPONENTS.FORMS.ENTER_PROPERTY_MANAGER_DOCUMENT',
    informIntervenerCPFCNPJ: 'COMPONENTS.FORMS.INFORM_PROPERTY_MANAGER_DOCUMENT',
    enterIntervenerId: 'COMPONENTS.FORMS.ENTER_PROPERTY_MANAGER_ID',
    informIntervenerId: 'COMPONENTS.FORMS.INFORM_THE_PROPERTY_MANAGER_ID',
    intervenerDetails: 'COMPONENTS.FORMS.PROPERTY_MANAGER_DETAILS',
    enterIntervenerProfession: 'COMPONENTS.FORMS.ENTER_PROPERTY_MANAGER_PROFESSION',
    enterBirthplace: 'COMPONENTS.FORMS.ENTER_PROPERTY_MANAGER_BIRTHPLACE',
  },
  legalresponsible: {
    title: 'COMPONENTS.FORMS.LEGAL_RESPONSIBLE',
    data: 'COMPONENTS.FORMS.LEGAL_RESPONSIBLE_DATA',
    enterName: 'COMPONENTS.FORMS.LEGAL_RESPONSIBLE_NAME',
    enterIntervenersName: 'COMPONENTS.FORMS.ENTER_THE_LEGAL_RESPONSIBLE_NAME',
    enterEmail: 'COMPONENTS.FORMS.ENTER_LEGAL_RESPONSIBLE_EMAIL',
    enterIntervenersEmail: 'COMPONENTS.FORMS.ENTER_THE_LEGAL_RESPONSIBLE_EMAIL_ADDRESS',
    enterCPFCNPJ: 'COMPONENTS.FORMS.ENTER_LEGAL_RESPONSIBLE_DOCUMENT',
    informIntervenerCPFCNPJ: 'COMPONENTS.FORMS.INFORM_LEGAL_RESPONSIBLE_DOCUMENT',
    enterIntervenerId: 'COMPONENTS.FORMS.ENTER_LEGAL_RESPONSIBLE_ID',
    informIntervenerId: 'COMPONENTS.FORMS.INFORM_THE_LEGAL_RESPONSIBLE_ID',
    intervenerDetails: 'COMPONENTS.FORMS.LEGAL_RESPONSIBLE_DETAILS',
    enterIntervenerProfession: 'COMPONENTS.FORMS.ENTER_LEGAL_RESPONSIBLE_PROFESSION',
    enterBirthplace: 'COMPONENTS.FORMS.ENTER_LEGAL_RESPONSIBLE_BIRTHPLACE',
  },
};

export const INTERVENINGTYPEPARTNER = {
  owner: INTERVENINGTYPEID[5],
  tenant: INTERVENINGTYPEID[6],
  guarantor1: INTERVENINGTYPEID[7],
  buyer: INTERVENINGTYPEID[10],
  intermediary: INTERVENINGTYPEID[12],
  surveyor: INTERVENINGTYPEID[14],
  resident: INTERVENINGTYPEID[16],
  joint: INTERVENINGTYPEID[18],
  propertymanager: INTERVENINGTYPEID[20],
  legalresponsible: INTERVENINGTYPEID[22],
};
