import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';
import { Matcher } from './shared/providers/matcher.service';

const routes: Routes = [
  {
    path: 'redefinir-senha/:token',
    loadChildren: () =>
      import('./pages/reset-password/reset-password.module').then((m) => m.ResetPasswordPageModule),
  },
  {
    path: 'assinatura/:id',
    loadChildren: () => import('./pages/signature/signature.module').then((m) => m.SignaturePageModule),
  },
  {
    path: 'assinar',
    loadChildren: () =>
      import('./pages/signature/pages/zero-step/zero-step.module').then((m) => m.ZeroStepPageModule),
  },
  {
    path: 'testemunha/assinatura/:id',
    loadChildren: () => import('./pages/signature-witness/signature.module').then((m) => m.SignaturePageModule),
  },
  {
    path: 'testemunha/assinar',
    loadChildren: () =>
      import('./pages/signature-witness/pages/zero-step/zero-step.module').then((m) => m.ZeroStepPageModule),
  },
  {
    path: 'novo-usuario',
    loadChildren: () => import('./pages/new-user/new-user.module').then((m) => m.NewUserPageModule),
  },
  {
    path: 'login',
    data: { matcher: Matcher.sendToLogin },
    loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    canLoad: [AuthGuard],
    matcher: Matcher.sendToProfileModule,
    loadChildren: () => import('./pages/profile/profile.module').then((m) => m.ProfilePageModule),
  },
  {
    path: 'redefinir-senha',
    loadChildren: () =>
      import('./pages/reset-password/reset-password.module').then((m) => m.ResetPasswordPageModule),
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}