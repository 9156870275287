import { State, Selector, Action, StateContext } from '@ngxs/store';

import { SetSignatureForResponsible, SetSignatureForSurveyor, SetIsSurveyor } from './signature.actions';
import { ISignature } from './signature.interface';
import { Injectable } from '@angular/core';

@Injectable()
@State<ISignature>({
  name: 'signature',
  defaults: {
    responsibleSignature: '',
    surveyorSignature: '',
    isSurveyor: true
  }
})
export class SignatureState {
  /****************************
   * Selectors
   ****************************/

  @Selector()
  static getResponsibleSignature({ responsibleSignature }: ISignature) {
    return responsibleSignature;
  }

  @Selector()
  static getSurveyorSignature({ surveyorSignature }: ISignature) {
    return surveyorSignature;
  }

  @Selector()
  static getIsSurveyor({ isSurveyor }: ISignature) {
    return isSurveyor;
  }

  /****************************
   * Actions
   ****************************/

  @Action(SetSignatureForResponsible)
  SetSignatureForResponsible(
    { patchState }: StateContext<ISignature>,
    { responsibleSignature }: SetSignatureForResponsible
  ) {
    patchState({ responsibleSignature });
  }

  @Action(SetSignatureForSurveyor)
  SetSignatureForSurveyor(
    { patchState }: StateContext<ISignature>,
    { surveyorSignature }: SetSignatureForSurveyor
  ) {
    patchState({ surveyorSignature });
  }

  @Action(SetIsSurveyor)
  SetIsSurveyor({ patchState }: StateContext<ISignature>, { isSurveyor }: SetIsSurveyor) {
    patchState({ isSurveyor });
  }
}
