import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Store } from '@ngxs/store';
import { Response } from '@shared/models/interfaces/response.interface';
import { HttpService } from './http.service';

export interface File64 {
  fileBase64: string;
  fileName: string;
}

export interface ResponseUploadBase64 {
  urls: string[];
}

@Injectable({
  providedIn: 'root',
})
export class UploadService extends HttpService {
  constructor(httpClient: HttpClient, store: Store) {
    super(environment.endpoints.file, httpClient, store);
  }

  async uploadBase64(files: File64[], surveyId?: number): Promise<Response<ResponseUploadBase64>> {
    const response: ResponseUploadBase64 = { urls: [] };
    let lastResponse: Response<ResponseUploadBase64>;
    let endpointUrl = '/base';
    if (surveyId) {
      endpointUrl = endpointUrl + `?surveyId=${surveyId}`;
    }
    for await (const file of files) {
      const lastResponse = await this.postAsync<File64[], Response<ResponseUploadBase64>>(
        [file],
        endpointUrl,
      );
      response.urls.push(...lastResponse.data.urls);
    }
    return {
      ...lastResponse,
      data: response,
    };
  }

  uploadFiles(files: File64[]) {
    return this.postAsync<File64[], Response<any>>(files, '/files');
  }
}
