import { State, Selector, Action, StateContext } from '@ngxs/store';
import { List } from '@shared/models/interfaces/List.interface';
import { Segment } from '@shared/models/interfaces/segment.interface';

import { SetSegments } from './segments.actions';
import { Injectable } from '@angular/core';

/**
 * State of Segment
 * Add new props in defaults segment
 */
@Injectable()
@State<List<Segment>>({
  name: 'segments',
  defaults: {
    total: 0,
    perPage: 0,
    page: 0,
    lastPage: 0,
    data: []
  }
})
export class SegmentState {
  /****************************
   * Selectors
   ****************************/
  @Selector()
  static getSegments(segmentState: List<Segment>) {
    return segmentState;
  }

  /****************************
   * Actions
   ****************************/

  @Action(SetSegments)
  setSegments({ patchState }: StateContext<List<Segment>>, { segments }: SetSegments) {
    patchState(segments);
  }
}
