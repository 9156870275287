import { Action, Selector, State, StateContext } from '@ngxs/store';

import { SetWitnessSignatureRequestData, SetWitnessSignatureResponseData } from './witness.actions';
import { IWitness } from './witness.interface';
import { Injectable } from '@angular/core';

/**
 * State of Item
 * Add new props in defaults object
 */
@Injectable()
@State<IWitness>({
  name: 'witness',
  defaults: {
    signature: {
      witnessSignatureRequest: {
        witnessSignatureIp: '',
        witnessSignatureToken: '',
        witnessSinatureCpf: ''
      },
      witnessSignatureResponse: {
        witnessEmail: '',
        surveyReportLink: '',
        tokenRequested: false,
        witnessHasSigned: false,
      }
    }
  }
})
export class WitnessState {
  /****************************
   * Selectors
   ****************************/
  @Selector()
  static getWitnessSignatureData(intervening: IWitness) {
    return intervening.signature;
  }

  /****************************
   * Actions
   ****************************/

  @Action(SetWitnessSignatureRequestData)
  setWitnessSignatureRequestData(
    { patchState, getState }: StateContext<IWitness>,
    { witnessSignatureRequest }: SetWitnessSignatureRequestData
  ) {
    if (witnessSignatureRequest) {
      const currentState = getState();
      patchState({ ...currentState, signature: { ...currentState.signature, witnessSignatureRequest } });
    }
  }

  @Action(SetWitnessSignatureResponseData)
  setWitnessSignatureResponseData(
    { patchState, getState }: StateContext<IWitness>,
    { witnessSignatureResponse }: SetWitnessSignatureResponseData
  ) {
    if (witnessSignatureResponse) {
      const currentState = getState();
      patchState({ ...currentState, signature: { ...currentState.signature, witnessSignatureResponse } });
    }
  }
}
