import { State, Selector, Action, StateContext } from '@ngxs/store';

import { SetUserEditId, SetUserEditIsEditing } from './edit.actions';
import { IEditStateModel } from './edit.interface';
import { Injectable } from '@angular/core';

@Injectable()
@State<IEditStateModel>({
  name: 'sharedEdit',
  defaults: {
    userEditId: null,
    userEditIsEditing: null
  }
})
export class EditState {
  /****************************
   * Selectors
   ****************************/

  @Selector()
  static geUserEditId({ userEditId }: IEditStateModel) {
    return userEditId;
  }

  @Selector()
  static geUserEditIsEditing({ userEditIsEditing }: IEditStateModel) {
    return userEditIsEditing;
  }

  /****************************
   * Actions
   ****************************/

  @Action(SetUserEditId)
  setUserEditId({ patchState }: StateContext<IEditStateModel>, { userEditId }: SetUserEditId) {
    patchState({ userEditId });
  }

  @Action(SetUserEditIsEditing)
  setUserEditIsEditing(
    { patchState }: StateContext<IEditStateModel>,
    { userEditIsEditing }: SetUserEditIsEditing
  ) {
    patchState({ userEditIsEditing });
  }
}
