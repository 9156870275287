export const MARITALSTATUS = {
  single: 1,
  married: 2,
  separated: 3,
  divorced: 4,
  widower: 5,
};

export const MARITALSTATUSNAME = [
  'MODELS.NOT_MARRIED',
  'MODELS.MARRIED',
  'MODELS.SEPARATE',
  'MODELS.DIVORCED',
  'MODELS.WIDOWER',
];

export const getMaritalIdForName = (name: string | number): number => {
  return MARITALSTATUSNAME.findIndex(value => value === name) + 1 || +(name || 0);
};
