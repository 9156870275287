import { IAmbienceItem, ISurveyModule, ISurveyModules, SurveyElementInformation, evaluationDetailConsumptionMeter, evaluationDetailKeysControls } from './survey.interface';
export class SetSurveyId {
  static readonly type = '[SHARED-SURVEY] Set inspect id to survey';
  constructor(public surveyId: number) {}
}
export class SetSurveyHash {
  static readonly type = '[SHARED-SURVEY] Set inspect hash to survey';
  constructor(public surveyHash: string) {}
}
export class SetSurveyUnderInspection {
  static readonly type = '[SHARED-SURVEY] Set inspect survey';
  constructor(public surveyData: any) {}
}

export class AddAmbienceToSurvey {
  static readonly type = '[SHARED-SURVEY] Add an ambience to survey';
  constructor(public ambience: any) {}
}
export class AddConsumeMeasurerToSurvey {
  static readonly type = '[SHARED-SURVEY] Add an consume measurer to survey';
  constructor(public measurer: any) {}
}

export class AddItemsToAmbience {
  static readonly type = '[SHARED-SURVEY] Add items to ambience';
  constructor(public items: any, public ambienceEvalId: string) {}
}

export class SetIsSigned {
  static readonly type = '[SHARED-SURVEY] Set if survey is already signed';
  constructor(public isSigned: boolean) {}
}

export class SetAmbiencesToSurvey {
  static readonly type = '[SHARED-SURVEY] Set all ambiences to survey';
  constructor(public allAmbiences: SurveyElementInformation[]) {}
}

export class SetResponsiblesToSurvey {
  static readonly type = '[SHARED-SURVEY] Set all responsibles to survey';
  constructor(public responsibleItems: any[]) {}
}

export class AddResponsibleToSurvey {
  static readonly type = '[SHARED-SURVEY] Add a responsible to survey';
  constructor(public responsible: any) {}
}
export class SetConsumeMeasurersToSurvey {
  static readonly type = '[SHARED-SURVEY] Set all consume measurers to survey';
  constructor(public consumptionMeasureItems: any[]) {}
}

export class SetKeysAndControlsToSurvey {
  static readonly type = '[SHARED-SURVEY] Set all keys and controls to survey';
  constructor(public keysAndControlsItems: any[]) {}
}

export class SetConsumptionMeasuresToSurvey {
  static readonly type = '[SHARED-SURVEY] Set all consumption measures to survey';
  constructor(public moduleConsumptionMeasures: any[]) {}
}

export class SetItemsToAmbience {
  static readonly type = '[SHARED-SURVEY] Set items to ambience';
  constructor(public ambience: any) {}
}
export class UpdateAmbience {
  static readonly type = '[SHARED-SURVEY] Update an ambience on survey';
  constructor(public ambience: SurveyElementInformation) {}
}
export class RemoveAmbienceFromSurvey {
  static readonly type = '[SHARED-SURVEY] Remove an ambience to survey';
  constructor(public ambienceEvalId: string) {}
}

export class RemoveItemFromAmbience {
  static readonly type = '[SHARED-SURVEY] Remove an item from an ambience';
  constructor(public ambience: any, public itemId: string) {}
}

export class RemoveConsumptionMeaurer {
  static readonly type = '[SHARED-SURVEY] Remove an consumption measurer';
  constructor(public itemId: string) {}
}

export class RemoveKeysAndControlsItem {
  static readonly type = '[SHARED-SURVEY] Remove an keys and controls item';
  constructor(public itemId: string) {}
}
export class UpdateAmbienceItem {
  static readonly type = '[SHARED-SURVEY] set item data for ambience';
  constructor(public ambienceEvalId: string, public ambienceItem: IAmbienceItem) {}
}

export class UpdateResponsibleItem {
  static readonly type = '[SHARED-SURVEY] set item data for responsible';
  constructor(public responsibleId: number, public responsibleItem: any) {}
}

export class AddKeysAndControlsToSurvey {
  static readonly type = '[SHARED-SURVEY] Add Keys and controls to survey';
  constructor(public keysAndControls: any) {}
}

export class UpdateKeysAndControlsItem {
  static readonly type = '[SHARED-SURVEY] set item data for keys and controls';
  constructor(public keysAndControlsId: string, public keysAndControlsItem: evaluationDetailKeysControls) {}
}

export class UpdateConsumptionMeasureItem {
  static readonly type = '[SHARED-SURVEY] set item data for consumption measurer';
  constructor(public consumptionmeasureId: string, public consumptionmeasureItem: evaluationDetailConsumptionMeter) {}
}
export class UpdateSignature {
  static readonly type = '[SHARED-SURVEY] set signature for survey';
  constructor(public surveyorSignature: string, public responsibleSignature: string) {}
}

export class ValidateAndCompleteSurvey {
  static readonly type = '[SHARED-SURVEY] validate and complete survey';
}
export class UpdateSelectedModules {
  static readonly type = '[SHARED-SURVEY] update selected modules';
  constructor(public modules: ISurveyModules[]) {}
}
export class ResetModules {
  static readonly type = '[SHARED-SURVEY] reset modules';
  constructor(public modules: any[]) {}
}
export class SetSurveyState {
  static readonly type = '[SHARED-SURVEY] Set Survey State';
  constructor(public surveyState: any) {}
}
