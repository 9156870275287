import { Injectable } from '@angular/core';

import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  deserializeToken(token?: string): any {
    return token ? jwt_decode(token) : jwt_decode(JSON.parse(localStorage.getItem('token')).token);
  }
}
