export const SURVEYSTATUS = {
  pending: 1,
  progress: 2,
  donePending: 3,
  done: 4,
};

export const SURVEYSTATUSNAME = {
  1: 'MODELS.PENDING',
  2: 'MODELS.IN_PROGRESS',
  3: 'MODELS.PERFORMED',
  4: 'MODELS.FULFILLED',
};
