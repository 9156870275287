import { Injectable } from '@angular/core';
import { Route, Router, CanLoad } from '@angular/router';

import { Store } from '@ngxs/store';
import { UserState } from '@shared/store/logged-user/user.state';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {
  constructor(private router: Router, private store: Store) {}

  canLoad(route: Route): boolean | Promise<boolean> | Observable<boolean> {
    if (this.store.selectSnapshot(UserState.getUserToken)) {
      return true;
    }
    this.router.navigate(['']);
    return false;
  }
}
