
export class SetNotificationsOpened {
  static readonly type = '[NOTIFICATION] Set notification opened';
  constructor(public notificationOpened: boolean) {}
}

export class SetNotificationsReceived {
  static readonly type = '[NOTIFICATION] Set notification received';
  constructor(public notificationReceived: boolean) {}
}

export class SetNotificationsPayload {
  static readonly type = '[NOTIFICATION] Set notification payload';
  constructor(public payload: any) {}
}
