import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '@environments/environment';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AlertController, Platform, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '@shared/providers/authentication.service';
import { IndexedDBService } from '@shared/providers/indexed-db.service';
import { UtilsService } from '@shared/providers/utils.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  constructor(
    private aplicationTitleService: Title,
    private platform: Platform,
    private statusBar: StatusBar,
    private authenticationService: AuthenticationService,
    public alertController: AlertController,
    public toastController: ToastController,
    private utils: UtilsService,
    private gtmService: GoogleTagManagerService,
    private translate: TranslateService,
    private indexedDBService: IndexedDBService
  ) {
    // HACK: fix toast not presented when offline, due to lazy loading the toast controller.
    // Can be removed once #17450 is resolved: https://github.com/ionic-team/ionic/issues/17450
    this.toastController.create({ animated: false }).then(t => {
      t.present();
      t.dismiss();
    });
    this.alertController.create({ animated: false }).then(t => {
      t.present();
      t.dismiss();
    });

    this.authenticationService.init();
    this.utils.presentLoading('Iniciando', 1000).then(() => {
      this.initializeApp();
      this.gtmService.addGtmToDom();
    });

    this.aplicationTitleService.setTitle(environment.title);
    this.favIcon.href = environment.faviconUrl;

    this.setCurrentLanguage();
  }

  setCurrentLanguage() {
    const languageData = this.utils.getCurrentLanguage();

    this.translate.setDefaultLang(languageData.languageValue);
  }

  initializeApp() {
    this.indexedDBService.initDB();
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      if (this.platform.is('cordova')) {
        this.statusBar.overlaysWebView(false);
        // this.utils.checkCodePush(); //Use the plugin always after platform.ready()
      }
    });
  }
}
