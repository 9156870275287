import { PhotoLimitHandlerModel, PhotoLimitStateModel } from './photos-limit.interface';

export class SetPhotoLimit {
  static readonly type = '[PhotoLimit] Set photo limit';
  constructor(public photoLimit: PhotoLimitStateModel) {}
}

export class HandlePhotos {
  static readonly type = '[PhotoLimit] handle quantity of photo in balance';
  constructor(public qtdPhotos: PhotoLimitHandlerModel) {}
}

