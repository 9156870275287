import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Element } from '@shared/models/interfaces/element.interface';
import { Template } from '@shared/models/interfaces/template.interface';

import {
  AddAmbienceItems,
  AddAmbienceName,
  AddTemplateElements,
  AddTemplateName,
  AddTemplates,
  SetAdminTemplates,
  SetAmbiences,
  SetItems,
  SetTemplates,
  AddItem,
  UpdateItem,
  DeleteItem,
  AddAmbience,
  UpdateAmbience,
  DeleteAmbience,
  AddTemplate,
  UpdateTemplate,
  DeleteTemplate,
  SetTemplatesState
} from './my-templates.actions';
import { IMyTemplates } from './my-templates.interface';
import { Injectable } from '@angular/core';

/**
 * State of Item
 * Add new props in defaults object
 */
@Injectable()
@State<IMyTemplates>({
  name: 'myTemplates',
  defaults: {
    items: {
      page: 0,
      lastPage: 0,
      perPage: 0,
      total: 0,
      data: []
    },
    ambiences: {
      page: 0,
      lastPage: 0,
      perPage: 0,
      total: 0,
      data: []
    },
    templates: {
      page: 0,
      lastPage: 0,
      perPage: 0,
      total: 0,
      data: []
    },
    adminTemplates: {
      page: 0,
      lastPage: 0,
      perPage: 0,
      total: 0,
      data: []
    }
  }
})
export class MyTemplatesState {
  /****************************
   * Selectors
   ****************************/
  @Selector()
  static getItems({items}: IMyTemplates) {
    return Object.assign(items);
  }

  @Selector()
  static getAmbiences(mytemplates: IMyTemplates) {
    return mytemplates.ambiences;
  }

  @Selector()
  static getModules(mytemplates: IMyTemplates) {
    return mytemplates.templates;
  }

  @Selector()
  static getTemplates(mytemplates: IMyTemplates) {
    return mytemplates.templates;
  }
  @Selector()
  static getAdminTemplates(mytemplates: IMyTemplates) {
    return mytemplates.adminTemplates;
  }
  /****************************
   * Actions
   ****************************/

  @Action(SetItems)
  setItems({ patchState, getState }: StateContext<IMyTemplates>, { items }: SetItems) {
    if (items) {
      const currentState = getState();
      patchState({ ...currentState, items: JSON.parse(JSON.stringify(items)) });
    }
  }

  @Action(SetAmbiences)
  setAmbiences({ patchState, getState }: StateContext<IMyTemplates>, { ambiences }: SetAmbiences) {
    if (ambiences) {
      const currentState = getState();
      patchState({ ...currentState, ambiences: JSON.parse(JSON.stringify(ambiences)) });
    }
  }
  @Action(AddAmbienceName)
  addAmbienceName({ patchState, getState }: StateContext<IMyTemplates>, { elementName }: AddAmbienceName) {
    const currentState = getState();
    const ambience: Element = {
      elementName
    };
    currentState.ambiences.data.push(ambience);
    patchState({ ...currentState });
  }

  @Action(AddAmbience)
  addAmbience({ patchState, getState }: StateContext<IMyTemplates>, { element }: AddAmbience) {
    const currentState = getState();
    const index = currentState.ambiences.data.findIndex(
      e => e.elementName === element.elementName && !e.elementCreatedAt
    );
    currentState.ambiences.data[index] = element;
    patchState({ ...currentState });
  }

  @Action(UpdateAmbience)
  updateAmbience({ patchState, getState }: StateContext<IMyTemplates>, { element }: UpdateAmbience) {
    const currentState = getState();
    const index = currentState.ambiences.data.findIndex(e => e.elementId === element.elementId);
    currentState.ambiences.data[index].elementName = element.elementName;
    patchState({ ...currentState });
  }

  @Action(AddAmbienceItems)
  addAmbienceItems({ patchState, getState }: StateContext<IMyTemplates>, { items }: AddAmbienceItems) {
    const currentState = getState();

    const newAmbienceIndex = currentState.ambiences.data.findIndex(a => a.items && a.items.length === 0);
    if (newAmbienceIndex !== -1) {
      currentState.ambiences.data[newAmbienceIndex].items = items;
      patchState({ ...currentState });
    }
  }

  @Action(DeleteAmbience)
  deleteAmbience({ patchState, getState }: StateContext<IMyTemplates>, { element }: DeleteAmbience) {
    const currentState = getState();

    const index = currentState.ambiences.data.findIndex(c => c.elementId === element.elementId);
    currentState.ambiences.data.splice(index, 1);
    patchState({ ...currentState });
  }

  @Action(SetTemplates)
  setTemplates({ patchState, getState }: StateContext<IMyTemplates>, { templates }: SetTemplates) {
    if (templates) {
      const currentState = getState();
      patchState({ ...currentState, templates });
    }
  }

  @Action(SetAdminTemplates)
  setAdminTemplates(
    { patchState, getState }: StateContext<IMyTemplates>,
    { adminTemplates }: SetAdminTemplates
  ) {
    if (adminTemplates) {
      const currentState = getState();
      patchState({ ...currentState, adminTemplates });
    }
  }

  @Action(AddTemplates)
  addTemplates({ patchState, getState }: StateContext<IMyTemplates>, { templates }: AddTemplates) {
    const currentState = getState();
    templates.data.forEach(template => currentState.templates.data.push(template));
    patchState({ ...currentState, templates });
  }

  @Action(AddTemplateName)
  addTemplateName({ patchState, getState }: StateContext<IMyTemplates>, { templateName }: AddTemplateName) {
    const currentState = getState();
    const template: Template = {
      templateName
    };
    currentState.templates.data.push(template);
    patchState({ ...currentState });
  }

  @Action(AddTemplateElements)
  addTemplateElement(
    { patchState, getState }: StateContext<IMyTemplates>,
    { elements }: AddTemplateElements
  ) {
    const currentState = getState();

    const newTemplateIndex = currentState.templates.data.findIndex(
      a => a.elements && a.elements && a.elements.length === 0
    );
    if (newTemplateIndex !== -1) {
      currentState.templates.data[newTemplateIndex].elements = elements;
      patchState({ ...currentState });
    }
  }

  @Action(AddTemplate)
  addTemplate({ patchState, getState }: StateContext<IMyTemplates>, { template }: AddTemplate) {
    const currentState = getState();
    const index = currentState.templates.data.findIndex(
      e => e.templateName === template.templateName && !e.templateCreatedAt
    );
    currentState.templates.data[index] = template;
    patchState({ ...currentState });
  }

  @Action(UpdateTemplate)
  updateTemplate({ patchState, getState }: StateContext<IMyTemplates>, { template }: UpdateTemplate) {
    const currentState = getState();

    const index = currentState.templates.data.findIndex(c => c.templateId === template.templateId);
    currentState.templates.data[index].templateName = template.templateName;
    patchState({ ...currentState });
  }

  @Action(DeleteTemplate)
  deleteTemplate({ patchState, getState }: StateContext<IMyTemplates>, { template }: DeleteTemplate) {
    const currentState = getState();

    const index = currentState.templates.data.findIndex(c => c.templateId === template.templateId);
    currentState.templates.data.splice(index, 1);
    patchState({ ...currentState });
  }

  @Action(AddItem)
  addItem({ patchState, getState }: StateContext<IMyTemplates>, { item }: AddItem) {
    const currentState = getState();
    currentState.items.data.push(item);
    patchState({ ...currentState });
  }

  @Action(UpdateItem)
  updateItem({ patchState, getState }: StateContext<IMyTemplates>, { item }: UpdateItem) {
    const currentState = getState();

    const index = currentState.items.data.findIndex(c => c.itemId === item.itemId);
    currentState.items.data[index].itemName = item.itemName;
    patchState({ ...currentState });
  }

  @Action(DeleteItem)
  deleteItem({ patchState, getState }: StateContext<IMyTemplates>, { item }: UpdateItem) {
    const currentState = getState();

    const index = currentState.items.data.findIndex(c => c.itemId === item.itemId);
    currentState.items.data.splice(index, 1);
    patchState({ ...currentState });
  }

  @Action(SetTemplatesState)
  setTemplatesState({ patchState }: StateContext<IMyTemplates>, { templatesState }: SetTemplatesState) {
    if (templatesState) {
      patchState(templatesState);
    }
  }
}
