import { List } from '@shared/models/interfaces/List.interface';

import { Admin } from './admins.interface';

export class SetAdmins {
  static readonly type = '[ADMINS] Set Administrators';
  constructor(public admins: List<Admin>) {}
}

export class AddAdmins {
  static readonly type = '[ADMINS] Add Administrators to the current state';
  constructor(public admins: Admin[]) {}
}
