import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { AndroidPermissionResponse, AndroidPermissions } from '@ionic-native/android-permissions';
import { AppAvailability } from '@ionic-native/app-availability/ngx';
import { AlertController, LoadingController, Platform, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { getInterveningTypeIdForName } from '@shared/models/constants/interveningType';
import { LANGUAGESDATA } from '@shared/models/constants/languagesData';
import { getMaritalIdForName } from '@shared/models/constants/martitalStatus';
import { Element } from '@shared/models/interfaces/element.interface';
import { Item } from '@shared/models/interfaces/item.interface';
import { Intervener, IntervenerInformation } from '@shared/models/interfaces/survey.interface';
import { Template } from '@shared/models/interfaces/template.interface';
import { UserInformations } from '@shared/store/logged-user/user.interface';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { take } from 'rxjs/operators';
import { v1 as uuidv1 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(
    public toastController: ToastController,
    private router: Router,
    private alertController: AlertController,
    private platform: Platform,
    private loadingController: LoadingController,
    private httpClient: HttpClient,
    private appAvailability: AppAvailability,
    private translate: TranslateService
  ) {}

  public readonly CONTENT_TYPES_VIDEO = {
    mp4: 'video/mp4',
    mov: 'video/quicktime',
    hevc: 'video/hevc',
    heif: 'video/heif',
    '3gp': 'video/3gpp',
    avi: 'video/x-msvideo',
    mkv: 'video/x-matroska',
  } as const;

  public getVideoType(url: string): string | undefined {
    const extension = url.split('.').pop()?.toLowerCase();
    if (extension && this.CONTENT_TYPES_VIDEO.hasOwnProperty(extension)) {
      return this.CONTENT_TYPES_VIDEO[extension];
    }
  }

  public sortPhotoArray(itemsPhotos: any[]): string[] {
    const photoUrls = itemsPhotos.filter(photo => !this.isVideo(photo.photoUrl));
    const videoUrls = itemsPhotos.filter(photo => this.isVideo(photo.photoUrl));

    // Ordenar a array de fotos com base no atributo 'photoPosition'
    photoUrls.sort((a, b) => a.photoPosition - b.photoPosition);
    const sortedPhotos = photoUrls.map(photo => photo.photoUrl);
    const sortedVideos = videoUrls.map(photo => photo.photoUrl);

    //Retorna a array com o(s) video(s) sempre no final
    return [...sortedPhotos, ...sortedVideos];
  }

  /**
   * Validate each form field at some formGroup
   */
  public validateAllFields(formGroup: FormGroup | FormArray) {
    if (formGroup.controls.length === 0) {
      formGroup.markAsDirty();
      formGroup.markAsTouched();
    } else {
      Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        if (control instanceof FormControl) {
          control.markAsDirty();
          control.markAsTouched();
          control.updateValueAndValidity();
        } else if (control instanceof FormGroup) {
          this.validateAllFields(control);
        } else if (control instanceof FormArray) {
          control.controls.forEach(eachControl => {
            this.validateAllFields(eachControl as FormGroup);
          });
        }
      });
    }
  }

  arrayToStringSeparatedByComma(array: any[], atribute: string | number, parent = null) {
    let str = '';
    if (array) {
      array.forEach(element => {
        if (parent) {
          str = str === '' ? element[parent][atribute] : `${str}, ${element[parent][atribute]}`;
        } else {
          str = str === '' ? element[atribute] : `${str}, ${element[atribute]}`;
        }
      });
    }
    return str;
  }

  public async showToast(
    header: string,
    message: string,
    durationInMs = 2000,
    position: 'bottom' | 'top' | 'middle' = 'top'
  ) {
    const alreadyToast = await this.toastController.getTop();
    if (alreadyToast) {
      alreadyToast.dismiss();
    }

    const toast = await this.toastController.create({
      header,
      message,
      position,
      duration: durationInMs,
      mode: 'md',
      buttons: [
        {
          text: '×',
          role: 'cancel',
          cssClass: 'disableHover',
        },
      ],
    });

    toast.present();
    return toast.onWillDismiss();
  }
  async showAlert(header, message, confirmHandler = null, cancelHandler = null): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.alertController
        .create({
          header,
          message,
          backdropDismiss: false,
          buttons: [
            {
              text: `${this.translate.instant('PROVIDERS.NO')}`,
              role: 'cancel',
              cssClass: 'secondary',
              handler: cancelHandler ? cancelHandler : () => resolve(false),
            },
            {
              text: `${this.translate.instant('PROVIDERS.YES')}`,
              handler: confirmHandler ? confirmHandler : () => resolve(true),
            },
          ],
        })
        .then(a => a.present());
    });
  }

  async navigateRelativeTo(activatedRoute: ActivatedRoute, path: string) {
    const url = `${this.router.url.split('?')[0]}/${path}`;
    await this.presentLoading();
    await this.router.navigateByUrl(url).finally(() => this.dismissLoading());
  }

  async presentLoading(
    message = `${this.translate.instant('PROVIDERS.HOLD')}`,
    duration = 0,
    id?: string
  ): Promise<void> {
    const loading = await this.loadingController.create({
      message,
      duration,
      id,
    });
    await loading.present();
  }

  async dismissLoading(id?: string): Promise<boolean> {
    if (id) {
      return this.loadingController.dismiss(undefined, undefined, id);
    }
    const topLoading = await this.loadingController.getTop();
    if (topLoading) {
      return topLoading.dismiss();
    }
  }

  unmask(val: string): string {
    return val.replace(/\D+/g, '');
  }

  convertISOToDateString(isoDate: string): string {
    if (isoDate) {
      return moment(isoDate).format('DD/MM/YYYY');
    }
  }

  normalizeString = (stringToNormalize: string) =>
    stringToNormalize
      .replace(/\s+/g, '_')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      // tslint:disable-next-line:semicolon
      .toLowerCase();

  blobToBase64(blob): Promise<any> {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  }

  public mountElementWithUuid(data: any, allListedElements, allElements) {
    const array = [];
    const countListedElements = allListedElements.filter(e => e.elementId === data.elementId).length;
    const countQuantity = data.surveyElementQuantity - countListedElements;
    for (let i = 0; i < countQuantity; i++) {
      const items = [];
      let currentElement = allElements.data.find(a => a.elementId === data.elementId);
      if (!currentElement) {
        // new element in offline mode
        currentElement = data;
      }
      if (currentElement && currentElement.items && currentElement.items.length > 0) {
        currentElement.items.forEach(ambienceItem => {
          items.push({
            evaluationSurveyElementItemId: uuidv1(),
            ...ambienceItem,
          });
        });
        array.push({
          evaluationSurveyElementItemId: uuidv1(),
          ...currentElement,
          items,
        });
      } else {
        array.push({
          evaluationSurveyElementItemId: uuidv1(),
          ...currentElement,
        });
      }
    }
    return array;
  }

  public dateDiffInDays(a: Date, b: Date): number {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  downloadProgress(downloadProgress) {
    if (downloadProgress) {
      console.debug(`Downloading ${downloadProgress.receivedBytes} of ${downloadProgress.totalBytes}`);
    }
  }

  parseAddressToMasterAdminFormat(addressRawValue) {
    const address: {
      masterAdminAddressCity: any;
      masterAdminAddressState: any;
      masterAdminAddressZipCode: any;
      masterAdminAddressStreet: any;
      masterAdminAddressStreetNumber: any;
      masterAdminAddressComplement: any;
      masterAdminAddressDistrict: any;
      masterAdminAddressCountry: any;
    } = {
      masterAdminAddressCity: addressRawValue.city.nome,
      masterAdminAddressState: addressRawValue.state.sigla,
      masterAdminAddressZipCode: this.unmask(addressRawValue.zipCode),
      masterAdminAddressStreet: addressRawValue.street,
      masterAdminAddressStreetNumber: addressRawValue.streetNumber,
      masterAdminAddressComplement: addressRawValue.complement,
      masterAdminAddressDistrict: addressRawValue.neighborhood,
      masterAdminAddressCountry: 'BR',
    };

    if (address.masterAdminAddressComplement) {
      address.masterAdminAddressComplement = `${this.translate.instant('PROVIDERS.NONE')}`;
    }

    return address;
  }

  async downloadImagesByUrls(imagesUrls: string[]): Promise<void> {
    const downloads = [];

    imagesUrls.forEach(imageUrl => {
      const imagesUrlSplitBySlash = imageUrl.split('/');
      const filename = imagesUrlSplitBySlash[imagesUrlSplitBySlash.length - 1];
      const downloadImage = this.httpClient
        .get(imageUrl, { responseType: 'blob' })
        .pipe(take(1))
        .toPromise()
        .then(blob => {
          saveAs(blob, filename);
        });
      downloads.push(downloadImage);
    });

    await Promise.all(downloads);
  }

  public async IsWazeAvailable(): Promise<boolean> {
    let app;
    return new Promise(async (resolve, reject) => {
      if (this.platform.is('cordova')) {
        if (this.platform.is('ios')) {
          app = 'waze://';
        } else if (this.platform.is('android')) {
          app = 'com.waze';
        }
        await this.appAvailability.check(app).then(
          () => resolve(true),
          () => resolve(false)
        );
      }
      resolve(false);
    });
  }

  getCurrentLanguage() {
    let languageData = JSON.parse(localStorage.getItem('languageData'));
    if (languageData === null) {
      languageData = LANGUAGESDATA.filter(l => l.default)[0];
    }

    return languageData;
  }

  getDefaultLanguage() {
    return LANGUAGESDATA.filter(l => l.default)[0];
  }

  validateGUID(guid: string) {
    return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(guid);
  }

  extractKey(key: string) {
    const regex = /guarantor1|(\w+\D)/g;

    return regex.exec(key)[0];
  }

  public prepareInterveningData(interveningRawValue: IntervenerInformation): Intervener[] {
    const interveners: Intervener[] = [];
    const interveningKeys: string[] = Object.keys(interveningRawValue);
    interveningKeys.forEach(keyForm => {
      const intervener = interveningRawValue[keyForm];
      const key = this.extractKey(keyForm);

      if (intervener.email) {
        interveners.push({
          intervenerId: intervener.id,
          intervenerTypeId: getInterveningTypeIdForName(key),
          intervenerRg: intervener.rg,
          intervenerName: intervener.name,
          intervenerEmail: intervener.email,
          intervenerDocument: this.unmask(intervener.document),
          intervenerProfession: intervener.profession,
          intervenerNationality: intervener.nationality,
          intervenerNaturalness: intervener.naturalness,
          maritalStatusId: getMaritalIdForName(intervener.marital),
          intervenerPosition: intervener.position,
        });
      }
      if (intervener.emailPartner) {
        interveners.push({
          intervenerId: intervener.idPartner,
          intervenerTypeId: getInterveningTypeIdForName(key + 'Partner'),
          intervenerRg: intervener.rgPartner,
          intervenerName: intervener.namePartner,
          intervenerEmail: intervener.emailPartner,
          intervenerDocument: this.unmask(intervener.documentPartner),
          intervenerProfession: intervener.professionPartner,
          intervenerNationality: intervener.nationalityPartner,
          intervenerNaturalness: intervener.naturalnessPartner,
          maritalStatusId: getMaritalIdForName(intervener.maritalPartner),
          intervenerPosition: intervener.position,
        });
      }
    });
    return interveners;
  }

  requestPhotosZip(surveyId: string, rawKeys: string[], email: string, survey: any) {
    const keys = this.extractObjectKey(rawKeys);
    const url = `${environment.apiUrlAWS}/${environment.endpointsAWS.zipImages}`;
    const body = {
      survey_id: surveyId,
      keys,
      email,
      surveyAddressStreet: survey.surveyAddressStreet,
      surveyAddressStreetNumber: survey.surveyAddressStreetNumber,
      surveyAddressDistrict: survey.surveyAddressDistrict,
      surveyAddressCity: survey.surveyAddressCity,
      surveyAddressState: survey.surveyAddressState,
    };

    return this.httpClient.post(url, body);
  }

  extractObjectKey(rawKeys: string[]): string[] {
    const paths: string[] = [];
    rawKeys.forEach(rawKey => {
      const split = rawKey.split('.amazonaws.com/');
      if (split.length > 1) {
        paths.push(split[1]);
      }
    });
    return paths;
  }

  sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  timeoutToReject<T>(message: string, timeoutInMs: number = 12000) {
    return new Promise<T>((_resolve, reject) => setTimeout(() => reject(message), timeoutInMs));
  }

  headersNoCache(): HttpHeaders {
    return new HttpHeaders({
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
    });
  }

  isVideo(urlFile: string) {
    if (Object.keys(this.CONTENT_TYPES_VIDEO).find(extension => urlFile.toLowerCase().endsWith(extension))) {
      return true;
    }
    return false;
  }

  async verifyPermissions(permission: string, aliasMessageToTranslate: string) {
    const androidPermission: AndroidPermissionResponse = await AndroidPermissions.checkPermission(permission);
    if (!androidPermission.hasPermission) {
      const allowPermission = await AndroidPermissions.requestPermission(permission);
      if (!allowPermission.hasPermission) {
        await this.showAlert(
          this.translate.instant('COMPONENTS.MODALS.DIDNT_GET_PERMISSION'),
          this.translate.instant(aliasMessageToTranslate)
        );
      }
    }
  }

  getFileExtension(fileName: string): string | undefined {
    return fileName.toLowerCase().split('.').pop();
  }

  public updateItemState(event, state: AbstractControl, isAutomaticDescription: Boolean) {
    const goodStateItemMsg = this.translate.instant('COMPONENTS.FORMS.GOOD_STATE_ITEM_MSG');
    const regularStateItemMsg = this.translate.instant('COMPONENTS.FORMS.REGULAR_STATE_ITEM_MSG');
    const badStateItemMsg = this.translate.instant('COMPONENTS.FORMS.BAD_STATE_ITEM_MSG');

    const isDefaultMessage =
      state.value.localeCompare(goodStateItemMsg) === 0 ||
      state.value.localeCompare(regularStateItemMsg) === 0 ||
      state.value.localeCompare(badStateItemMsg) === 0;

    /* A mensagem padrão só é aplicada ao clicar no checkbox caso o usuário não
    tenha alterado a mensagem na mão e se a descrição automática estiver ativada*/

    if (isDefaultMessage && isAutomaticDescription) {
      if (event.target.value === 1) {
        state.setValue(goodStateItemMsg);
      } else if (event.target.value === 2) {
        state.setValue(regularStateItemMsg);
      } else {
        state.setValue(badStateItemMsg);
      }
    }
  }

  public isElementCreatedByUserLogged(
    userInformations: UserInformations,
    element: Item | Element | Template
  ): Boolean {
    return userInformations && element.userId === userInformations.userId ? true : false;
  }
}
