import { Item } from '@shared/models/interfaces/item.interface';
import { List } from '@shared/models/interfaces/List.interface';
import { SurveyInformations } from '../survey/survey.interface';

export class SetItems {
  static readonly type = '[MY-TEMPLATES] Set Items';
  constructor(public items: List<Item>) {}
}

export class SetPendingSurveys {
  static readonly type = '[SURVEYS-LIST] Set Pending Surveys';
  constructor(public pending: List<SurveyInformations>) {}
}
export class AddPendingSurveys {
  static readonly type = '[SURVEYS-LIST] Add survey to Pending Surveys';
  constructor(public survey: SurveyInformations) {}
}
export class SetInProgressSurveys {
  static readonly type = '[SURVEYS-LIST] Set In Progress Surveys';
  constructor(public inProgress: List<SurveyInformations>) {}
}
export class SetDonePendingSurveys {
  static readonly type = '[SURVEYS-LIST] Set Done Pending Surveys';
  constructor(public donePending: List<SurveyInformations>) {}
}
export class SetDoneSurveys {
  static readonly type = '[SURVEYS-LIST] Set Done Surveys';
  constructor(public done: List<SurveyInformations>) {}
}
export class SetArchivedSurveys {
  static readonly type = '[SURVEYS-LIST] Set Archived Surveys';
  constructor(public archived: List<SurveyInformations>) {}
}
export class MoveSurveyToInProgressList {
  static readonly type = '[SURVEYS-LIST] Move Survey To in progress List';
  constructor(public survey: SurveyInformations) {}
}
export class MoveSurveyToDoneList {
  static readonly type = '[SURVEYS-LIST] Move Survey To Done List';
  constructor(public survey: SurveyInformations) {}
}
export class MoveSurveyToDonePendingList {
  static readonly type = '[SURVEYS-LIST] Move Survey To Done Pending List';
  constructor(public survey: SurveyInformations) {}
}
export class SetSurveysListState {
  static readonly type = '[SURVEYS-LIST] Set Surveys List State';
  constructor(public surveysListState: any) {}
}

export class SetSelectedSurveysList {
  static readonly type = '[SURVEYS-LIST] Set Selected Surveys List';
  constructor(public selected: string) {}
}
