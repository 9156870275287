import { IRequest, ISurveyRequest } from './request.interface';

export class AddRequestToQueue {
  static readonly type = '[REQUEST] Add request to queue';
  constructor(public request: IRequest) {}
}
export class AddSurveyOnFailQueue {
  static readonly type = '[REQUEST] Add request to fail queue';
  constructor(public surveyRequest: ISurveyRequest) {}
}
export class AddSurveyOnFinalizedQueue {
  static readonly type = '[REQUEST] Add request to finalized queue';
  constructor(public surveyId: number) {}
}
export class SetCurrentGetRequest {
  static readonly type = '[REQUEST] Set current GET request';
  constructor(public request: IRequest) {}
}
export class SetRequestQueue {
  static readonly type = '[REQUEST] Set request queue';
  constructor(public requisitionQueue: IRequest[]) {}
}

export class RemoveRequestToQueue {
  static readonly type = '[REQUEST] Remove request from queue';
  constructor(public requestId: number = 0) {}
}

export class RemoveToOnFailQueue {
  static readonly type = '[REQUEST] Remove request from fail survey queue';
  constructor(public surveyId: number) {}
}
