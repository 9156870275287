import { Action, Selector, State, StateContext } from '@ngxs/store';

import {
  AddRequestToQueue,
  RemoveRequestToQueue,
  SetRequestQueue,
  AddSurveyOnFailQueue,
  AddSurveyOnFinalizedQueue,
  RemoveToOnFailQueue
} from './request.actions';
import { IRequestQueue, ISurveyRequest } from './request.interface';
import { Injectable } from '@angular/core';

@Injectable()
@State<IRequestQueue>({
  name: 'requestState',
  defaults: {
    currentGetRequest: null,
    requisitionQueue: [],
    surveyFailQueue: [],
    finalizedSurveys: []
  }
})
export class RequestState {
  /****************************
   * Selectors
   ****************************/

  @Selector()
  static getRequisitionQueue({ requisitionQueue }: IRequestQueue) {
    return requisitionQueue;
  }
  @Selector()
  static getSurveyFailQueue({ surveyFailQueue }: IRequestQueue) {
    return surveyFailQueue;
  }
  @Selector()
  static getFinalizedSurveys({ finalizedSurveys }: IRequestQueue) {
    return finalizedSurveys;
  }

  /****************************
   * Actions
   ****************************/

  @Action(AddSurveyOnFailQueue)
  addSurveyOnFailQueue(
    { patchState, getState }: StateContext<IRequestQueue>,
    { surveyRequest }: AddSurveyOnFailQueue
  ) {
    const surveyFailQueue = getState().surveyFailQueue;
    surveyFailQueue.push(surveyRequest);
    patchState({ surveyFailQueue });
  }

  @Action(AddSurveyOnFinalizedQueue)
  addSurveyOnFinalizedQueue(
    { patchState, getState }: StateContext<IRequestQueue>,
    { surveyId }: AddSurveyOnFinalizedQueue
  ) {
    const finalizedSurveys = getState().finalizedSurveys;
    finalizedSurveys.push(surveyId);
    patchState({ finalizedSurveys });
  }

  @Action(SetRequestQueue)
  setRequestQueue({ patchState }: StateContext<IRequestQueue>, { requisitionQueue }: SetRequestQueue) {
    patchState({ requisitionQueue });
  }

  @Action(AddRequestToQueue)
  addRequestToQueue({ patchState, getState }: StateContext<IRequestQueue>, { request }: AddRequestToQueue) {
    const requisitionQueue = getState().requisitionQueue;
    requisitionQueue.push(request);
    patchState({ requisitionQueue });
  }

  @Action(RemoveRequestToQueue)
  removeRequestToQueue(
    { patchState, getState }: StateContext<IRequestQueue>,
    { requestId }: RemoveRequestToQueue
  ) {
    const requisitionQueue = getState().requisitionQueue;
    requisitionQueue.shift();
    patchState({ requisitionQueue });
  }

  @Action(RemoveToOnFailQueue)
  removeToOnFailQueue(
    { patchState, getState }: StateContext<IRequestQueue>,
    { surveyId }: RemoveToOnFailQueue
  ) {
    const surveyFailQueue = getState().surveyFailQueue;
    const index = surveyFailQueue.findIndex(
      (surveyRequest: ISurveyRequest) => surveyRequest.surveyId !== surveyId
    );
    surveyFailQueue.splice(index, 1);
    patchState({ surveyFailQueue });
  }
}
