import { INotificationState } from './notification.interface';
import { Selector, State, Action, StateContext } from '@ngxs/store';
import {
  SetNotificationsOpened,
  SetNotificationsReceived,
  SetNotificationsPayload
} from './notification.actions';
import { Injectable } from '@angular/core';

/**
 * State of Item
 * Add new props in defaults object
 */
@Injectable()
@State<INotificationState>({
  name: 'notification',
  defaults: {
    notificationReceived: false,
    notificationOpened: false,
    payload: null
  }
})
export class NotificationState {
  /****************************
   * Selectors
   ****************************/
  @Selector()
  static getNotificationOpened({ notificationOpened }: INotificationState) {
    return notificationOpened;
  }

  @Selector()
  static getNotificationReceived({ notificationReceived }: INotificationState) {
    return notificationReceived;
  }

  @Selector()
  static getNotificationPayoad({ payload }: INotificationState) {
    return payload;
  }

  /****************************
   * Actions
   ****************************/

  @Action(SetNotificationsOpened)
  setNotificationsOpened(
    { patchState }: StateContext<INotificationState>,
    { notificationOpened }: INotificationState
  ) {
    patchState({ notificationOpened });
  }

  @Action(SetNotificationsReceived)
  setNotificationsReceived(
    { patchState }: StateContext<INotificationState>,
    { notificationOpened }: INotificationState
  ) {
    patchState({ notificationOpened });
  }

  @Action(SetNotificationsPayload)
  setNotificationsPayload({ patchState }: StateContext<INotificationState>, { payload }: INotificationState) {
    patchState({ payload });
  }
}
