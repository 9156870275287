import {
  WitnessSignatureRequest,
  WitnessSignatureResponse
} from '@shared/models/interfaces/witnessSignature.interface';

export class SetWitnessSignatureRequestData {
  static readonly type = '[Witness] Set witness signature data';
  constructor(public witnessSignatureRequest: WitnessSignatureRequest) {}
}

export class SetWitnessSignatureResponseData {
  static readonly type = '[Witness] Set witness signature data';
  constructor(public witnessSignatureResponse: WitnessSignatureResponse) {}
}
