import { LanguageData } from '../interfaces/language.interface';

export const LANGUAGESDATA: LanguageData[] = [
  {
    languageLabel: 'PT/BR',
    languageFlagUrl: 'assets/svg/BR.svg',
    languageValue: 'pt-BR',
    default: true,
  },
  {
    languageLabel: 'ENG',
    languageFlagUrl: 'assets/svg/US.svg',
    languageValue: 'en',
    default: false,
  },
];
