import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';
import { UserState } from '@shared/store/logged-user/user.state';
import PouchDB from 'pouchdb';
import * as idbPlugin from 'pouchdb-adapter-idb';
import { Observable } from 'rxjs';

import { HttpService } from './http.service';
@Injectable({
  providedIn: 'root'
})
export class PouchIdbService extends HttpService {
  constructor(http: HttpClient, private store: Store) {
    super('', http, store);
    PouchIdbService.instannce = this;
  }
  static instannce: PouchIdbService;
  private isInstantiated: boolean;
  private reqDb;
  private stateDb;

  private methods = {
    GET: { function: path => this.get(path).toPromise(), hasBody: false },
    POST: { function: (body, path) => this.post(body, path).toPromise(), hasBody: true },
    PUT: { function: (body, path) => this.put(body, path).toPromise(), hasBody: true },
    DELETE: { function: path => this.delete(path).toPromise(), hasBody: false }
  };
  public async fetch(getFromState = false) {
    const db = getFromState ? this.stateDb : this.reqDb;
    return db.allDocs({
      // eslint-disable-next-line @typescript-eslint/camelcase
      include_docs: true,
      attachments: true
    });
  }

  initLocalDatabaseByUserOnState() {
    PouchDB.plugin(idbPlugin);
    const user = this.store.selectSnapshot(UserState.getUserInformations);
    this.reqDb = new PouchDB(`${user.userId}-requests`);
    this.stateDb = new PouchDB(`${user.userId}-state`);
    this.isInstantiated = true;
  }
  
  public getPouchData(id: string, getFromState = false) {
    const db = getFromState ? this.stateDb : this.reqDb;
    return db.get(id);
  }
  public deletePouchData(document: any, getFromState = false) {
    const db = getFromState ? this.stateDb : this.reqDb;
    db.get(document._id)
      .then(doc => {
        return this.reqDb.remove(doc);
      })
      .catch(err => {
        console.error(err);
      });
  }
  public putPouchData(document: any, id = new Date().toISOString(), getFromState = false) {
    const db = getFromState ? this.stateDb : this.reqDb;
    document._id = id;
    return this.getPouchData(id, getFromState).then(
      result => {
        document._rev = result._rev;
        return db.put(document);
      },
      error => {
        if (error.status === 404) {
          return db.put(document);
        } else {
          return new Promise((resolve, reject) => {
            reject(error);
          });
        }
      }
    );
  }

  public async httpRequest(method: string, path: string, body, httpOptions?: any): Promise<Observable<any>> {
    const requestMethod = this.methods[method];
    let response;
    if (requestMethod.hasBody) {
      response = await requestMethod.function(body, path);
    } else {
      response = await requestMethod.function(path);
    }
    return response;
  }
}
