import {
  InterveningSignatureRequest,
  InterveningSignatureResponse
} from '@shared/models/interfaces/interveningSIgnature.interface';

export class SetInterveningSignatureRequestData {
  static readonly type = '[Intervening] Set intervening signature data';
  constructor(public interveningSignatureRequest: InterveningSignatureRequest) {}
}

export class SetInterveningSignatureResponseData {
  static readonly type = '[Intervening] Set intervening signature data';
  constructor(public interveningSignatureResponse: InterveningSignatureResponse) {}
}
