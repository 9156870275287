import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { Store } from '@ngxs/store';
import { ConnectionStatus } from '@shared/models/constants/ConnectionStatus.enum';
import { ManageOfflineRequestsService } from '@shared/providers/manage-offline-requests.service';
import { ControllerState } from '@shared/store/controller/controller.state';
import { StateResetAll } from 'ngxs-reset-plugin';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from './authentication.service';
import { UtilsService } from './utils.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private utils: UtilsService,
    private router: Router,
    private offlineService: ManageOfflineRequestsService,
    private store: Store,
    private translate: TranslateService
  ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentLanguage = this.utils.getCurrentLanguage().languageValue;
    const nextReq = req.clone({
      headers: req.headers.set('Accept-Language', currentLanguage),
    });
    const loggedUser = this.authenticationService.getAuthData();
    if (
      loggedUser &&
      this.store.selectSnapshot(ControllerState.getConnectionStatus) === ConnectionStatus.Offline
    ) {
      return this.offlineService.manageOfflineRequest(req);
    }
    return next.handle(nextReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error && error.status === 401) {
          this.store.dispatch(new StateResetAll());
          this.authenticationService.setAuthentication(null);
          this.utils.dismissLoading();
          this.utils.showToast('', `${this.translate.instant('PROVIDERS.REAUTHENTICATION')}`);
          this.router.navigateByUrl('/');
        }
        return throwError(error);
      })
    );
  }
}
