export const MODULETYPESIDS = {
  Ambiences: 1,
  ConsumeMeasurers: 2,
  KeysAndControls: 3,
  Responsibles: 4,
  Signatures: 5,
  Immobile: 6,
  Intervening: 7,
  Witness: 8,
  Reports: 9,
  Contestation: 10,
};

export const MODULETYPES = [
  {
    moduleTypeId: 1,
    moduleTypeDecription: 'MODELS.ENVIRONMENTS'
  },
  {
    moduleTypeId: 2,
    moduleTypeDecription: 'MODELS.CONSUMPTION_METERS'
  },
  {
    moduleTypeId: 3,
    moduleTypeDecription: 'MODELS.SWITCHES_AND_CONTROLS'
  },
  {
    moduleTypeId: 4,
    moduleTypeDecription: 'MODELS.RESPONSIBLE'
  },
  {
    moduleTypeId: 5,
    moduleTypeDecription: 'MODELS.SUBSCRIPTIONS'
  },
  {
    moduleTypeId: 6,
    moduleTypeDecription: 'MODELS.IMMOBILE'
  },
  {
    moduleTypeId: 7,
    moduleTypeDecription: 'MODELS.INTERVENERS'
  },
  {
    moduleTypeId: 8,
    moduleTypeDecription: 'MODELS.WITNESSES'
  },
  {
    moduleTypeId: 9,
    moduleTypeDecription: 'MODELS.REPORTS'
  },
  {
    moduleTypeId: 10,
    moduleTypeDecription: 'MODELS.DISPUTES'
  }
];
