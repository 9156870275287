import { Action, Selector, State, StateContext } from '@ngxs/store';

import {
  SetConsumeMeasurers,
  SetKeysAndControls,
  SetElementsState,
  AddConsumeMeasurers,
  AddKeyAndControl
} from './elements.actions';
import { IElement } from './elements.interface';
import { Injectable } from '@angular/core';

/**
 * State of Item
 * Add new props in defaults object
 */
@Injectable()
@State<IElement>({
  name: 'elements',
  defaults: {
    consumeMeasurers: {
      page: 0,
      lastPage: 0,
      perPage: 0,
      total: 0,
      data: []
    },
    keysAndControls: {
      page: 0,
      lastPage: 0,
      perPage: 0,
      total: 0,
      data: []
    }
  }
})
export class ElementsState {
  /****************************
   * Selectors
   ****************************/
  @Selector()
  static getKeysAndControls(elements: IElement) {
    return elements.keysAndControls;
  }
  @Selector()
  static getConsumeMeasurers(elements: IElement) {
    return elements.consumeMeasurers;
  }

  /****************************
   * Actions
   ****************************/

  @Action(SetKeysAndControls)
  setKeysAndControls(
    { patchState, getState }: StateContext<IElement>,
    { keysAndControls }: SetKeysAndControls
  ) {
    if (keysAndControls) {
      const currentState = getState();
      patchState({ ...currentState, keysAndControls });
    }
  }

  @Action(SetConsumeMeasurers)
  setConsumeMeasurers(
    { patchState, getState }: StateContext<IElement>,
    { consumeMeasurers }: SetConsumeMeasurers
  ) {
    if (consumeMeasurers) {
      const currentState = getState();
      patchState({ ...currentState, consumeMeasurers });
    }
  }

  @Action(AddConsumeMeasurers)
  addConsumeMeasurers(
    { patchState, getState }: StateContext<IElement>,
    { consumeMeasurers }: AddConsumeMeasurers
  ) {
    if (consumeMeasurers) {
      const currentState = getState();
      currentState.consumeMeasurers.data.push(consumeMeasurers);
      patchState(currentState);
    }
  }

  @Action(AddKeyAndControl)
  addKeysControl({ patchState, getState }: StateContext<IElement>, { keyAndControl }: AddKeyAndControl) {
    if (keyAndControl) {
      const currentState = getState();
      currentState.keysAndControls.data.push(keyAndControl);
      patchState(currentState);
    }
  }

  @Action(SetElementsState)
  setElementsState({ patchState, getState }: StateContext<IElement>, { elements }: SetElementsState) {
    if (elements) {
      patchState(elements);
    }
  }
}
