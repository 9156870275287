import { Action, Selector, State, StateContext } from '@ngxs/store';

import {
  SetInterveningSignatureRequestData,
  SetInterveningSignatureResponseData
} from './intervening.actions';
import { IIntervening } from './intervening.interface';
import { Injectable } from '@angular/core';

/**
 * State of Item
 * Add new props in defaults object
 */
@Injectable()
@State<IIntervening>({
  name: 'intervening',
  defaults: {
    signature: {
      interveningSignatureRequest: {
        interveningSignatureIp: '',
        interveningSignatureToken: '',
        interveningSinatureCpf: ''
      },
      interveningSignatureResponse: {
        interveningEmail: '',
        surveyReportLink: '',
        tokenRequested: false,
        interveningHasSigned: false,
      }
    }
  }
})
export class InterveningState {
  /****************************
   * Selectors
   ****************************/
  @Selector()
  static getInterveningSignatureData(intervening: IIntervening) {
    return intervening.signature;
  }

  /****************************
   * Actions
   ****************************/

  @Action(SetInterveningSignatureRequestData)
  setInterveningSignatureRequestData(
    { patchState, getState }: StateContext<IIntervening>,
    { interveningSignatureRequest }: SetInterveningSignatureRequestData
  ) {
    if (interveningSignatureRequest) {
      const currentState = getState();
      patchState({ ...currentState, signature: { ...currentState.signature, interveningSignatureRequest } });
    }
  }

  @Action(SetInterveningSignatureResponseData)
  setInterveningSignatureResponseData(
    { patchState, getState }: StateContext<IIntervening>,
    { interveningSignatureResponse }: SetInterveningSignatureResponseData
  ) {
    if (interveningSignatureResponse) {
      const currentState = getState();
      patchState({ ...currentState, signature: { ...currentState.signature, interveningSignatureResponse } });
    }
  }
}
