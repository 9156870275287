import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IndexedDBService {
  private db: IDBDatabase;

  constructor() {
    this.initDB();
  }

  public initDB(): void {
    const request = indexedDB.open('myDB', 1);

    request.onerror = event => {
      console.error('Error opening IndexedDB', event.target);
    };

    request.onupgradeneeded = () => {
      this.db = request.result;
      if (!this.db.objectStoreNames.contains('photos')) {
        this.db.createObjectStore('photos');
      }
    };

    request.onsuccess = () => {
      this.db = request.result;
    };
  }

  public getPhotos(componentId: string): Observable<any[]> {
    return new Observable<any[]>(observer => {
      const transaction = this.db.transaction(['photos'], 'readonly');
      const objectStore = transaction.objectStore('photos');
      const request = objectStore.get(componentId);

      request.onsuccess = () => {
        observer.next(request.result || []);
        observer.complete();
      };

      request.onerror = event => {
        console.error('error on getPhotos', event);
        observer.error(request.error);
      };
    });
  }

  public savePhotos(componentId: string, photos: any[]): Observable<void> {
    return new Observable<void>(observer => {
      const transaction = this.db.transaction(['photos'], 'readwrite');
      const objectStore = transaction.objectStore('photos');
      const request = objectStore.put(photos, componentId);

      request.onsuccess = () => {
        observer.next();
        observer.complete();
      };

      request.onerror = event => {
        console.error('error on savePhotos', event);
        observer.error(event.target);
      };
    });
  }

  public clearIndexedDB(): void {

    if (this.db) {
      const transaction = this.db.transaction(['photos'], 'readwrite');
      const objectStore = transaction.objectStore('photos');
      objectStore.clear();
    }
  }
}
