import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { HandlePhotos, SetPhotoLimit } from './photos-limit.actions';
import { PhotoLimitStateModel } from './photos-limit.interface';

@Injectable()
@State<PhotoLimitStateModel>({
  name: 'photoLimit',
  defaults: {
    accountPhotoLimit: undefined,
    monthPhotoBalance: undefined,
  },
})
export class PhotoLimitState {
  @Selector()
  static getPhotoLimit(photoLimit: PhotoLimitStateModel) {
    return photoLimit;
  }

  @Action(SetPhotoLimit)
  setPhotoLimit({ setState }: StateContext<PhotoLimitStateModel>, { photoLimit }: SetPhotoLimit) {
    setState(photoLimit);
  }

  @Action(HandlePhotos)
  handlePhotos(
    { patchState, getState }: StateContext<PhotoLimitStateModel>,
    { qtdPhotos: { newPhotoArrayLength, originalPhotoArrayLength } }: HandlePhotos
  ) {
    const photos = getState();
    if (originalPhotoArrayLength === undefined) {
      originalPhotoArrayLength = 0;
    }

    const diff = newPhotoArrayLength - originalPhotoArrayLength;
    photos.monthPhotoBalance += diff;

    if (photos.monthPhotoBalance < 0) {
      photos.monthPhotoBalance = 0;
    }

    patchState(photos);
  }
}
